/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import WalkthroughForm from "./WalkthroughForm";
import PageHeader from "../../Common/PageHeader";
import { types } from "../../../Redux/Actions/types";
import { Constants } from "../../Common/Constants";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../../Redux/Actions/Configuration/Configuration";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";
import ToggleListField from "../../Common/Fields/ToggleListField";
import DeleteListButton from "../../Common/Fields/DeleteListButton";
import EditListButton from "../../Common/Fields/EditListButton";
import { Dropdown } from "react-bootstrap";
import usePermissions from "../../Common/Functions/usePermissions";

const WalkthroughScreenList = () => {
  const dispatch = useDispatch();
  const { popupmodel } = useSelector((state) => state.Walkthrough);
  let section = { type: Constants.configKeyWKTScreen };
  const [settings, setSettings] = useState([]);
  const [data, setData] = useState([]);
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);
  const deletePermission = usePermissions(Constants.permission.CONFG_DELETE);

  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response?.data?.data);
    });
    dispatch({
      type: types.WKT_SCREEN_SINGLE,
      payload: "",
    });
  }, []);

  useEffect(() => {
    if (settings?.length > 0) {
      setData(JSON.parse(settings?.[0]?.value));
    }
  }, [settings]);

  const triggerToggle = (index) => {
    let copiedData = JSON.parse(JSON.stringify(data));
    copiedData[index].status =
      copiedData?.[index].status === Constants.status.ACTIVE
        ? Constants.status.INACTIVE
        : Constants.status.ACTIVE;
    let dataForm = { ...section };
    let dataObj = {};
    dataObj[section["type"]] = JSON.stringify(copiedData);
    dataForm.key_value = dataObj;
    dispatch(addAdminConfigurations(dataForm)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        newScreen();
      }
    });
  };

  const onDelete = (item) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        let copiedData = JSON.parse(JSON.stringify(data));
        let idx = copiedData.findIndex((elt) => elt.id === item.id);
        copiedData.splice(idx, 1);
        let dataForm = { ...section };
        let dataObj = {};
        dataObj[section["type"]] = JSON.stringify(copiedData);
        dataForm.key_value = dataObj;
        dispatch(addAdminConfigurations(dataForm)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            newScreen();
          }
        });
      }
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let dataObj = items.map((elt, index) => {
      elt.sort_order = index + 1;
      return elt;
    });
    setData(dataObj);
    let dataForm = { ...section };
    let dataObjs = {};
    dataObjs[section["type"]] = JSON.stringify(dataObj);
    dataForm.key_value = dataObjs;
    dispatch(addAdminConfigurations(dataForm)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        newScreen();
      }
    });
  };

  const newScreen = () => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response.data.data);
    });
  };

  return (
    <div className="container-xxl pt-m-0 pt-16-web">
      <PageHeader
        pagetitle={Constants.walkthroughScreenList}
        buttonLink={addPermission}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: types.WKT_SCREEN_FORM_MODEL,
            payload: { msg: Constants.addWalkthroughScreen, show: true },
          });
        }}
      />
      <div className="card list-view">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="sequence">
            {(provided) => (
              <ul
                className="sequence"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {data?.length > 0 &&
                  data.map((option, index) => {
                    return (
                      <Draggable
                        key={option?.id}
                        draggableId={option?.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            className="space-btwn"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div>
                              <p>{option?.title1}</p>
                            </div>
                            <div className="switch-tn2 position-relative btn-h">
                              <div className="remove-drop-ic">
                                <Dropdown className="dropdown user-profilem">
                                  <Dropdown.Toggle
                                    as="a"
                                    className="nav-link dropdown-toggle pulse p-0 margin-b"
                                    href="#!"
                                    role="button"
                                  >
                                    <i
                                      className="fa fa-ellipsis-h ellips-t cursor-pointer"
                                      aria-hidden="true"
                                    ></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                                    <div className="outer-dot2 btn-list2">
                                      <div className="list-group">
                                        <EditListButton
                                          onClick={() => {
                                            dispatch({
                                              type: types.WKT_SCREEN_FORM_MODEL,
                                              payload: {
                                                msg: Constants.editGoals,
                                                show: true,
                                                add: 0,
                                              },
                                            });
                                            dispatch({
                                              type: types.WKT_SCREEN_SINGLE,
                                              payload: option,
                                            });
                                          }}
                                        />
                                      </div>
                                      {editPermission && (
                                        <div className="list-group">
                                          <ToggleListField
                                            label={Constants.statusKey}
                                            onChange={() => {
                                              triggerToggle(index);
                                            }}
                                            checked={
                                              option.status ===
                                              Constants.status.ACTIVE
                                            }
                                          />
                                        </div>
                                      )}
                                      {deletePermission && (
                                        <div className="list-group">
                                          <DeleteListButton
                                            onClick={() => {
                                              onDelete(option._id);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <ModalPopup
          show={popupmodel}
          onHide={() => {
            dispatch({ type: types.WKT_SCREEN_SINGLE, payload: "" });
            dispatch({ type: types.WKT_SCREEN_FORM_MODEL, payload: "" });
          }}
          dialogClassName={"agent-modal sm-modal"}
          title={popupmodel.msg}
          component={<WalkthroughForm newScreen={newScreen} />}
        />
      </div>
    </div>
  );
};

export default WalkthroughScreenList;
