import httpServiceInstance from "../helper/HttpService";
import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";

export const transactions = async (data) => {
  const url = `${ApiUrl.TRANSACTIONS}${
    data?.skipPagination
      ? `?${Constants.paginationFalse}`
      : `?skip=${data?.skip || 0}`
  }&limit=${data?.limit || Constants.limitCount}${
    data && data?.search ? `&search=${data?.search}` : ""
  }${data?.payment_status ? `&payment_status=${data?.payment_status}` : ""}${
    data?.payment_type?.length > 0
      ? `&payment_type=${JSON.stringify(data?.payment_type)}`
      : ""
  }${data?.start_date ? `&start_date=${data?.start_date}` : ""}${
    data?.end_date ? `&end_date=${data?.end_date}` : ""
  }`;

  const response = await httpServiceInstance.get(url);
  return response;
};

export const detailtransactions = async (id) => {
  const response = await httpServiceInstance.get(
    ApiUrl.TRANSACTIONS + `?id=${id}`
  );
  return response;
};

export const refund = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.REFUND, data);
  return response;
};

export const exportSaved = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.SAVE_EXPORT, data);
  return response;
};

export const calculateAmountRefund = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.CALCULATE_REFUND_AMOUNT,
    data
  );
  return response;
};
