/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enrolledUsersList } from "../../../../Redux/Actions";
import { Constants } from "../../../Common/Constants";
import PageHeader from "../../../Common/PageHeader";
import TableList from "../../../Common/UiModel/TableList";
import { Link, useNavigate, useParams } from "react-router-dom";
import secondsToHour from "../../../Common/Functions/secondsToHour";
import dayjs from "dayjs";
import { RoutesUrl } from "../../../Common/RoutesUrl";
import ReactPagination from "../../../Common/Fields/ReactPagination";
import ViewListButton from "../../../Common/Fields/ViewListButton";
import { Dropdown } from "react-bootstrap";
import ImportUsers from "./ImportUsers";

const EnrolledUsersList = ({ type, userViewPermission }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enrolledUserslist } = useSelector((state) => state.EnrolledUsers);
  const [enrolledUsersData, setEnrolledUsersData] = useState([]);
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(1);
  const [importExcel, setImportExcel] = useState(false);

  useEffect(() => {
    let data = { skip: 0, limit: Constants.limitCount, id, type };
    dispatch(enrolledUsersList(data));
  }, []);

  useEffect(() => {
    setEnrolledUsersData(enrolledUserslist.data);
  }, [enrolledUserslist]);

  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link
          to={
            type === Constants.course
              ? `${RoutesUrl.courses}/${id}/${Constants.enrolledUsersKey}/${row?._id}`
              : ""
          }
          onClick={() => {
            if (type === Constants.package) {
              if (userViewPermission)
                window.open(`${RoutesUrl.users}/${row?.user_id}`, "_blank");
            }
          }}
        >
          {row?.name}
        </Link>
      ),
    },
    {
      name: Constants.enrollmentDate,
      cell: (row) => dayjs(row?.enrollmentDate).format(Constants.dateFormat),
    },
    {
      name: Constants.currentRunningPercentage,
      cell: (row) => row?.currentRunningPercentage,
    },
    {
      name: Constants.timeSpent,
      selector: (row) =>
        row?.totalVideoTimeSpent ? secondsToHour(row?.totalVideoTimeSpent) : 0,
    },
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        navigate(
                          `${RoutesUrl.courses}/${id}/${Constants.enrolledUsersKey}/${row?._id}`
                        );
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  const handleOffset = (newOff) => {
    let data = {
      skip: newOff,
      limit: Constants.limitCount,
      id,
      type,
    };
    dispatch(enrolledUsersList(data));
  };

  return (
    <>
      <div className="mt-3 d-flex flex-bw">
        <PageHeader pagetitle={Constants.enrolledUsersList} />
        <div className="btn-flex btn-hover">
          <button
            type="button"
            className="common-btn btn btn-primary"
            onClick={() => setImportExcel(true)}
          >
            {Constants.importUsers}
          </button>
        </div>
      </div>
      <TableList
        columns={columns}
        data={enrolledUsersData}
        defaultSortFieldId
        highlightOnHover
      />
      {enrolledUserslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={enrolledUserslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ImportUsers
        show={importExcel}
        onHide={() => {
          setImportExcel(false);
        }}
        dialogClassName={"modal-md"}
      />
    </>
  );
};

export default EnrolledUsersList;
