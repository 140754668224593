import { ApiUrl } from "../components/Common/ApiUrl";
import httpServiceInstance from "../helper/HttpService";
import { Constants } from "../components/Common/Constants";

export const affiliateUsers = async (data) => {
  let url = `${ApiUrl.AFFILIATE}${
    data?.skipPagination
      ? `?${Constants.paginationFalse}`
      : `?skip=${data?.skip || 0}`
  }&limit=${data?.limit || Constants.limitCount}${
    data?.start_date ? `&start_date=${data?.start_date}` : ""
  }${data?.end_date ? `&end_date=${data?.end_date}` : ""}${
    data?.status ? `&status=${data?.status}` : ""
  }${data && data?.search ? `&search=${data?.search}` : ""}`;
  const response = await httpServiceInstance.get(url);
  return response;
};
