/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { supportList, supportStatusUpdate } from "../../Redux/Actions";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ModalPopup from "../Common/UiModel/ModalPopup";
import SupportForm from "./SupportForm";
import ReactPagination from "../Common/Fields/ReactPagination";
import { Link } from "react-router-dom";
import ReactSelect from "../Common/Fields/ReactSelect";
import { types } from "../../Redux/Actions/types";
import dayjs from "dayjs";
import DateRangePicker from "../Common/Fields/DateRangePicker";
import { Button } from "react-bootstrap";
import saveExportedFile from "../Common/Functions/saveExportedFile";
import usePermissions from "../Common/Functions/usePermissions";

const initDate = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

const SupportList = () => {
  const [showSupport, setShowSupport] = useState("");
  const dispatch = useDispatch();
  const { supportlist } = useSelector((state) => state.Support);
  const [pageIndex, setPageIndex] = useState(1);
  const [supportStatus, setSupportStatus] = useState("");
  const [showCalender, setShowCalender] = useState(false);
  const [initialDate, setInitialDate] = useState(initDate);
  const [range, setRange] = useState({});
  const [search, setSearch] = useState("");
  const userViewPermission = usePermissions(Constants.permission.USER_VIEW);
  const editPermission = usePermissions(Constants.permission.SUPPORT_EDIT);

  useEffect(() => {
    dispatch(supportList());
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const getData = (searchKey) => {
    const data = {
      search: searchKey || "",
      skip: 0,
      limit: Constants.limitCount,
      support_status: supportStatus.value,
      start_date: range?.start_date,
      end_date: range?.end_date,
    };
    dispatch(supportList(data));
  };

  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.statusKey,
      cell: (row) => (
        <div
          className={`payment-status-badges ${
            row?.request_status === "Resolved"
              ? "paid"
              : row?.request_status === "Pending"
              ? "failed"
              : "progress"
          }`}
        >
          {row?.request_status}
        </div>
      ),
    },
    {
      name: Constants.query,
      cell: (row) => (
        <Link to={""} onClick={() => setShowSupport(row)}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.query}
          >
            {row?.query?.slice(0, 15) + "..."}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link
          className="cursor-pointer"
          onClick={() => {
            if (userViewPermission) {
              window.open(
                process.env.REACT_APP_PUBLIC_URL +
                  `${Constants.usersKey}/${row?.user_id?._id}`,
                "_blank"
              );
            }
          }}
          to={""}
        >
          <div>{row?.user_id?.name}</div>
        </Link>
      ),
    },
    {
      name: Constants.email,
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.user_id?.email}
        >
          {row?.user_id?.email?.slice(0, 15) + "..."}
        </span>
      ),
    },
    {
      name: Constants.description,
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.description}
        >
          {row?.description?.slice(0, 15) + "..."}
        </span>
      ),
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    {
      name: Constants.changeSupportStatus,
      cell: (row) => (
        <div className="my-2">
          <ReactSelect
            required={false}
            options={Constants.supportOptions}
            isDisabled={!editPermission}
            placeholder={Constants.changeLeadStatus}
            onChange={(e) => handleSupportStatusChange(e, row)}
            value={{ value: row?.request_status, label: row?.request_status }}
          />
        </div>
      ),
    },
  ];

  const handleSupportStatusChange = (selected, data) => {
    dispatch(
      supportStatusUpdate(data?._id, { request_status: selected.value })
    ).then((response) => {
      if (response?.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response?.error,
        });
      } else {
        dispatch(supportList());
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response?.data?.message,
        });
      }
    });
  };

  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      support_status: supportStatus?.value || "",
      start_date: range?.start_date,
      end_date: range?.end_date,
      search,
    };
    dispatch(supportList(data));
  };

  const handleStatusChange = (e) => {
    setSupportStatus(e);
    const data = {
      support_status: e?.value || "",
      start_date: range?.start_date,
      end_date: range?.end_date,
      search,
    };
    dispatch(supportList(data));
  };

  const onRangeChange = (range) => {
    setInitialDate({
      startDate: range?.[0]?.startDate,
      endDate: range?.[0]?.endDate,
      key: "selection",
    });
    setRange({
      start_date: dayjs(range?.[0]?.startDate).format("YYYY-MM-DD"),
      end_date: dayjs(range?.[0]?.endDate).format("YYYY-MM-DD"),
    });
  };

  const onDateFilter = () => {
    setShowCalender(false);
    let data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      support_status: supportStatus.value,
    };
    dispatch(supportList(data));
  };

  const onResetFilter = () => {
    setShowCalender(false);
    setInitialDate(initDate);
    setRange({});
    setSearch("");
    setSupportStatus("");
    dispatch(
      supportList({
        skip: 0,
        limit: Constants.limitCount,
      })
    );
  };

  const exportSupportList = () => {
    let data = {
      skipPagination: true,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      support_status: supportStatus.value,
    };
    dispatch(supportList(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        handleDownloadClick(response?.data?.data);
      }
    });
  };

  // Function to handle the download click for exported CSV data
  const handleDownloadClick = (supportList) => {
    const data = supportList?.map((element) => {
      return {
        [Constants.name]: element?.user_id?.name || Constants.na,
        [Constants.email]: element?.user_id?.email || Constants.na,
        [Constants.status]: element?.request_status?.email || Constants.na,
        [Constants.query]: element?.query || Constants.na,
        [Constants.description]: element?.description || Constants.na,
        [Constants.createdDate]: dayjs(element?.createdAt).format(
          Constants.dateFormat
        ),
        [Constants.createdTime]: dayjs(element?.createdAt).format(
          Constants.timeFormat
        ),
      };
    });
    const csvData = saveExportedFile(Constants.support, dispatch, data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", Constants.csvSupport);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="container-xxl mt-3">
      <div className="mt-2">
        <PageHeader
          pagetitle={Constants.supportList}
          buttonLink={supportlist?.length > 0 ? true : false}
          buttonName={Constants.export}
          onClick={() => exportSupportList()}
        />
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        <div
          className={`calender-outer position-initial ${
            range?.start_date ? "calender-active" : ""
          }`}
        >
          <i
            style={{
              cursor: "pointer",
              fontSize: "25px",
            }}
            className="fa fa-calendar"
            aria-hidden="true"
            onClick={() => {
              setShowCalender((prevState) => !prevState);
            }}
          ></i>
          {showCalender && (
            <div className="date-pos">
              <div>
                <DateRangePicker
                  initialDate={initialDate}
                  rangeSelected={(range) => {
                    onRangeChange(range);
                  }}
                />
              </div>
              <div className="appy-btn">
                <Button
                  className="text-uppercase fw-bold text-center mx-auto d-block"
                  onClick={() => {
                    onDateFilter();
                  }}
                >
                  {Constants.apply}
                </Button>
              </div>
            </div>
          )}
        </div>
        {/* Payment Status filter */}
        <div className="col-md-3">
          <label className="form-label">{`${Constants.select} ${Constants.statusKey}`}</label>
          <ReactSelect
            required={false}
            options={Constants.supportOptions}
            placeholder={Constants.statusKey}
            onChange={(e) => handleStatusChange(e)}
            value={supportStatus || ""}
          />
        </div>
        {/* Search filter */}
        <div className="col-md-3">
          <label className="form-label">{`${Constants.search}`}</label>
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByNameEmailNumber}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
        {/* Reset */}
        <div className="col-md-1 reset-btn">
          <button
            className="btn btn-primary text-uppercase px-4  cross-reset p-right fw-bold"
            onClick={() => onResetFilter()}
          >
            {Constants.reset}
          </button>
        </div>
      </div>
      <TableList
        columns={columns}
        data={supportlist?.data}
        defaultSortFieldId
        highlightOnHover
      />
      <ModalPopup
        show={showSupport}
        onHide={() => {
          setShowSupport("");
        }}
        dialogClassName={"modal-lg"}
        title={Constants.detail}
        component={<SupportForm data={showSupport} />}
      />
      {supportlist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={supportlist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </div>
  );
};

export default SupportList;
