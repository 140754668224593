import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Graph = ({ data, title, format }) => {
  const [graphData, setGraphData] = useState({
    series: [
      {
        name: title,
        data: [],
      },
    ],
    options: {
      chart: {
        height: 390,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return format + val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: [],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return format + val;
          },
        },
      },
      title: {
        text: title,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  });

  useEffect(() => {
    if ((data?.data && data?.months) || data?.dates) {
      setGraphData((prevGraphData) => ({
        ...prevGraphData,
        series: [
          {
            ...prevGraphData.series[0],
            data: data?.data,
          },
        ],
        options: {
          ...prevGraphData.options,
          xaxis: {
            ...prevGraphData.options.xaxis,
            categories: data?.months || data?.dates,
          },
        },
      }));
    }
  }, [data]);

  return (
    <div id="chart" className="col-md-6">
      <ReactApexChart
        options={graphData.options}
        series={graphData.series}
        type="bar"
        height={410}
      />
    </div>
  );
};

export default Graph;
