/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import { Constants } from "../../Common/Constants";
import TextField from "../../Common/Fields/TextField";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import * as Yup from "yup";
import { types } from "../../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import TextEditor from "../../Common/Fields/TextEditor";
import {
  configFaqsInsert,
  configFaqsList,
} from "../../../Redux/Actions/Configuration/Faq";
import { useSelector } from "react-redux";
import { configFaqsUpdate } from "../../../Redux/Actions/Configuration/Faq";
import usePermissions from "../../Common/Functions/usePermissions";

const section = { type: Constants.configFaqKey };

const FaqForm = () => {
  const dispatch = useDispatch();
  const { configFaqsSingle } = useSelector((state) => state.ConfigFaqs);
  const [initialValues, setInitialValues] = useState({
    question: "",
    answer: "",
  });
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);

  const validationSchema = Yup.object().shape({
    question: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidWordLimitRegex, Constants.invalidWordLimit),
    answer: Yup.string().required(Constants.required),
  });

  const onEditorChange = function (editor, value, setFieldValue) {
    const data = editor.getData();
    setFieldValue(value, data);
  };

  useEffect(() => {
    if (!!configFaqsSingle) {
      let editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (configFaqsSingle[key]) {
          editValue[key] = configFaqsSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [configFaqsSingle]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        let submitFunc = configFaqsInsert(values);
        if (configFaqsSingle !== "") {
          let id = configFaqsSingle?._id;
          submitFunc = configFaqsUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.data.message,
            });
            dispatch(configFaqsList(section));
            dispatch({ type: types.CONFIG_FAQ_SINGLE, payload: "" });
            dispatch({ type: types.CONFIG_FAQ_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="col-md-12">
              <TextField
                type="text"
                name="question"
                className="form-control"
                errors={touched?.question ? errors?.question : undefined}
                touched={touched?.question}
                label={Constants.ques}
              />
            </div>
            <div className="col-md-12">
              <TextEditor
                label={Constants.ans}
                onChange={(_event, editor) =>
                  onEditorChange(editor, "answer", setFieldValue)
                }
                data={`${values?.answer || ""}`}
                errors={touched?.answer ? errors?.answer : undefined}
                touched={touched?.answer}
              />
            </div>
          </div>
          {((addPermission && !configFaqsSingle?._id) ||
            (editPermission && configFaqsSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default FaqForm;
