/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWithdrawRequests } from "../../Redux/Actions/Withdraw";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ReactPagination from "../Common/Fields/ReactPagination";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";

const WithdrawList = () => {
  const dispatch = useDispatch();
  const { withdrawRequestsList } = useSelector((state) => state.Withdraw);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [withdrawData, setWithdrawData] = useState([]);

  useEffect(() => {
    dispatch(getWithdrawRequests());
  }, []);

  useEffect(() => {
    // Set transaction list data
    setWithdrawData(withdrawRequestsList?.data);
  }, [withdrawRequestsList]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const getData = (searchTerm) => {
    let data = {
      limit: Constants.limitCount,
      skip: 0,
      search: searchTerm || "",
    };
    dispatch(getWithdrawRequests(data));
  };

  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.transactionId,
      cell: (row) => (
        <Link to={`${RoutesUrl.transactions}/${row?._id}`}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.transaction_id}
          >
            {row?.transaction_id?.slice(0, 10) + "..."}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.userName,
      cell: (row) => (
        <Link
          onClick={() =>
            window.open(`${RoutesUrl.users}/${row?.user_id?._id}`, "_blank")
          }
        >
          {row?.user_id?.name || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.date,
      cell: (row) => dayjs(row?.transaction_date).format(Constants.dateFormat),
    },
  ];

  const handleOffset = (newOff) => {
    let data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(getWithdrawRequests(data));
  };

  return (
    <>
      <div className="container-xxl">
        <div className="mt-2">
          <PageHeader pagetitle={Constants.withdrawList} />
        </div>
        <div className="row mb-4 align-items-end position-relative justify-content-end">
          {/* Search filter */}
          <div className="col-md-4">
            <input
              className="px-2 filter-sec-trans"
              type="search"
              value={search}
              placeholder={Constants.searchByName}
              onChange={(event) => {
                setSearch(event.target.value);
                if (event.target.value === "") getData();
              }}
            ></input>
          </div>
        </div>
        {/* Transaction Table Listing */}
        <TableList
          columns={columns}
          data={withdrawData}
          defaultSortFieldId
          highlightOnHover
        />
      </div>
      {withdrawRequestsList?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={withdrawRequestsList?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </>
  );
};

export default WithdrawList;
