import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Constants } from "../Common/Constants";
import TextField from "../Common/Fields/TextField";
import PhoneField from "../Common/Fields/PhoneField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { editUserProfile } from "../../Redux/Actions/Courses/EnrolledUsers";
import { types } from "../../Redux/Actions/types";
import { usersDetail } from "../../Redux/Actions/Users";

const EditProfile = ({ data, onHide }) => {
  const dispatch = useDispatch();

  // Initial values for refund form
  const [initialValues] = useState({
    name: data?.name,
    email: data?.email,
    dial_code: data?.dial_code,
    phone_number: data?.phone_number,
  });

  // Validations for profile form
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidEmailRegex, Constants.invalidEmail),
    phone_number: Yup.string()
      .required(Constants.required)
      .matches(/^(\+91[\-\s]?)?[789]\d{9}$/, Constants.invalid),
    name: Yup.string()
      .required(Constants.required)
      .matches(Constants.validNameRegex, Constants.invalid),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // EditProfile API
        dispatch(editUserProfile(values, data?._id)).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(usersDetail(data?._id));
            onHide();
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="email"
              className="form-control"
              errors={touched?.email ? errors?.email : undefined}
              touched={touched?.email}
              label={Constants.email}
            />
          </div>
          <div className="col-md-12">
            <PhoneField
              value={`${values?.dial_code}${values?.phone_number}`}
              name="phone"
              onChange={(e, country) => {
                setFieldValue("dial_code", country.dialCode);
                setFieldValue("phone_number", e.slice(country.dialCode.length));
              }}
              label={Constants.phoneNumber}
              errors={touched?.phone_number ? errors?.phone_number : undefined}
              touched={touched?.phone_number}
            />
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default EditProfile;
