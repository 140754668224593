/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageHeader from "../../Common/PageHeader";
import { Constants } from "../../Common/Constants";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "../../Common/Fields/TextField";
import TextEditor from "../../Common/Fields/TextEditor";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import { coursesList, sendEmail } from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../Redux/Actions/types";
import MultiSelect from "../../Common/Fields/MultiSelect";
import getOptionsFromData from "../../Common/Functions/getOptionsFromData";
import usePermissions from "../../Common/Functions/usePermissions";

const ReportEmail = () => {
  const dispatch = useDispatch();
  const { courseslist } = useSelector((state) => state.Courses);
  const [usersSelected, setUsersSelected] = useState([]);
  const [coursesSelected, setCoursesSelected] = useState([]);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const initialValues = {
    subject: "",
    content: "",
    user_type: [],
    courses: [],
  };
  const addPermission = usePermissions(Constants.permission.NOTIFI_ADD);

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required(Constants.required),
    content: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    dispatch(coursesList({ skipPagination: true }));
  }, []);

  useEffect(() => {
    setCoursesOptions(getOptionsFromData(courseslist?.data));
  }, [courseslist?.data]);

  const onEditorChange = function (editor, value, setFieldValue) {
    const data = editor.getData();
    setFieldValue(value, data);
  };

  const handleOptionsChange = (e, setFieldValue, field, setSelected) => {
    setSelected(e);
    let ids = e.map((elt) => elt.value);
    setFieldValue(field, ids);
  };

  return (
    <div className="container-xxl">
      <div className="mt-3 course-form card">
        <PageHeader pagetitle={Constants.emailFormat} />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            // Send email API
            let submitFunc = sendEmail(values);
            dispatch(submitFunc).then((response) => {
              if (response?.error) {
                dispatch({
                  type: types.ERROR_ALERT_VISIBLE,
                  payload: response?.error,
                });
              } else {
                resetForm(); // Reset the form values
                setUsersSelected([]);
                dispatch({
                  type: types.SUCCESS_ALERT_VISIBLE,
                  payload: response?.data?.message,
                });
              }
            });
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form className="row">
              <div className="col-md-12 mb-2">
                <TextField
                  type="text"
                  name="subject"
                  className="form-control"
                  errors={touched?.subject ? errors?.subject : undefined}
                  touched={touched?.subject}
                  label={Constants.subject}
                />
              </div>
              <div className="col-md-12 mb-4">
                <TextEditor
                  label={Constants.content}
                  onChange={(_event, editor) =>
                    onEditorChange(editor, "content", setFieldValue)
                  }
                  data={`${values?.content || ""}`}
                  errors={touched?.content ? errors?.content : undefined}
                  touched={touched?.content}
                />
              </div>
              <div className="col-md-4 mb-4">
                <label className="form-label">{Constants.usersType}</label>
                <MultiSelect
                  key="multi_select"
                  options={Constants.usersTypeOptions}
                  onChange={(selected) =>
                    handleOptionsChange(
                      selected,
                      setFieldValue,
                      "user_type",
                      setUsersSelected
                    )
                  }
                  value={usersSelected}
                  errors={touched?.user_type ? errors?.user_type : undefined}
                  touched={touched?.user_type}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
              {usersSelected
                ?.map((elt) => elt.value)
                .includes(Constants.usersTypeOptions?.[0]?.value) && (
                <div className="col-md-4 mb-4">
                  <label className="form-label">{`${Constants.select} ${Constants.course}`}</label>
                  <MultiSelect
                    key="multi_select"
                    options={coursesOptions}
                    onChange={(selected) =>
                      handleOptionsChange(
                        selected,
                        setFieldValue,
                        "courses",
                        setCoursesSelected
                      )
                    }
                    value={coursesSelected}
                    errors={touched?.courses ? errors?.courses : undefined}
                    touched={touched?.courses}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                  />
                </div>
              )}
              {addPermission && (
                <ButtonCustom label={Constants.submit} type="submit" />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ReportEmail;
