import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerReact = ({ onChange, date, editPermission }) => {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    setStartDate(date);
  }, [date]);

  const handleDateChange = (date) => {
    setStartDate(date);
    onChange(date);
  };

  return (
    <>
      {startDate && (
        <DatePicker
          selected={startDate}
          className="form-control"
          minDate={new Date()}
          onChange={(date) => {
            if (editPermission) handleDateChange(date);
          }}
        />
      )}
    </>
  );
};

export default DatePickerReact;
