import DataTable from "react-data-table-component";

function TableList(props) {
  const customStyles = {
    headRow: {
      style: {
        color: "#223336",
        backgroundColor: "#FFFFFF",
      },
    },
    rows: {
      style: {
        backgroundColor: "#FFFFFF",
        "&:nth-child(2n+1)": {
          backgroundColor: "#f3f7f9",
        },
      },
    },
  };

  return (
    <div className="container-xxl22">
      <div
        className={`mb-12 custom-table customStyles-view ${props?.className}`}
      >
        <DataTable customStyles={customStyles} {...props} />
      </div>
    </div>
  );
}

export default TableList;
