/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { types } from "../../../../Redux/Actions/types";
import {
  modulesDelete,
  modulesList,
  modulesUpdateStatus,
} from "../../../../Redux/Actions/Courses/Modules";
import { Constants } from "../../../Common/Constants";
import responseMethod from "../../../Common/Functions/responseMethod";
import { deleteConfirmBox } from "../../../Common/Functions/deleteConfirmBox";
import PageHeader from "../../../Common/PageHeader";
import TableList from "../../../Common/UiModel/TableList";
import ModalPopup from "../../../Common/UiModel/ModalPopup";
import ModuleForm from "./ModuleForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RoutesUrl } from "../../../Common/RoutesUrl";
import { Dropdown } from "react-bootstrap";
import ToggleListField from "../../../Common/Fields/ToggleListField";
import DeleteListButton from "../../../Common/Fields/DeleteListButton";
import EditListButton from "../../../Common/Fields/EditListButton";
import ViewListButton from "../../../Common/Fields/ViewListButton";
import dayjs from "dayjs";
import ReactPagination from "../../../Common/Fields/ReactPagination";
import usePermissions from "../../../Common/Functions/usePermissions";

const Modules = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { moduleslist, popupmodel } = useSelector((state) => state.Modules);
  const [modulesData, setModulesData] = useState([]);
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(1);
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);
  const deletePermission = usePermissions(Constants.permission.COURSES_DELETE);

  useEffect(() => {
    dispatch(modulesList({ id }));
    dispatch({
      type: types.MODULES_LIST,
      payload: [],
    });
  }, []);

  useEffect(() => {
    setModulesData(moduleslist.data);
  }, [moduleslist]);

  const triggerToggle = (rowData) => {
    let itemId = rowData._id;
    let data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(modulesUpdateStatus(itemId, data)).then((response) => {
      let tempList = modulesData;
      let index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].status = data.status;
      setModulesData(tempList);
      responseMethod(response, dispatch, modulesList, id);
    });
  };

  const onDelete = (delete_id) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(modulesDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(modulesList({ id }));
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (row) => row?.uniqueId,
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link
          to={`${RoutesUrl.courses}/${id}/${Constants.moduleKey}/${row?._id}`}
        >
          {row?.name}
        </Link>
      ),
    },
    {
      name: Constants.statusKey,
      cell: (row) => row?.status,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    {
      name: Constants.updatedBy,
      selector: (row) => row?.updated_by?.name || Constants.admin,
    },
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        navigate(
                          `${RoutesUrl.courses}/${id}/${Constants.moduleKey}/${row?._id}`
                        );
                      }}
                    />
                  </div>
                  {editPermission && (
                    <>
                      <div className="list-group">
                        <EditListButton
                          onClick={() => {
                            dispatch({
                              type: types.MODULES_FORM_MODEL,
                              payload: {
                                msg: Constants.editModule,
                                show: true,
                                add: 0,
                              },
                            });
                            dispatch({
                              type: types.MODULES_SINGLE,
                              payload: row,
                            });
                          }}
                        />
                      </div>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.statusKey}
                          onChange={() => {
                            triggerToggle(row);
                          }}
                          checked={row.status === Constants.status.ACTIVE}
                        />
                      </div>
                    </>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  const handleOffset = (newOff) => {
    let data = {
      id,
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(modulesList(data));
  };

  return (
    <>
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.moduleList}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.MODULES_FORM_MODEL,
              payload: { msg: Constants.addModule, show: true },
            });
          }}
        />
      </div>
      <TableList
        columns={columns}
        data={modulesData}
        defaultSortFieldId
        highlightOnHover
      />
      {moduleslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={moduleslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.MODULES_SINGLE, payload: "" });
          dispatch({ type: types.MODULES_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-md"}
        title={popupmodel.msg}
        component={<ModuleForm />}
      />
    </>
  );
};

export default Modules;
