import { types } from "../Actions/types";

const initialState = {
  supportlist: [],
};

export default function Support(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.SUPPORTS_LIST:
      return {
        ...state,
        supportlist: payload,
      };

    default:
      return state;
  }
}
