import { mdiPencil } from "@mdi/js";

const EditButton = ({ onClick }) => (
  <div
    className="action-icon"
    onClick={() => {
      onClick();
    }}
  >
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d={mdiPencil} />
    </svg>
    <span className="ps-2 name-f"></span>
  </div>
);

export default EditButton;
