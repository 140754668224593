import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const rolesAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.ROLES, data);
  return response;
};

export const rolesEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.ROLES + "?id=" + id,
    data
  );
  return response;
};

export const rolesRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.ROLES + "?id=" + id);
  return response;
};

export const roles = async () => {
  let url = `${ApiUrl.ROLES}?${Constants.paginationFalse}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const roleUsersAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.USERS, data);
  return response;
};

export const roleUsersEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.USERS + "?id=" + id,
    data
  );
  return response;
};

export const roleUsersRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.USERS + "?id=" + id);
  return response;
};

export const roleUsers = async () => {
  let url = `${ApiUrl.USERS}?type=${Constants.roleKey}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const roleUsersStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.USERS + "?id=" + id,
    data
  );
  return response;
};

export const rolePermissionsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.PERMISSION, data);
  return response;
};

export const rolePermissions = async (id) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.PERMISSION}?role=${id}`
  );
  return response;
};
