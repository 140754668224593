import { types } from "../Actions/types";

const initialState = {
  newslist: [],
  newsSingle: "",
  popupmodel: "",
};

export default function News(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.NEWS_LIST:
      return {
        ...state,
        newslist: payload,
      };
    case types.NEWS_SINGLE:
      return {
        ...state,
        newsSingle: payload,
      };
    case types.NEWS_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}
