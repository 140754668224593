import {
  addConfigurations,
  getConfigurations,
} from "../../../services/Configuration/configuration.service";
import { types } from "../types";

export const getAdminConfigurations = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await getConfigurations(data);
  if (response.status === true) {
    let res = response.data;
    dispatch({ type: types.ADMIN_CONFIGURATION_LIST, payload: res });
    dispatch({ type: types.LOADER_HIDE });
    return { data: res };
  } else {
    dispatch({
      type: types.ADMIN_CONFIGURATION_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const addAdminConfigurations = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await addConfigurations(data);
  if (response.status === true) {
    let res = response.data;
    dispatch({ type: types.ADMIN_CONFIGURATION_ADD, payload: res });
    dispatch({ type: types.LOADER_HIDE });
    return { data: res };
  } else {
    dispatch({
      type: types.ADMIN_CONFIGURATION_ADD,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
