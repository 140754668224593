import { Constants } from "../Constants";

const convertToAmPmFormat = (time) => {
  const [hour, minute] = time.split(":");
  let hour12 = parseInt(hour, 10);
  let period = Constants.am;
  if (hour12 > 12) {
    hour12 -= 12;
    period = Constants.pm;
  }
  return `${hour12}:${minute} ${period}`;
};

export default convertToAmPmFormat;
