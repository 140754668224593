/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import { Constants } from "../../Common/Constants";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "../../Common/Fields/TextField";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ReactSelect from "../../Common/Fields/ReactSelect";
import getOptionsFromData from "../../Common/Functions/getOptionsFromData";
import PasswordFieldWithToggle from "../../Common/Fields/PasswordFieldWithToggle";
import PhoneField from "../../Common/Fields/PhoneField";
import {
  roleUsersInsert,
  roleUsersList,
  roleUsersUpdate,
} from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import usePermissions from "../../Common/Functions/usePermissions";

const RoleUsersForm = () => {
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [rolesListing, setRolesListing] = useState([]);
  const { roleslist, roleUsersSingle } = useSelector((state) => state.Roles);
  const [contactNumber, setContactNumber] = useState("");
  const addPermission = usePermissions(Constants.permission.ROLE_MGT_ADD);
  const editPermission = usePermissions(Constants.permission.ROLE_MGT_EDIT);
  // Initial values for role users form
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    dial_code: Constants.dialCode,
    password: "",
    phone_number: "",
    role: "",
    type: Constants.roleKey,
  });

  // Validations for role users form
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidTextRegex, Constants.invalid)
      .matches(/^.{1,100}$/, Constants.invalid),
    email: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidTextRegex, Constants.invalid)
      .matches(Constants.invalidEmailRegex, Constants.invalidEmail),
    password: !!roleUsersSingle
      ? Yup.string()
      : Yup.string()
          .min(8, Constants.passwordRequired)
          .matches(Constants.invalidTextRegex, Constants.invalid)
          .matches(Constants.validPasswordRegex, Constants.passwordValidation)
          .required(Constants.required),
    phone_number: Yup.string()
      .matches(Constants.validPhoneNumber, Constants.invalid)
      .required(Constants.required),
    role: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill the role users form
    if (!!roleUsersSingle) {
      let editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (roleUsersSingle[key]) {
          editValue[key] = roleUsersSingle[key];
        }
        if (key === "role") {
          setRole({
            value: roleUsersSingle[key]?._id,
            label: roleUsersSingle[key]?.name,
          });
          editValue[key] = roleUsersSingle[key]?._id;
        }
        if (key === "phone_number") {
          const dialCode = roleUsersSingle["dial_code"];
          const number = roleUsersSingle["phone_number"];
          setContactNumber(dialCode + number);
        }
        setInitialValues(editValue);
      }
    }
  }, [roleUsersSingle]);

  useEffect(() => {
    setRolesListing(getOptionsFromData(roleslist?.data));
  }, [roleslist?.data]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update role
        let submitFunc = roleUsersInsert(values);
        if (roleUsersSingle?._id) {
          let id = roleUsersSingle?._id;
          submitFunc = roleUsersUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(roleUsersList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.ROLE_USERS_SINGLE, payload: "" });
            dispatch({ type: types.ROLE_USERS_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form className="row">
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-6 mb-2">
            <PhoneField
              value={contactNumber || false}
              name="phone"
              onChange={(e, country) => {
                setFieldValue("dial_code", "+" + country.dialCode);
                setFieldValue("phone_number", e.slice(country.dialCode.length));
              }}
              label={Constants.phoneNumber}
              errors={touched?.phone_number ? errors?.phone_number : undefined}
              touched={touched?.phone_number}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="email"
              className="form-control"
              errors={touched?.email ? errors?.email : undefined}
              touched={touched?.email}
              label={Constants.email}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="password"
              name="password"
              component={PasswordFieldWithToggle}
              placeholder="***************"
              className="form-control"
              errors={touched?.password ? errors?.password : undefined}
              touched={touched?.password}
              label={Constants.password}
            />
          </div>
          <div className="col-md-6 mb-2">
            <ReactSelect
              label={`${Constants.select} ${Constants.role}`}
              options={rolesListing}
              onChange={(e) => {
                setRole(e);
                setFieldValue("role", e.value);
              }}
              value={role}
            />
          </div>
          {((addPermission && !roleUsersSingle?._id) ||
            (editPermission && roleUsersSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default RoleUsersForm;
