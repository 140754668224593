export const Constants = {
  aadhaarBack: "Aadhaar Back",
  aadhaarFront: "Aadhaar Front",
  aadhaarNumber: "Aadhaar Number",
  abandonedCourses: "Abandoned Courses",
  abandonedCoursesKey: "abandoned-courses",
  abandonedTrack: "Abandoned Track",
  accepts: "Accepts",
  accessToken: "accessToken",
  actions: "Actions",
  activePromoCode: "Active Promo Code",
  activeTab: "activeTab",
  activeUsers: "ActiveUsers",
  add: "Add",
  addBanner: "Add Banner",
  addCategory: "Add Category",
  addComments: "Add Comment",
  addCourses: "Add Course",
  addEvent: "Add Event",
  addFaq: "Add FAQ",
  addField: "Add Input",
  addFreeVideo: "Add Free Video",
  addGoals: "Add Goal",
  addKey: "add",
  addLesson: "Add Lesson",
  admin: "Admin",
  addModule: "Add Module",
  addNews: "Add News",
  addOption: "Add Option",
  addPackage: "Add Package",
  addPromoCode: "Add Promo Code",
  addQues: "Add Question",
  addResource: "Add Resource",
  address: "Address",
  addReview: "Add Review",
  addSubCategory: "Add Sub Category",
  addUser: "Add User",
  addWalkthroughScreen: "Add Walkthrough Screen",
  affiliateUsers: "Affiliate Users",
  affiliateUsersList: "Affiliate User List",
  affiliateUsersKey: "affiliate",
  age: "Age",
  allModulesLessonsWillGetDeleted: "All the previous Lessons will get deleted.",
  am: "AM",
  amount: "Amount (INR)",
  amountKey: "Amount",
  ans: "Answer",
  ansChoiceOptions: [
    { key: 0, value: "Multiple choice" },
    { key: 1, value: "Single choice" },
  ],
  ansChoiceType: "Answer choice type",
  apply: "Apply",
  approve: "Approve",
  areYouSure: "Are you sure?",
  aspectRatio: "Aspect ratio",
  back: "Back",
  banner: "Banner",
  bannerKey: "banner",
  bannerDetails: "Banner Details",
  bannerImage: "Banner Image",
  bannerList: "Banner List",
  bannerVideo: "Banner Video",
  broadcastNotification: "Broadcast Notification",
  bytes: "bytes",
  catalog: "Catalog",
  catalogKey: "catalog",
  category: "Category",
  categorKey: "category",
  categoryList: "Category List",
  commentList: "Comments",
  changeCompletionPer: "Completion %",
  changeEnrollmentDate: "Change Enrollment Date",
  changeLeadStatus: "Change Lead Status",
  changeSupportStatus: "Change Support Status",
  characterLimitCrossed: "Character limit crossed",
  checkboxKey: "checkbox",
  city: "City",
  clickToUpload: "Click to Upload Image(s)",
  close: "Close",
  cms: "CMS",
  code: "Code",
  completedLessons: "Completed Lessons",
  completionPer: "Completion %",
  completionPerOptions: [
    { value: "0", label: "0 - Enable Sequential Learning" },
    { value: "100", label: "100 - Disable Sequential Learning" },
  ],
  completionPercentOptions: [
    { value: [0, 20], label: "0-20" },
    { value: [20, 40], label: "20-40" },
    { value: [40, 60], label: "40-60" },
    { value: [60, 80], label: "60-80" },
    { value: [80, 100], label: "80-100" },
  ],
  configBasic: "Basic",
  configBasicKey: "config-basic",
  configContactUs: "Contact Us",
  configContactUsKey: "config_contact_us",
  configFaq: "FAQs",
  configFaqKey: "config_faq",
  configuration: "Configuration",
  configurationKey: "configuration",
  configGoals: "Goals",
  configGoalsKey: "config-goals",
  configGoalsSelectionLimit: "Goals Selection Limit",
  configGoalsSelectionLimitKey: "config-goals-selection-limit",
  configKeyTotalGoals: "total_goals",
  configKeyGoalsSelectionLimit: "goals_selection_limit",
  configKeyWKTScreen: "walk_through_screen",
  configLoyalty: "Loyalty Points",
  configLoyaltyKey: "config_loyalty",
  configPricing: "Pricing",
  configPricingKey: "pricing",
  configPrivacyPolicy: "Privacy Policy",
  configPrivacyPolicyKey: "privacy_policy",
  configRefer: "Refer & Earn",
  configRefund: "Refund",
  configReferKey: "config_refer_earn",
  configRefundKey: "config_refund",
  configSales: "Sales Analytics",
  configSalesKey: "config_sales_key",
  configTermsConditions: "Terms & Conditions",
  configTermsConditionsKey: "terms_conditions_key",
  configWalkthrough: "Walkthrough Screen",
  configWalkthroughKey: "config-walkthrough",
  contactStatus: "Contact Status",
  contactStatusOptions: [
    { value: 1, label: "Not Contacted" },
    { value: 2, label: "Contacted" },
  ],
  content: "Content",
  copied: "Copied!",
  course: "Course",
  courseCompletionPer: "Course Completion %",
  courseDbKey: 0,
  courseDetails: "Course Details",
  courseLink: "Course Links",
  coursesKey: "courses",
  coursesList: "Course List",
  customer: "Customers",
  customerKey: "customers",
  create: "Create",
  createdAt: "Created At",
  createdBy: "Created By",
  createdDate: "Created Date",
  createdTime: "Created Time",
  createRole: "Create Role",
  csvAbandonedUsers: "AbandonedUsers.csv",
  csvAffiliateUsers: "AffiliateUsers.csv",
  csvDeletedUsers: "DeletedUsers.csv",
  csvKycRequests: "KycRequests.csv",
  csvPromoCodes: "PromoCodes.csv",
  csvSupport: "Support.csv",
  csvTransaction: "Transactions.csv",
  csvUser: "Users.csv",
  currency: "₹",
  currencyUsage: "Currency Usage",
  currentRunningPercentage: "Current Running %",
  dashboard: "Dashboard",
  dashboardKey: "dashboard",
  date: "Date",
  dateFormat: "D MMM YYYY",
  dateOfBirth: "Date of birth",
  day: "Day",
  defaultAdmin: "DEFAULT_ADMIN",
  delete: "Delete",
  deleted: "Deleted",
  deletedUsers: "Deleted Users",
  deletedUsersKey: "deletedreport",
  deleteKey: "delete",
  description: "Description",
  designation: "Designation",
  detail: "Detail",
  deviceType: "Device Type",
  deviceTypeOptions: [
    {
      value: "android",
      label: "Android",
    },
    {
      value: "ios",
      label: "iOS",
    },
  ],
  dialCode: "Dial Code",
  disabled: "Disabled",
  discount: "Discount (%)",
  discountInr: "Discount (INR)",
  discountKey: "Discount",
  document: "Document",
  downloadSample: "Download Sample file",
  dragAndDropOrClick: "Drag and drop .xlsx file here, or click to select file",
  duration: "Duration (hr)",
  edit: "Edit",
  editComments: "Edit Comment",
  editCategory: "Edit Category",
  editEvent: "Edit Event",
  editFaq: "Edit FAQ",
  editFreeVideo: "Edit Free Video",
  editGoals: "Edit Goals",
  editKey: "edit",
  editLesson: "Edit Lesson",
  editModule: "Edit Module",
  editNews: "Edit News",
  editPackage: "Edit Package",
  editProfile: "Edit Profile",
  editPromoCode: "Edit Promo Code",
  editQues: "Edit Question",
  editResource: "Edit Resource",
  editReview: "Edit Review",
  editSubCategory: "Edit Sub Category",
  editUser: "Edit User",
  editWalkthroughScreen: "Edit Walkthrough Screen",
  educationalQualification: "Educational Qualification",
  educationalQualificationOptions: [
    { label: "Primary education", value: "primary_education" },
    {
      label: "Secondary education or high school",
      value: "secondary_education_or_high_school",
    },
    { label: "Bachelor's degree", value: "bachelor's_degree" },
    { label: "Master's degree", value: "master's_degree" },
    { label: "Other", value: "other" },
  ],
  email: "Email",
  emailFormat: "Email Format",
  enabled: "Enabled",
  endDate: "End Date",
  endTimeGreaterThanStartTime: "End time should be greater than start time",
  endTime: "End Time",
  enrolledCourses: "Enrolled Courses",
  enrolledItems: "Enrolled Items",
  enrolledLearners: "Enrolled Learners",
  enrolledUsers: "Enrolled Users",
  enrolledUsersKey: "enrolledUsers",
  enrolledUsersList: "Enrolled User List",
  enteredAmtLessThanPaidAmt:
    "Entered amount should not be greater than paid amount",
  enrollmentDate: "Enrollment Date",
  enrollUser: "Enroll User",
  enterLabelText: "Enter label text",
  enterOptionText: "Enter option text",
  event: "Event",
  eventDbKey: 2,
  eventKey: "event",
  eventList: "Event List",
  expiryDuration: "Expiry Duration",
  expiryDurationMonths: "Expiry Duration (Months)",
  export: "EXPORT",
  faqs: "FAQs",
  faqList: "FAQ List",
  field: "Input Type:",
  fileNotUploadaed: "File not uploaded",
  form: "Form",
  formKey: "form",
  formBannerOptions: [
    {
      value: "text",
      label: "Text",
    },
    { value: "checkbox", label: "Checkbox" },
    { value: "radio", label: "Radio" },
    { value: "image", label: "Image Upload" },
  ],
  free: "Free",
  freeFirstLesson: "Free First Lesson",
  freeSize: "Free size",
  freeVideo: "Free Video",
  freeVideoKey: "freeVideo",
  freeVideoList: "Free Video List",
  gender: "Gender",
  genderOptions: [
    {
      value: "male",
      label: "Male",
    },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ],
  goals: "Goals",
  grantAccess: "Grant Access",
  gstStatus: "GST Status",
  height: "Height",
  history: "History",
  historyKey: "history",
  howYouGetToKnowUs: "How you get to know us?",
  id: "ID",
  inactivePromoCode: "Inactive Promo Code",
  invalid: "Invalid",
  invalidDays: "Enter value between 1 to 99",
  invalidDaysRegex: /^(?:[1-9]|[1-9][0-9])$/,
  invalidEmail: "Invalid Email",
  invalidEmailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  invalidFaqQuesLimitRegex: /^.{1,254}$/,
  invalidPercentRegex: /^(100(\.0{1,2})?|[1-9]\d?(\.\d{1,2})?)$/,
  invaidNumberOnly: /^\d+(\.\d{1,2})?$/,
  invalidPhoneNumber: "Invalid phone Number",
  invalidPrice: "Invalid Price",
  invalidPriceRegex: /^[1-9]\d*(\.\d{1,2})?$/,
  invalidReason: "Input should not exceed 50 characters",
  invalidTextRegex: /^[^<>]*$/,
  invalidWordLimitRegex: /^[a-zA-Z0-9._\s]{1,255}$/,
  invalidQuizSetRegex: /^[1-5]$/,
  invalidValue: "Invalid Value",
  invalidWordLimit: "Input should not exceed 255 characters",
  invoiceLink: "Invoice Link",
  itemDeleted: "Item has been deleted",
  image: "Image",
  imageGif: "Image/GIF",
  imageKye: "image",
  imageSvg: "SVG",
  imageKey: "image",
  importUsers: "Import Users",
  invoiceDescription: "Invoice Description",
  kyc: "Kyc",
  kycApproveKey: 2,
  kycDetail: "Kyc Detail",
  kycKey: "kyc",
  kycPendingKey: 1,
  kycRejectKey: 3,
  kycRequest: "KYC Requests",
  kycRequestList: "KYC Request List",
  kycRequests: [
    {
      value: null,
      label: "All",
    },
    {
      value: 1,
      label: "Pending",
    },
    {
      value: 2,
      label: "Approved",
    },
    {
      value: 3,
      label: "Rejected",
    },
  ],
  kycStatus: "KYC Status",
  leadStatus: "Lead Status",
  leadStatusOptions: [
    {
      value: 1,
      label: "Open",
    },
    {
      value: 2,
      label: "Follow Up",
    },
    {
      value: 3,
      label: "Converted",
    },
    {
      value: 4,
      label: "Not Interested",
    },
  ],
  lessonName: "Lesson Name",
  lessons: "Lessons",
  lessonList: "Lesson List",
  lessonSetRule: [
    {
      value: 0,
      label: "Release once a week",
    },
    {
      value: 1,
      label: "Instant release",
    },
    {
      value: 2,
      label: "Select date for each lesson",
    },
  ],
  lifetime: "Lifetime",
  limitCount: 10,
  link: "Link",
  linkCourse: "learning/course/",
  login: "Login",
  logs: "Logs",
  logsKey: "logs",
  logsList: "Logs List",
  loyaltyPercentage: "Loyalty (%)",
  loyaltyPoints: "Loyalty Points",
  manageRoles: "Manage Roles",
  manageRolesKey: "manage-roles",
  manageUsers: "Manage Users",
  manageUsersKey: "manage-cust",
  max: "Maximum",
  maxCurrencyUsage: "Maximum Currency Usage (%)",
  maxDiscount: "Maximum Discount (INR)",
  maxFormFieldsLimit: "Maximum 20 fields are allowed",
  maxGoalsError: "Maximum number can not be less than the minimum",
  maxTotalGoalsError: "Maximum number can not be greater than total goals",
  maxUseCount: "Maximum Use Count",
  message: "Message",
  min: "Minimum",
  minGoalsError: "Minimum number can not be greater than total goals",
  minWithDrawAmount: "Minimum Withdraw Amount",
  moduleKey: "module",
  moduleLessons: "Module Lessons",
  moduleList: "Module List",
  moduleName: "Module Name",
  modules: "Modules",
  month: "Month",
  na: "N/A",
  name: "Name",
  newPaidUsers: "New Paid Users",
  news: "News",
  newSignups: "New Signups",
  newsKey: "news",
  newsList: "News List",
  ninetyDays: "90 days",
  no: "No",
  noRecord: "There are no records to display",
  noRevert: "You won't be able to revert this!",
  notApplied: "Not applied",
  notification: "Notification",
  notificationEmail: "notification-mail",
  notificationKey: "notification",
  notificationNotification: "notification-notifi",
  notifyLearners: "Notify Learners",
  number: "Number",
  numberOfAttempts: "Number of Attempts",
  onFirstPurchase: "On First Purchase",
  oneTimePlayable: "One time playable",
  oneYear: "1 year",
  oops: "Oops!",
  option1: "Option 1",
  option2: "Option 2",
  option3: "Option 3",
  option4: "Option 4",
  options: "Options",
  originalPrice: "Original Price",
  originalPriceLessThanStrikeOutPrice:
    "Original price should be less than Strike Out price",
  other: "Other",
  overview: "Overview",
  overviewKey: "overview-report",
  package: "Package",
  packageDetails: "Package Details",
  packageDbKey: 1,
  packageKey: "package",
  packageList: " Package List",
  paginationFalse: "pagination=false",
  paginationTrue: "pagination=true",
  paid: "Paid",
  paidAmount: "Paid Amount (INR)",
  panCard: "Pan Card",
  panNumber: "Pan Number",
  passingMark: "Passing Mark",
  password: "Password",
  passwordRequired: "Password must be 7 characters at minimum",
  passwordValidation:
    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
  paymentStatus: "Payment Status",
  paymentType: "Payment Type",
  permission: {
    DASHBOARD_VIEW: "dashboard_view",
    CATEGORY_VIEW: "category_view",
    CATEGORY_EDIT: "category_edit",
    CATEGORY_ADD: "category_add",
    CATEGORY_DELETE: "category_delete",
    COURSES_VIEW: "courses_view",
    COURSES_EDIT: "courses_edit",
    COURSES_ADD: "courses_add",
    COURSES_DELETE: "courses_delete",
    TRANSACTION_VIEW: "transaction_view",
    TRANSACTION_EDIT: "transaction_edit",
    PACKAGE_VIEW: "package_view",
    PACKAGE_EDIT: "package_edit",
    PACKAGE_ADD: "package_add",
    PACKAGE_DELETE: "package_delete",
    FREE_VIDEO_VIEW: "freeVideo_view",
    FREE_VIDEO_EDIT: "freeVideo_edit",
    FREE_VIDEO_ADD: "freeVideo_add",
    FREE_VIDEO_DELETE: "freeVideo_delete",
    PROMO_VIEW: "promocode_view",
    PROMO_EDIT: "promocode_edit",
    PROMO_ADD: "promocode_add",
    PROMO_DELETE: "promocode_delete",
    BANNER_VIEW: "banner_view",
    BANNER_EDIT: "banner_edit",
    BANNER_ADD: "banner_add",
    BANNER_DELETE: "banner_delete",
    EVENT_VIEW: "event_view",
    EVENT_EDIT: "event_edit",
    EVENT_ADD: "event_add",
    EVENT_DELETE: "event_delete",
    NEWS_VIEW: "news_view",
    NEWS_EDIT: "news_edit",
    NEWS_ADD: "news_add",
    NEWS_DELETE: "news_delete",
    USER_VIEW: "customers_view",
    USER_EDIT: "customers_edit",
    KYC_VIEW: "kyc_view",
    KYC_EDIT: "kyc_edit",
    AFFILIATE_VIEW: "affiliate_view",
    AFFILIATE_EDIT: "affiliate_edit",
    SUPPORT_VIEW: "support_view",
    SUPPORT_EDIT: "support_edit",
    WITHDRAW_VIEW: "withdraw_view",
    WITHDRAW_EDIT: "withdraw_edit",
    REPORT_VIEW: "report_view",
    ROLE_MGT_VIEW: "role_mgt_view",
    ROLE_MGT_EDIT: "role_mgt_edit",
    ROLE_MGT_ADD: "role_mgt_add",
    ROLE_MGT_DELETE: "role_mgt_delete",
    NOTIFI_VIEW: "notification_view",
    NOTIFI_ADD: "notification_add",
    CONFG_VIEW: "configuration_view",
    CONFG_EDIT: "configuration_edit",
    CONFG_ADD: "configuration_add",
    CONFG_DELETE: "configuration_delete",
  },
  permissionApiRoute: "/api/v1/",
  permissionBannerIdKey: "?banner_id=",
  permissionCtgryIdKey: "?category_id=",
  permissionCourseIdKey: "?course_id=",
  permissionEventIdKey: "?event_id=",
  permissionIdKey: "?id=",
  permissionKey: "permission",
  permissionKycUsers: "?kyc_request=true",
  permissionPkgIdKey: "?package_id=",
  permissionUserIdKey: "?user_id=",
  phoneNumber: "Phone Number",
  pm: "PM",
  price: "Price (INR)",
  problem: "Problem",
  promoCode: "Promo Code",
  promoCodeDiscount: "Promo Code Discount",
  promoCodeKey: "promocode",
  promocodesList: "Promo Code List",
  publish: "Publish",
  query: "Query",
  ques: "Question",
  quesList: "Question List",
  quiz: "Quiz",
  quizKey: "quiz",
  quizPassingPercent: "Passing percent",
  radioKey: "radio",
  reason: "Reason",
  recentlyAdded: "Recently Added",
  recommended: "Recommended",
  redirectionType: "Redirection Type",
  redirectionTypeOptions: [
    { key: "video", value: "Video" },
    { key: "form", value: "Form" },
    { key: "image", value: "Image" },
  ],
  referAmount: "Refer Amount",
  referCode: "Refer Code",
  reference: "Reference",
  referenceOptions: [
    { value: null, label: "All" },
    { value: "application", label: "Application" },
    { value: "social_media", label: "Social Media" },
    { value: "existing_affiliates", label: "Existing Affiliate" },
    { value: "other", label: "Other" },
  ],
  referredUsers: "Referred Useres",
  refreshToken: "refreshToken",
  refundCoursePercent: "Refund Course Percent",
  refundDays: "Refund Day(s)",
  refunded: "Refunded",
  refundedAmount: "Refunded Amount (INR)",
  refundFullKey: "full",
  reject: "Reject",
  rejectionReason: "Rejection Reason",
  remove: "Remove",
  report: "Report",
  reportKey: "report",
  reportMonth: "30",
  reportThreeMonths: "90",
  reportWeek: "7",
  reportYear: "365",
  required: "This field cannot be empty",
  requiredBannerOrVideo: "Either banner image or video is required",
  requiredKey: "Required",
  resendInvoiceOnMail: "Resend invoice",
  reset: "Reset",
  resources: "Resources",
  resourceList: "Resource List",
  response: "Response",
  responseDate: "Response Date",
  responseList: "Response List",
  revenue: "Revenue",
  reviews: "Reviews",
  reviewList: "Review List",
  role: "Role",
  roleId: "roleId",
  roleKey: "ROLE",
  roleMgt: "Role Management",
  roleMgtKey: "role_mgt",
  roleType: "role_type",
  search: "Search",
  searchByCode: "Search by Code...",
  searchByName: "Search by Name...",
  searchByNameEmail: "Search by Name, Email...",
  searchByNameEmailNumber: "Search by Name, Email, Number...",
  searchByNameEmailPanAadhaar:
    "Search by Name, Email, Aadhaar Number, Pan Number...",
  searchByNameTeacher: "Search by Name, Teacher Name...",
  seats: "Seat(s)",
  select: "Select",
  selectAll: "Select All",
  selectAns: "Select Answer(s)",
  selectCategory: "Select Category",
  selectDate: "Select Date",
  selected: "Selected",
  selectFormFieldType: "Select Input Type",
  selectLesson: "Select Lesson",
  selectionLimit: "Selection Limit",
  selectLessons: "Select Lessons",
  selectModule: "Select Module",
  sequentialLearning: "Sequential Learning",
  setRule: "Set Rule",
  setRuleOptions: [
    {
      value: "instant",
      label: "Instant",
    },
    {
      value: "scheduled",
      label: "Scheduled",
    },
  ],
  sevenDays: "7 days",
  signout: "Sign out",
  solution: "Solution",
  somethingWentWrong: "Something Went Wrong",
  startDate: "Start Date",
  startTime: "Start Time",
  state: "State",
  status: {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  },
  statusActiveKey: "Active",
  statusKey: "Status",
  strikeOutPrice: "Strike Out Price",
  subCategory: "Sub Category",
  subCategoryList: "Sub Category List",
  subject: "Subject",
  submit: "Submit",
  subTotal: "Sub Total",
  success: "Success",
  support: "Support",
  supportKey: "support",
  supportList: "Support List",
  supportOptions: [
    { value: "Progress", label: "Progress" },
    { value: "Resolved", label: "Resolved" },
    { value: "Pending", label: "Pending" },
  ],
  tax: "Tax (INR)",
  taxIncluded: "Tax Included",
  taxKey: "Tax",
  taxPercent: "Tax (%)",
  teacherDetail: "Teacher Detail",
  teacherName: "Teacher Name",
  teacherPhoto: "Teacher Photo",
  textKey: "text",
  timeFormat: "hh:mm A",
  timeSpent: "Time Spent (hr)",
  thirtyDays: "30 days",
  thumbnail: "Thumbnail",
  thumbnails: "Thumbnail(s)",
  time: "Time",
  title: "Title",
  title1: "Title 1",
  title2: "Title 2",
  topThree: "Top 3",
  total: "Total",
  totalAffiliateUsers: "Total Affiliate Users",
  totalAmount: "Total Amount",
  totalCourses: "Total Courses",
  totalDuration: "Total Duraction",
  totalEnrolledUsers: "Total Enrolled Users",
  totalEvents: "Total Events",
  totalLessons: "Total Lessons",
  totalPackages: "Total Packages",
  totalPromoCode: "Total Promo Code",
  totalReferralAmount: "Total Referral Amount",
  totalReferredCustomers: "Total Referred Customers",
  totalRevenue: "Total Revenue",
  totalSeats: "Total Seats",
  totalTransactions: "Total Transactions",
  totalUsers: "Total Users",
  transactions: "Transaction",
  transactionKey: "transaction",
  transactionList: "Transaction List",
  transactionId: "Transaction ID",
  transactionDate: "Transaction Date",
  transactionDetails: "Transaction Details",
  transactionFilterStatus: [
    {
      value: null,
      label: "All",
    },
    {
      value: "captured",
      label: "Paid",
    },
    {
      value: "Imported",
      label: "Imported",
    },
    {
      value: "authorized",
      label: "In Process",
    },
    {
      value: "failed",
      label: "Failed",
    },
    {
      value: "refund_requested",
      label: "Refund Requested",
    },
    {
      value: "refunded",
      label: "Refunded",
    },
    {
      value: "processed",
      label: "Refund Initiated",
    },
  ],
  transactionPaymentType: [
    {
      value: 0,
      label: "Course",
    },
    {
      value: 1,
      label: "Package",
    },
    {
      value: 2,
      label: "Banner Video",
    },
    {
      value: 3,
      label: "Event",
    },
  ],
  transactions_key: "transactions",
  type: "Type",
  typeCoursePckgOptions: [
    { value: "Course", label: "Course" },
    { value: "Package", label: "Package" },
    { value: "Event", label: "Event" },
  ],
  typeEnrollCoursePckgOptions: [
    { value: "Course", label: "Course" },
    { value: "Package", label: "Package" },
  ],
  uninstalls: "Uninstalls",
  updatedBy: "Updated By",
  updatedDate: "Updated Date",
  updatedTime: "Updated Time",
  upload: "Upload",
  uploadImageGif: "Upload Image/GIF",
  usedCount: "Used Count",
  usedLoyalty: "Used Loyalty Pt.",
  user: "user",
  userCertificate: "User Certificate",
  userDetail: "User Detail",
  userFormResponseKey: "userFormResponse",
  userName: "User Name",
  users: "Users",
  usersKey: "users",
  usersLatest: "Latest Users",
  usersLatestEnrolled: "Latest Enrolled Users",
  usersList: "User List",
  usersSearch: "Name, Email, Number...",
  usersType: "User Type",
  usersTypeOptions: [
    {
      value: "enrolled",
      label: "Enrolled",
    },
    {
      value: "unenrolled",
      label: "Unenrolled",
    },
    // {
    //   value: "ibc",
    //   label: "IBC",
    // },
    // {
    //   value: "cp",
    //   label: "CP",
    // },
  ],
  validLinkRegex: /^https:\/\/\S+$/,
  validNameRegex: /^[A-Za-z ]{1,40}$/,
  validPasswordRegex:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%#*?&]{7,}$/,
  validPhoneNumber: /^[6-9]\d{9}$/,
  validFrom: "Valid From",
  validtill: "Valid Till",
  validTill: "Valid To",
  video: "Video",
  videoKey: "video",
  view: "View",
  viewKey: "view",
  walkthroughScreenList: "Walkthrough Screen List",
  watchTime: "Watch Time (hr)",
  weekDays: [
    { value: 0, label: "Monday" },
    { value: 1, label: "Tuesday" },
    { value: 2, label: "Wednesday" },
    { value: 3, label: "Thursday" },
    { value: 4, label: "Friday" },
    { value: 5, label: "Saturday" },
    { value: 6, label: "Sunday" },
  ],
  welcome: "Welcome",
  welcomeEmail: "Welcome Email",
  welcomeEmailFormat: "Welcome Email Format",
  width: "Width",
  withdraw: "Withdraw",
  withdrawKey: "withdraw",
  withdrawList: "Withdraw List",
  whatsAppPhoneNumber: "WhatsApp Number",
  yearOptions: [
    { label: "Jan", value: 1 },
    { label: "Feb", value: 2 },
    { label: "Mar", value: 3 },
    { label: "Apr", value: 4 },
    { label: "May", value: 5 },
    { label: "Jun", value: 6 },
    { label: "Jul", value: 7 },
    { label: "Aug", value: 8 },
    { label: "Sep", value: 9 },
    { label: "Oct", value: 10 },
    { label: "Nov", value: 11 },
    { label: "Dec", value: 12 },
  ],
  yes: "Yes",
  youWantToSignOut: "You want to sign out?",
};
