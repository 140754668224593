/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userCommentsList } from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import { Constants } from "../../Common/Constants";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import EditListButton from "../../Common/Fields/EditListButton";
import PageHeader from "../../Common/PageHeader";
import TableList from "../../Common/UiModel/TableList";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import CommentForm from "./CommentForm";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import usePermissions from "../../Common/Functions/usePermissions";

const Comments = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { usercommentslist, popupmodel } = useSelector((state) => state.Users);
  const [userCommentsData, setUserCommentsData] = useState([]);
  const editPermission = usePermissions(Constants.permission.USER_EDIT);

  useEffect(() => {
    // User comment list API
    dispatch(userCommentsList(id));
    dispatch({
      type: types.USER_COMMENTS_SINGLE,
      payload: "",
    });
  }, []);

  useEffect(() => {
    // Set user comment data
    setUserCommentsData(usercommentslist.data);
  }, [usercommentslist]);

  // Columns to free Video Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) => i + 1,
    },
    {
      name: Constants.problem,
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.problem}
        >
          {row?.problem?.slice(0, 20) + "..."}
        </span>
      ),
    },
    {
      name: Constants.solution,
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.solution}
        >
          {row?.solution?.slice(0, 20) + "..."}
        </span>
      ),
    },
    {
      name: Constants.date,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.time,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    {
      name: Constants.name,
      cell: (row) => row?.comment_updated_by?.name || Constants.admin,
    },
    {
      name: Constants.actions,
      cell: (row) => (
        // Actions for user comment
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <EditListButton
                      onClick={() => {
                        dispatch({
                          type: types.USER_COMMENTS_FORM_MODEL,
                          payload: {
                            msg: Constants.editComments,
                            show: true,
                            add: 0,
                          },
                        });
                        dispatch({
                          type: types.USER_COMMENTS_SINGLE,
                          payload: row,
                        });
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="mt-4">
      <PageHeader
        pagetitle={Constants.commentList}
        buttonLink={editPermission}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: types.USER_COMMENTS_FORM_MODEL,
            payload: { msg: Constants.addComments, show: true },
          });
        }}
      />
      <TableList
        columns={columns}
        data={userCommentsData}
        defaultSortFieldId
        highlightOnHover
      />
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.USER_COMMENTS_SINGLE, payload: "" });
          dispatch({ type: types.USER_COMMENTS_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<CommentForm />}
      />
    </div>
  );
};

export default Comments;
