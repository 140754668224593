import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const promocodesAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.PROMOCODES, data);
  return response;
};

export const promocodesEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.PROMOCODES + "?id=" + id,
    data
  );
  return response;
};

export const promocodesRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.PROMOCODES + "?id=" + id
  );
  return response;
};

export const promocodesStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.PROMOCODES_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const promocodes = async (data) => {
  let url = `${ApiUrl.PROMOCODES}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }${data && data?.search ? `&search=${data?.search}` : ""}${
    data?.start_date
      ? `&start_date=${data?.start_date}&end_date=${data?.end_date}`
      : ""
  }${data?.month ? `&month=${data?.month}` : ""}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const detailPromocode = async (id) => {
  const response = await httpServiceInstance.get(
    ApiUrl.PROMOCODES + `?id=${id}`
  );
  return response;
};

export const usersPromocode = async (data) => {
  const response = await httpServiceInstance.get(
    ApiUrl.PROMOCODES_USERS +
      `?id=${data?.id}&skip=${data?.skip || 0}${
        data?.limit ? `&limit=${data?.limit}` : ""
      }`
  );
  return response;
};
