import React from "react";
import { useLocation } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import Sidebar from "./components/Common/Sidebar";
import LoaderSpinner from "./helper/LoaderSpinner";
import { useSelector } from "react-redux";
import { RoutesUrl } from "./components/Common/RoutesUrl";

function App() {
  const location = useLocation();
  const activekey = location.pathname;
  const { isLoader } = useSelector((state) => state.Loader);

  return (
    <div id="ebazar-layout" className="theme-blue">
      {isLoader && <LoaderSpinner />}
      {activekey !== RoutesUrl.signIn &&
        activekey !== RoutesUrl.pageNotFound && (
          <Sidebar activekey={activekey} />
        )}
      <MainRoutes activekey={activekey} />
    </div>
  );
}

export default App;
