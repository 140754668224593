import httpServiceInstance from "../helper/HttpService";
import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";

export const withdrawRequests = async (data) => {
  const url = `${ApiUrl.WITHDRAW}${
    data?.skipPagination
      ? `?${Constants.paginationFalse}`
      : `?skip=${data?.skip || 0}`
  }&limit=${data?.limit || Constants.limitCount}${
    data && data?.search ? `&search=${data?.search}` : ""
  }`;

  const response = await httpServiceInstance.get(url);
  return response;
};

export const detailwithdraw = async (id) => {
  const response = await httpServiceInstance.get(ApiUrl.WITHDRAW + `?id=${id}`);
  return response;
};
