import HttpService from "../helper/HttpService";
import { ApiUrl } from "../components/Common/ApiUrl";
import Axios from "../helper/HttpService";
import { Constants } from "../components/Common/Constants";

export const loginUser = async (data) => {
  const response = await HttpService.post(ApiUrl.LOGIN, data);
  return response;
};

export const userPermission = () => {
  const roleId = localStorage.getItem(Constants.roleId);
  if (roleId) {
    const url = ApiUrl.PERMISSION + `?role=${roleId}`;
    return Axios.get(url).then((response) => {
      return response;
    });
  }
  return null;
};

export const logoutUser = async () => {
  const response = await HttpService.post(ApiUrl.LOGOUT);
  return response;
};

export const dashboardData = async () => {
  const response = await HttpService.get(ApiUrl.DASHBOARD);
  return response;
};
