/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { Constants } from "../Common/Constants";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  packagesDetail,
  packagesInsert,
  packagesList,
  packagesUpdate,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import TextArea from "../Common/Fields/TextArea";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import ToggleField from "../Common/Fields/ToggleField";
import { useParams } from "react-router-dom";
import usePermissions from "../Common/Functions/usePermissions";

const PackageForm = () => {
  const dispatch = useDispatch();
  const { packagesSingle } = useSelector((state) => state.Packages);
  const { id } = useParams();
  // Initial values for package form
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    strike_out_price: "",
    original_price: "",
    image: "",
    tax_percentage: "",
    tax_included: false,
  });
  const addPermission = usePermissions(Constants.permission.PACKAGE_ADD);
  const editPermission = usePermissions(Constants.permission.PACKAGE_EDIT);

  // Validations for package form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    image: Yup.string().required(Constants.required),
    strike_out_price: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidPriceRegex, Constants.invalidPrice),
    original_price: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidPriceRegex, Constants.invalidPrice)
      // Validation - Orginal price should be less than strike out price
      .test(
        "lessThanStrikeOutPrice",
        Constants.originalPriceLessThanStrikeOutPrice,
        function (value) {
          const { strike_out_price } = this.parent;
          return parseFloat(value) < parseFloat(strike_out_price);
        }
      ),
    tax_percentage: Yup.string().test(
      "taxValidation",
      Constants.invalid,
      function (value) {
        const taxIncluded = this.parent.tax_included;
        if (taxIncluded) {
          return value && Constants.invalidPercentRegex.test(value);
        }
        return true;
      }
    ),
  });

  useEffect(() => {
    // Set initial values to pre fill the package form
    if (!!packagesSingle) {
      let editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        editValue[key] = packagesSingle[key];
        setInitialValues(editValue);
      }
    }
  }, [packagesSingle]);

  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update package API
        let submitFunc = packagesInsert(values);
        if (id) {
          submitFunc = packagesUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            if (id) {
              dispatch(packagesDetail(id));
            } else dispatch(packagesList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.PACKAGES_SINGLE, payload: "" });
            dispatch({ type: types.PACKAGES_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="strike_out_price"
              className="form-control"
              errors={
                touched?.strike_out_price ? errors?.strike_out_price : undefined
              }
              touched={touched?.strike_out_price}
              label={Constants.strikeOutPrice}
            />
          </div>
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="original_price"
              className="form-control"
              errors={
                touched?.original_price ? errors?.original_price : undefined
              }
              touched={touched?.original_price}
              label={Constants.originalPrice}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.image}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.image && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="375"
                    height="249"
                    errors={touched?.image ? errors?.image : undefined}
                    touched={touched?.image}
                    label={`${Constants.upload} ${Constants.image}`}
                    handleFile={(files) =>
                      handleFileChange(files, setFieldValue, "image")
                    }
                    maxFiles={1}
                  />
                )}

                {values && values?.image && (
                  <ViewImageFile
                    file={values?.image}
                    onClick={() => setFieldValue(`image`, "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-2 mb-2">
            <label className="form-label">{Constants.taxKey}</label>
            <div className="flex-toggle ">
              <ToggleField
                onChange={(e) => {
                  setFieldValue("tax_included", e.target.checked);
                }}
                checked={values.tax_included}
              />
            </div>
          </div>
          {values?.tax_included && (
            <div className="col-md-4 mb-2">
              <TextField
                type="text"
                name="tax_percentage"
                className="form-control"
                errors={
                  touched?.tax_percentage ? errors?.tax_percentage : undefined
                }
                touched={touched?.tax_percentage}
                label={Constants.taxPercent}
              />
            </div>
          )}
          {((addPermission && !packagesSingle?._id) ||
            (editPermission && packagesSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PackageForm;
