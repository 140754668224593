/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "./CardBlock";
import { leadStatusUpdate, usersDetail } from "../../Redux/Actions/Users";
import { Constants } from "../Common/Constants";
import UserCourseList from "./UserCourseList";
import PageHeader from "../Common/PageHeader";
import { RoutesUrl } from "../Common/RoutesUrl";
import EnrollUser from "./EnrollUser";
import Comments from "./Comments";
import ReactSelect from "../Common/Fields/ReactSelect";
import { types } from "../../Redux/Actions/types";
import AbandonedCourses from "./AbandonedCourses";
import dayjs from "dayjs";
import usePermissions from "../Common/Functions/usePermissions";

const UserDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { usersSingle } = useSelector((state) => state.Users);
  const userEditPermission = usePermissions(Constants.permission.USER_EDIT);
  const kycViewPermission = usePermissions(Constants.permission.KYC_VIEW);

  useEffect(() => {
    // User detail API
    dispatch(usersDetail(id));
  }, []);

  const handleLeadStatusChange = (selected) => {
    const userId = JSON.parse(localStorage.getItem(Constants.user))?._id;
    const data = {
      lead_status: selected.value,
      lead_status_updated_by: userId,
    };
    dispatch(leadStatusUpdate(id, data)).then((response) => {
      if (response?.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response?.error,
        });
      } else {
        dispatch(usersDetail(id));
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response?.data?.message,
        });
      }
    });
  };

  const handleKycClick = () => {
    window.open(`${RoutesUrl.kyc}/${id}`, "_blank");
  };

  return (
    <div className="container-xxl mb-4">
      <PageHeader pagetitle={usersSingle?.user?.name} list={RoutesUrl?.users} />
      {/* Personal Details */}
      <CardBlock user={usersSingle?.user} />
      <div className="row">
        {/* Other details */}
        <div className="col-md-4 d-flex">
          <div className="card mt-4 border-shadow w-100 card-clr card-red card-user-details">
            <h4 className="justfy-bw">
              {Constants.userDetail}
              <h5 className="text-f user-tag">
                {usersSingle?.user?.signup_device_type}
              </h5>
            </h4>
            <div className="h5 line-b">
              <p>{`${Constants.age} : ${usersSingle?.user?.age} (${
                Constants.genderOptions.find(
                  (elt) => elt.value === usersSingle?.user?.gender
                )?.label
              })`}</p>
              <p>{`${Constants.city} : ${
                usersSingle?.user?.address?.city || Constants.na
              } (${usersSingle?.user?.address?.state || Constants.na})`}</p>
              <p>{`${Constants.totalReferredCustomers} : ${usersSingle.totalReferredUsers}`}</p>
              <p>{`${Constants.totalReferralAmount} : ${Constants.currency}${
                usersSingle?.user?.refer_cashback || 0
              }`}</p>
              <p>{`${Constants.referCode} : ${
                usersSingle?.user?.refer_code || Constants.na
              }`}</p>
            </div>
          </div>
        </div>
        {/* Status */}
        <div className="col-md-4 d-flex">
          <div className="card mt-4 border-shadow w-100 card-clr card-user-details">
            <h4>{Constants.other}</h4>
            <div className="h5 line-b">
              <p className="d-flex align-items-center justfy-bw">
                <div>
                  {`${Constants.kycStatus} : ${
                    Constants.kycRequests?.find(
                      (elt) => elt.value === usersSingle?.user?.kyc_status
                    )?.label || Constants.notApplied
                  }`}
                  {usersSingle?.user?.kyc_status && kycViewPermission ? (
                    <i
                      className="cursor-pointer icofont-info-circle ms-2"
                      onClick={() => {
                        handleKycClick();
                      }}
                    ></i>
                  ) : (
                    ""
                  )}
                </div>
                {usersSingle?.user?.kyc_status ? (
                  <div className="info_ic d-flex align-items-center">
                    <div className="time-slot">
                      <h5>
                        {usersSingle?.user?.kyc_status_updated_by?.name ||
                          Constants.admin}
                      </h5>
                      <h6>{`${dayjs(
                        usersSingle?.user?.kyc_status_updated_at
                      ).format(Constants.dateFormat)} ${dayjs(
                        usersSingle?.user?.kyc_status_updated_at
                      ).format(Constants.timeFormat)}`}</h6>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </p>
              <p>{`${Constants.gstStatus} : ${
                usersSingle?.user?.gst_invoice_status
                  ? Constants.enabled
                  : Constants.disabled
              }`}</p>
              <p className="justfy-bw">
                {`${Constants.leadStatus} : ${
                  Constants.leadStatusOptions.find(
                    (elt) => elt.value === usersSingle?.user?.lead_status
                  )?.label || Constants.leadStatusOptions?.[0]?.label
                } `}
                <div className="time-slot">
                  <h5>
                    {usersSingle?.user?.lead_status_updated_by?.name ||
                      Constants.admin}
                  </h5>
                  <h6>{`${dayjs(
                    usersSingle?.user?.lead_status_updated_at
                  ).format(Constants.dateFormat)} ${dayjs(
                    usersSingle?.user?.lead_status_updated_at
                  ).format(Constants.timeFormat)}`}</h6>
                </div>
              </p>
              {userEditPermission && (
                <div className="mx-3">
                  <ReactSelect
                    required={false}
                    options={Constants.leadStatusOptions}
                    placeholder={Constants.changeLeadStatus}
                    onChange={(e) => handleLeadStatusChange(e)}
                  />
                </div>
              )}
              <p>{`${Constants.loyaltyPoints} : ${
                usersSingle?.user?.loyalty_points.toFixed(2) || 0
              }`}</p>
            </div>
          </div>
        </div>
        {/* Goals */}
        <div className="col-md-4 d-flex">
          <div className="card mt-4 border-shadow w-100 card-clr card-red card-user-details">
            <h4>{`${Constants.selected} ${Constants.goals}`}</h4>
            <div className="h5 bg-p">
              {usersSingle?.user?.goals?.length > 0
                ? usersSingle?.user?.goals?.map((goal) => (
                    <p key={goal._id}>{goal.name}</p>
                  ))
                : Constants.na}
            </div>
          </div>
        </div>
        {/* Course List */}
        <UserCourseList courses={usersSingle?.courses || []} />
        <AbandonedCourses courses={usersSingle?.abandanedCourse || []} />
        <div>
          <EnrollUser />
        </div>
        <div className="my-4">
          <Comments />
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
