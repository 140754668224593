/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Constants } from "../../Common/Constants";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import TabsBootstrap from "../../Common/UiModel/TabsBootstrap";
import CourseDetailPage from "./Detail";
import Lesson from "./Lessons";
import Resources from "./Resources";
import CoursesFaqs from "./Faqs";
import Reviews from "./Reviews";
import WelcomeEmail from "./WelcomeEmail";
import { useDispatch, useSelector } from "react-redux";
import { coursesDetail } from "../../../Redux/Actions";
import { useParams } from "react-router-dom";
import QuizDetail from "./Quiz";
import Modules from "./Modules";
import EnrolledUsersList from "./EnrolledUsers";
import SetRule from "./SetRule";
import PageHeader from "../../Common/PageHeader";
import { RoutesUrl } from "../../Common/RoutesUrl";
import usePermissions from "../../Common/Functions/usePermissions";

const CourseDetail = () => {
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);

  // initial tabs
  const initialTabsList = [
    {
      eventKey: "detail",
      title: Constants.detail,
      component: <CourseDetailPage />,
    },
    {
      eventKey: "setRule",
      title: Constants.setRule,
      component: <SetRule />,
    },
    {
      eventKey: "lesson",
      title: Constants.lessons,
      component: <Lesson />,
    },
    {
      eventKey: "resource",
      title: Constants.resources,
      component: <Resources />,
    },
    {
      eventKey: "review",
      title: Constants.reviews,
      component: <Reviews />,
    },
    {
      eventKey: "faqs",
      title: Constants.faqs,
      component: <CoursesFaqs />,
    },
    {
      eventKey: "email",
      title: Constants.welcomeEmail,
      component: (
        <WelcomeEmail
          type={Constants.course}
          addPermission={addPermission}
          editPermission={editPermission}
        />
      ),
    },
    {
      eventKey: "users",
      title: Constants.enrolledUsers,
      component: <EnrolledUsersList type={Constants.course} />,
    },
  ];

  const dispatch = useDispatch();
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const activeTab = params.get("activeTab");
  const { coursesSingle } = useSelector((state) => state.Courses);
  const { id } = useParams();
  const [tabsList, setTabsList] = useState(initialTabsList);
  const [activetab, setActiveTab] = useState(
    activeTab || tabsList?.[0]?.eventKey
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(coursesDetail(id));
  }, []);

  useEffect(() => {
    let list = [...tabsList];
    if (
      coursesSingle?.quiz &&
      list.findIndex((elt) => elt.eventKey === Constants.quizKey) === -1
    ) {
      list = [
        ...list,
        {
          eventKey: Constants.quizKey,
          title: Constants.quiz,
          component: <QuizDetail />,
        },
      ];
    }
    if (coursesSingle.module_lessons) {
      let index = list.findIndex((elt) => elt.eventKey === "lesson");
      if (index !== -1) {
        const moduleObj = {
          eventKey: "module",
          title: Constants.modules,
          component: <Modules />,
        };
        list.splice(index, 1, moduleObj);
      }
    }
    setTabsList(list);
  }, [coursesSingle]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div className="container-xxl common-space mt-0">
      <PageHeader pagetitle={coursesSingle?.name} list={RoutesUrl.courses} />
      <div className="row catalog_box al_catalog_box">
        <div className="col-md-12 mb-4 order-list-view">
          {tabsList.length > 0 && (
            <TabsBootstrap
              defaultActiveKey={activetab}
              onSelect={(index) => {
                setActiveTab(index);
              }}
              activeKey={activetab}
              handleTabSelect={handleTabSelect}
              options={tabsList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
