/* eslint-disable react-hooks/exhaustive-deps */
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import EventForm from "../EventForm";

const EventDetailPage = () => {
  return (
    <div className="mt-4 course-form card">
      <PageHeader pagetitle={Constants.detail} />
      <EventForm />
    </div>
  );
};

export default EventDetailPage;
