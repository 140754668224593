/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../Common/PageHeader";
import { types } from "../../../Redux/Actions/types";
import { Constants } from "../../Common/Constants";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import CategoryForm from "./CategoryForm";
import { Dropdown } from "react-bootstrap";
import {
  categoryDelete,
  categoryList,
  categoryUpdateStatus,
} from "../../../Redux/Actions";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";
import responseMethod from "../../Common/Functions/responseMethod";
import TableList from "../../Common/UiModel/TableList";
import dayjs from "dayjs";
import EditListButton from "../../Common/Fields/EditListButton";
import DeleteListButton from "../../Common/Fields/DeleteListButton";
import ToggleListField from "../../Common/Fields/ToggleListField";
import ReactPagination from "../../Common/Fields/ReactPagination";
import { Link } from "react-router-dom";
import usePermissions from "../../Common/Functions/usePermissions";

const Category = () => {
  const dispatch = useDispatch();
  const { categorylist, popupmodel } = useSelector((state) => state.Category);
  const [categoryData, setCategoryData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const addPermission = usePermissions(Constants.permission.CATEGORY_ADD);
  const editPermission = usePermissions(Constants.permission.CATEGORY_EDIT);
  const deletePermission = usePermissions(Constants.permission.CATEGORY_DELETE);

  useEffect(() => {
    // Category list API
    dispatch(categoryList());
    dispatch({
      type: types.CATEGORY_SINGLE,
      payload: "",
    });
  }, []);

  useEffect(() => {
    // Set category data
    setCategoryData(categorylist.data);
  }, [categorylist]);

  const onDelete = (delete_id) => {
    // Delete category
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(categoryDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(categoryList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  const triggerToggle = (rowData) => {
    // Update status toggle (used in App) for category
    let itemId = rowData._id;
    let data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(categoryUpdateStatus(itemId, data)).then((response) => {
      let tempList = categoryData;
      let index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].status = data.status;
      setCategoryData(tempList);
      responseMethod(response, dispatch, categoryList);
    });
  };

  const handleEdit = (row) => {
    dispatch({
      type: types.CATEGORY_FORM_MODEL,
      payload: {
        msg: Constants.editCategory,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.CATEGORY_SINGLE,
      payload: row,
    });
  };

  // Columns to free Video Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.image,
      width: "10%",
      selector: (row) => (
        <div
          className={`img-out ${
            row.status === Constants.status.ACTIVE
              ? "active-status"
              : "inactive-status"
          }`}
        >
          <img
            src={row?.banner_image}
            alt={row?.name}
            style={{
              maxHeight: "100px",
              maxWidth: "100px",
            }}
          />
        </div>
      ),
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          {row?.name}
        </Link>
      ),
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    {
      name: Constants.createdBy,
      cell: () => Constants.admin,
    },
    {
      name: Constants.actions,
      cell: (row) => (
        // Actions for category
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  {editPermission && (
                    <>
                      <div className="list-group">
                        <EditListButton
                          onClick={() => {
                            handleEdit(row);
                          }}
                        />
                      </div>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.statusKey}
                          onChange={() => {
                            triggerToggle(row);
                          }}
                          checked={row.status === Constants.status.ACTIVE}
                        />
                      </div>
                    </>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  const handleOffset = (newOff) => {
    let data = {
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(categoryList(data));
  };

  return (
    <div className="container-xxl mt-3">
      <PageHeader
        pagetitle={Constants.categoryList}
        buttonLink={addPermission}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: types.CATEGORY_FORM_MODEL,
            payload: { msg: Constants.addCategory, show: true },
          });
        }}
      />
      <TableList
        columns={columns}
        data={categoryData}
        defaultSortFieldId
        highlightOnHover
      />
      {categorylist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={categorylist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <div id="add-category-form">
        <ModalPopup
          show={popupmodel}
          onHide={() => {
            dispatch({ type: types.CATEGORY_SINGLE, payload: "" });
            dispatch({ type: types.CATEGORY_FORM_MODEL, payload: "" });
          }}
          dialogClassName={"modal-lg"}
          title={popupmodel.msg}
          component={<CategoryForm />}
        />
      </div>
    </div>
  );
};

export default Category;
