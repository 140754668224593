import {
  reviews,
  reviewsAdd,
  reviewsEdit,
  reviewsRemove,
  reviewsStatusUpdate,
} from "../../../services/Courses/reviews.service";
import { types } from "../types";

export const reviewsInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await reviewsAdd(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const reviewsList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await reviews(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.REVIEWS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.REVIEWS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const reviewsUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await reviewsEdit(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const reviewsDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await reviewsRemove(id);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const reviewsUpdateStatus = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await reviewsStatusUpdate(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
