import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const coursesAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.COURSES, data);
  return response;
};

export const coursesEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.COURSES + "?id=" + id,
    data
  );
  return response;
};

export const coursesRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.COURSES + "?id=" + id
  );
  return response;
};

export const coursesStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.COURSES_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const coursesRecommendedUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.COURSES_RECOMMENDED_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const courses = async (data) => {
  const url = `${ApiUrl.COURSES}?${
    data?.skipPagination ? Constants.paginationFalse : `skip=${data?.skip || 0}`
  }&limit=${data?.limit || Constants.limitCount}${
    data && data && data?.search ? `&search=${data?.search}` : ""
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const detailCourse = async (id) => {
  const response = await httpServiceInstance.get(ApiUrl.COURSES + `?id=${id}`);
  return response;
};

export const courseCategory = async (data) => {
  const response = await httpServiceInstance.get(
    ApiUrl.COURSES +
      `?${Constants.paginationFalse}${
        data?.category_id ? `&category_id=${data?.category_id}` : ""
      }${data?.subcategory_id ? `&subcategory_id=${data?.subcategory_id}` : ""}`
  );
  return response;
};
