import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const freeVideosAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.FREE_VIDEOS, data);
  return response;
};

export const freeVideosEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.FREE_VIDEOS + "?id=" + id,
    data
  );
  return response;
};

export const freeVideosRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.FREE_VIDEOS + "?id=" + id
  );
  return response;
};

export const freeVideosStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.FREE_VIDEOS_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const freeVideos = async (data) => {
  let url = `${ApiUrl.FREE_VIDEOS}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }${data && data?.search ? `&search=${data?.search}` : ""}`;
  const response = await httpServiceInstance.get(url);
  return response;
};
