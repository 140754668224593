/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { eventsUsers } from "../../../Redux/Actions";
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import TableList from "../../Common/UiModel/TableList";
import dayjs from "dayjs";
import { RoutesUrl } from "../../Common/RoutesUrl";
import ReactPagination from "../../Common/Fields/ReactPagination";
import usePermissions from "../../Common/Functions/usePermissions";

const EventResponsePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { eventsuserslist } = useSelector((state) => state.Events);
  const [eventUsersData, setEventsUsersData] = useState([]);
  const userViewPermission = usePermissions(Constants.permission.USER_VIEW);
  const transactionViewPermission = usePermissions(
    Constants.permission.TRANSACTION_VIEW
  );

  useEffect(() => {
    // Event User list API
    const data = {
      id,
    };
    dispatch(eventsUsers(data));
  }, []);

  useEffect(() => {
    // Set event users data
    setEventsUsersData(eventsuserslist);
  }, [eventsuserslist]);

  // Columns for Response Table List
  const columns = [
    {
      name: Constants.name,
      cell: (row) => (
        <Link
          to=""
          onClick={() => {
            if (userViewPermission)
              window.open(`${RoutesUrl.users}/${row?._id}`, "_blank");
          }}
        >
          {row?.name || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.transactionId,
      cell: (row) => (
        <Link
          to=""
          onClick={() => {
            if (transactionViewPermission)
              window.open(
                `${RoutesUrl.transactions}/${row?.transaction_id}`,
                "_blank"
              );
          }}
        >
          {row?.razorpay_payment_id || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.seats,
      cell: (row) => row?.booked_seats || 0,
    },
    {
      name: Constants.email,
      cell: (row) => row?.email || Constants.na,
    },
    {
      name: Constants.number,
      cell: (row) => `${row?.dial_code} ${row?.phone_number}`,
    },
    {
      name: Constants.responseDate,
      cell: (row) => dayjs(row?.registered_date).format(Constants.dateFormat),
    },
  ];

  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      id,
    };
    dispatch(eventsUsers(data));
  };

  return (
    <>
      <div className="mt-3">
        <PageHeader pagetitle={Constants.responseList} />
      </div>
      <TableList
        columns={columns}
        data={eventUsersData}
        defaultSortFieldId
        highlightOnHover
      />
      {eventsuserslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={eventsuserslist?.total}
          newOffset={handleOffset}
        />
      )}
    </>
  );
};

export default EventResponsePage;
