/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  enrolledUsersDateChange,
  enrolledUsersDetail,
} from "../../../../Redux/Actions";
import PageHeader from "../../../Common/PageHeader";
import TableList from "../../../Common/UiModel/TableList";
import { Constants } from "../../../Common/Constants";
import secondsToHour from "../../../Common/Functions/secondsToHour";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import DatePickerReact from "../../../Common/Fields/DatePickerReact";
import { deleteConfirmBox } from "../../../Common/Functions/deleteConfirmBox";
import { types } from "../../../../Redux/Actions/types";
import { RoutesUrl } from "../../../Common/RoutesUrl";
import { ApiUrl } from "../../../Common/ApiUrl";
import usePermissions from "../../../Common/Functions/usePermissions";

const EnrolledUserDetail = () => {
  const { id, userId } = useParams();
  const dispatch = useDispatch();
  const { enrolledUsersSingle } = useSelector((state) => state.EnrolledUsers);
  const [date, setDate] = useState(new Date());
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);

  useEffect(() => {
    dispatch(enrolledUsersDetail(id, userId));
  }, []);

  useEffect(() => {
    if (enrolledUsersSingle?.user?.enrollmentDate) {
      const dateObject = new Date(enrolledUsersSingle?.user?.enrollmentDate);
      setDate(dateObject);
    }
  }, [enrolledUsersSingle]);

  const columns = [
    {
      name: Constants.lessonName,
      cell: (row) => row?.name,
    },
    {
      name: Constants.moduleName,
      cell: (row) => row?.module?.name || Constants.na,
    },
    {
      name: Constants.duration,
      cell: (row) => (row?.duration ? secondsToHour(row?.duration) : 0),
    },
    {
      name: Constants.timeSpent,
      cell: (row) =>
        row?.totalTimeSpend ? secondsToHour(row?.totalTimeSpend) : 0,
    },
  ];

  const handleEnrollmenrDateChange = (date) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        let data = { enrollmentDate: date };
        dispatch(enrolledUsersDateChange(data, id, userId)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(enrolledUsersDetail(id, userId));
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      } else {
        const dateObject = new Date(enrolledUsersSingle?.user?.enrollmentDate);
        setDate(dateObject);
      }
    });
  };

  const downloadCertificate = () => {
    let url = `${process.env.REACT_APP_API_STAGING_URL}${ApiUrl.DOWNLOAD_CERTIFICATE}?course_id=${id}&user_id=${enrolledUsersSingle?.user?._id}`;
    const link = document.createElement("a");
    const fileName = "file.extension";
    link.setAttribute("download", fileName);
    link.setAttribute("target", "_blank");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <div className="container-xxl mt-3">
        <div className="row">
          <div className="col-md-6">
            <PageHeader
              pagetitle={enrolledUsersSingle?.user?.name}
              list={`${RoutesUrl?.courses}/${id}?activeTab=users`}
            />
          </div>
          <div className="col-md-6">
            <div className=" d-flex position-inherit gap-3 justify-content-end mob-filter-m">
              {/* <div className="col-md-5"></div> */}
              <div className="col-md-6 mt-0" style={{ zIndex: 2 }}>
                <label className="me-4 form-label">
                  {Constants.changeEnrollmentDate}
                </label>
                {date && (
                  <DatePickerReact
                    onChange={(date) => {
                      handleEnrollmenrDateChange(date);
                    }}
                    date={date}
                    editPermission={editPermission}
                  />
                )}
              </div>
              {enrolledUsersSingle?.user?.course_completion === 100 && (
                <div className="col-md-4 mt-2 justify-content-end btn-download">
                  <ButtonCustom
                    label="Download Certificate"
                    onClick={() => {
                      downloadCertificate();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2" style={{ position: "relative", zIndex: 1 }}>
          <TableList
            columns={columns}
            data={enrolledUsersSingle?.lessons}
            defaultSortFieldId
            highlightOnHover
          />
        </div>
      </div>
    </>
  );
};

export default EnrolledUserDetail;
