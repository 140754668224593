import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import React, { useState, useEffect } from "react";

const DateRangePicker = (props) => {
  const [state, setState] = useState([props.initialDate]);

  useEffect(() => {
    setState([props.initialDate]);
  }, [props.initialDate]);

  return (
    <DateRange
      onChange={(item) => {
        setState([item.selection]);
        props.rangeSelected([item.selection]);
      }}
      showSelectionPreview={false}
      showMonthAndYearPickers={false}
      showDateDisplay={false}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={state}
      direction="horizontal"
    />
  );
};

export default DateRangePicker;
