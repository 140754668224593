import { types } from "../../Actions/types";

const initialState = {
  resourceslist: [],
  error: null,
  resourcesSingle: "",
  popupmodel: "",
};

export default function Resources(state = initialState, action) {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.RESOURCES_LIST:
      return {
        ...state,
        resourceslist: payload,
        error: error,
      };
    case types.RESOURCES_SINGLE:
      return {
        ...state,
        resourcesSingle: payload,
        error: error,
      };
    case types.RESOURCES_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}
