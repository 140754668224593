/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  eventsDelete,
  eventsList,
  eventsUpdateStatus,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { Constants } from "../Common/Constants";
import responseMethod from "../Common/Functions/responseMethod";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import { RoutesUrl } from "../Common/RoutesUrl";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ModalPopup from "../Common/UiModel/ModalPopup";
import EventForm from "./EventForm";
import dayjs from "dayjs";
import convertToAmPmFormat from "../Common/Functions/convertToAmPmFormat";
import ReactPagination from "../Common/Fields/ReactPagination";
import ViewListButton from "../Common/Fields/ViewListButton";
import DeleteListButton from "../Common/Fields/DeleteListButton";
import ToggleListField from "../Common/Fields/ToggleListField";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ResponseListButton from "../Common/Fields/ResponseListButton";
import usePermissions from "../Common/Functions/usePermissions";

const Event = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventslist, popupmodel } = useSelector((state) => state.Events);
  const [eventsData, setEventsData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const addPermission = usePermissions(Constants.permission.EVENT_ADD);
  const editPermission = usePermissions(Constants.permission.EVENT_EDIT);
  const deletePermission = usePermissions(Constants.permission.EVENT_DELETE);

  useEffect(() => {
    // Event list api
    const data = {
      skip: 0,
      limit: Constants.limitCount,
    };
    dispatch(eventsList(data));
    dispatch({
      type: types.EVENTS_SINGLE,
      payload: [],
    });
  }, []);

  useEffect(() => {
    // Set data for events from event store
    setEventsData(eventslist.data);
  }, [eventslist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const getData = (searchKey) => {
    const data = {
      skip: 0,
      limit: Constants.limitCount,
      search: searchKey || "",
    };
    dispatch(eventsList(data));
  };

  const triggerToggle = (rowData) => {
    // Trigger status toggle for event
    let itemId = rowData._id;
    let data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(eventsUpdateStatus(itemId, data)).then((response) => {
      responseMethod(response, dispatch, eventsList);
    });
  };

  const onDelete = (delete_id) => {
    // Delete event
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(eventsDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(eventsList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Columns to event Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.image,
      width: "10%",
      selector: (row) => (
        <div
          className={`img-out ${
            row.status === Constants.status.ACTIVE
              ? "active-status"
              : "inactive-status"
          }`}
        >
          <img
            src={row?.image}
            alt={row?.name}
            style={{
              maxHeight: "100px",
              maxWidth: "100px",
            }}
          />
        </div>
      ),
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to={`${RoutesUrl.event}/${row?._id}`}>{row?.name}</Link>
      ),
    },
    {
      name: Constants.totalSeats,
      cell: (row) => row?.total_seats || 0,
    },
    {
      name: Constants.price,
      cell: (row) => row?.price || 0,
    },
    {
      name: Constants.startDate,
      cell: (row) => dayjs(row?.start_date).format(Constants.dateFormat),
    },
    {
      name: Constants.startTime,
      cell: (row) => convertToAmPmFormat(row?.start_time),
    },
    {
      name: Constants.endDate,
      cell: (row) => dayjs(row?.end_date).format(Constants.dateFormat),
    },
    {
      name: Constants.endTime,
      cell: (row) => convertToAmPmFormat(row?.end_time),
    },
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        navigate(`${RoutesUrl.event}/${row?._id}`);
                        window.location.reload();
                      }}
                    />
                  </div>
                  {editPermission && (
                    <div className="list-group">
                      <ToggleListField
                        label={Constants.statusKey}
                        onChange={() => {
                          triggerToggle(row);
                        }}
                        checked={row.status === Constants.status.ACTIVE}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                  <div className="list-group">
                    <ResponseListButton
                      onClick={() => {
                        navigate(
                          `${RoutesUrl.event}/${row?._id}?activeTab=event`
                        );
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(eventsList(data));
  };

  return (
    <div className="container-xxl mt-3">
      <PageHeader
        pagetitle={Constants.eventList}
        buttonLink={addPermission}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: types.EVENTS_FORM_MODEL,
            payload: { msg: Constants.addEvent, show: true },
          });
        }}
      />

      <div className="row mb-4 align-items-end position-relative justify-content-end">
        {/* Search filter */}
        <div className="col-md-4">
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByName}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
      </div>
      <TableList
        columns={columns}
        data={eventsData}
        defaultSortFieldId
        highlightOnHover
      />
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.EVENTS_SINGLE, payload: "" });
          dispatch({ type: types.EVENTS_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<EventForm />}
      />
      {eventslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={eventslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </div>
  );
};

export default Event;
