import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const reviewsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.REVIEWS, data);
  return response;
};

export const reviewsEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.REVIEWS + "?id=" + id,
    data
  );
  return response;
};

export const reviewsRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.REVIEWS + "?id=" + id
  );
  return response;
};

export const reviewsStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.REVIEWS_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const reviews = async (data) => {
  let url = `${ApiUrl.REVIEWS}?course_id=${data?.id}&skip=${
    data?.skip || 0
  }&limit=${data?.limit || Constants.limitCount}`;
  const response = await httpServiceInstance.get(url);
  return response;
};
