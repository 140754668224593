import {
  news,
  newsAdd,
  newsEdit,
  newsRemove,
  newsStatusUpdate,
} from "../../services/news.service";
import { types } from "./types";

export const newsInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await newsAdd(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const newsList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await news(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.NEWS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.NEWS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const newsUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await newsEdit(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const newsDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await newsRemove(id);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const newsUpdateStatus = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await newsStatusUpdate(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
