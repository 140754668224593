/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import PageHeader from "../Common/PageHeader";
import { Constants } from "../Common/Constants";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import * as Yup from "yup";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import usePermissions from "../Common/Functions/usePermissions";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ViewImageFile from "../Common/Fields/ViewImageFile";

const section = { type: Constants.configBasicKey };

const Basic = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);

  const [initialValues, setInitialValues] = useState({
    dashboard_image: "",
  });

  const validationSchema = Yup.object().shape({
    dashboard_image: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response.data.data);
    });
  }, []);

  useEffect(() => {
    let listdata = settings;
    if (listdata && listdata.length > 0) {
      let editVal = {};
      for (const value of Object.values(listdata)) {
        editVal[value.name] = value.value;
        setInitialValues(editVal);
      }
    }
  }, [settings]);

  const handleFileChange = (file, setFieldValue, field) => {
    // Add  image for dashboard to AWS
    setFieldValue(field, file?.[0]);
  };

  return (
    <div className="container-xxl">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let dataForm = {};
          dataForm.key_value = values;
          dataForm.type = section.type;
          let submitFunc = addAdminConfigurations(dataForm);
          dispatch(submitFunc).then((response) => {
            if (response.error) {
              dispatch({
                type: types.ERROR_ALERT_VISIBLE,
                payload: response.error,
              });
            } else {
              dispatch({
                type: types.SUCCESS_ALERT_VISIBLE,
                payload: response.data.message,
              });
              dispatch(getAdminConfigurations(section));
            }
          });
        }}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form className="form-control p-3 w-100 list-view card">
            <div className="row">
              <PageHeader pagetitle={Constants.configBasic} />
              <div className="col-md-12 mb-2">
                <label className="form-label">{`${Constants.dashboard} ${Constants.image}`}</label>
                <RequiredIcon />
                <div className="out-cr">
                  <div className="width-outer-up">
                    {values && !values?.dashboard_image && (
                      <FileAwsUpload
                        fileType={[".jpeg", ".png", ".jpg"]}
                        width="1500"
                        height="1000"
                        errors={
                          touched?.dashboard_image
                            ? errors?.dashboard_image
                            : undefined
                        }
                        touched={touched?.dashboard_image}
                        label={`${Constants.upload} ${Constants.dashboard} ${Constants.image}`}
                        handleFile={(files) =>
                          handleFileChange(
                            files,
                            setFieldValue,
                            "dashboard_image"
                          )
                        }
                        maxFiles={1}
                      />
                    )}
                  </div>
                  <div className="after-up">
                    {values && values?.dashboard_image && (
                      <ViewImageFile
                        file={values?.dashboard_image}
                        onClick={() => setFieldValue(`dashboard_image`, "")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {(addPermission || editPermission) && (
              <ButtonCustom type="submit" label={Constants.submit} />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Basic;
