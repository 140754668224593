/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import TextField from "../../../Common/Fields/TextField";
import ReactSelect from "../../../Common/Fields/ReactSelect";
import MultiSelect from "../../../Common/Fields/MultiSelect";
import { Constants } from "../../../Common/Constants";
import PageHeader from "../../../Common/PageHeader";
import ViewImageFile from "../../../Common/Fields/ViewImageFile";
import { useDispatch, useSelector } from "react-redux";
import { bannerUserForm } from "../../../../Redux/Actions";
import { useParams } from "react-router-dom";

const UserFormResponse = () => {
  const dispatch = useDispatch();
  const { id, userId } = useParams();
  const [formData, setFormData] = useState([]);
  const { banneruserform } = useSelector((state) => state.Banners);

  useEffect(() => {
    // Banner form user response API
    dispatch(bannerUserForm(id, userId));
  }, []);

  useEffect(() => {
    // Set banner form values
    if (banneruserform?.response) setFormData(banneruserform?.response);
  }, [banneruserform]);

  return (
    <div className="container-xxl">
      <PageHeader pagetitle={banneruserform?.name} />
      <div className="course-form card pt-4">
        <Formik enableReinitialize={true} onSubmit={() => {}}>
          {() => (
            <Form className="row">
              {formData?.map((field, index) => {
                const answerValue = field?.answer;
                return (
                  <div
                    className="col-md-6 mb-2"
                    key={`${field?.label}${index}`}
                  >
                    {/* For Text Field */}
                    {field.type === Constants.textKey && (
                      <TextField
                        type="text"
                        notrequired={[]}
                        label={field?.label}
                        value={answerValue}
                      />
                    )}
                    {/* For radio button (Single select) */}
                    {field.type === Constants.radioKey && (
                      <ReactSelect
                        label={field?.label}
                        required={false}
                        options={field?.options?.map((elt) => ({
                          value: elt,
                          label: elt,
                        }))}
                        onChange={() => {}}
                        value={{
                          label: answerValue,
                          value: answerValue,
                        }}
                      />
                    )}
                    {/* For checkbox (Multiple select) */}
                    {field.type === Constants.checkboxKey && (
                      <>
                        <label className="form-label">{field?.label}</label>
                        <MultiSelect
                          key="multi_select"
                          options={field?.options?.map((elt) => ({
                            value: elt,
                            label: elt,
                          }))}
                          value={field?.answer?.map((elt) => ({
                            value: elt,
                            label: elt,
                          }))}
                          onChange={() => {}}
                          isSelectAll={true}
                          menuPlacement={"bottom"}
                        />
                      </>
                    )}
                    {/* For image */}
                    {field.type === Constants.imageKey && (
                      <div>
                        <label className="form-label">{field?.label}</label>
                        <div className="out-cr">
                          <ViewImageFile file={answerValue} remove={true} />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UserFormResponse;
