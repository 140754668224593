import { types } from "../../Actions/types";

const initialState = {
  coursesFaqslist: [],
  error: null,
  coursesFaqsSingle: "",
  popupmodel: "",
};

export default function CoursesFaqs(state = initialState, action) {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.COURSES_FAQS_LIST:
      return {
        ...state,
        coursesFaqslist: payload,
        error: error,
      };
    case types.COURSES_FAQS_SINGLE:
      return {
        ...state,
        coursesFaqsSingle: payload,
        error: error,
      };
    case types.COURSES_FAQS_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}
