/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Constants } from "../../Common/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import TabsBootstrap from "../../Common/UiModel/TabsBootstrap";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Yearly from "./Yearly";
import Quarterly from "./Quarterly";

const Overview = () => {
  const tabsList = [
    {
      eventKey: Constants.reportWeek,
      title: Constants.sevenDays,
      component: <Weekly />,
    },
    {
      eventKey: Constants.reportMonth,
      title: Constants.thirtyDays,
      component: <Monthly />,
    },
    {
      eventKey: Constants.reportThreeMonths,
      title: Constants.ninetyDays,
      component: <Quarterly />,
    },
    {
      eventKey: Constants.reportYear,
      title: Constants.oneYear,
      component: <Yearly />,
    },
  ];
  const [activetab, setActiveTab] = useState(tabsList?.[0]?.eventKey);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Set active tab as per URL
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    // Set URL in correspondance with active tab
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div className="container-xxl common-space">
      <div className="row catalog_box al_catalog_box">
        <div className="col-md-12 mb-4 order-list-view">
          {tabsList.length > 0 && (
            <TabsBootstrap
              defaultActiveKey={activetab}
              onSelect={(index) => {
                setActiveTab(index);
              }}
              activeKey={activetab}
              handleTabSelect={handleTabSelect}
              options={tabsList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;
