import { Constants } from "../../Common/Constants";
import { ApiRoutes } from "./ApiRoutes";

// Permissions object list for each tab type (view, edit, add, delete)
export const permissionList = [
  {
    key: Constants.dashboardKey,
    value: Constants.dashboard,
    view: false,
    add: Constants.disabled,
    edit: Constants.disabled,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.dashboardView,
  },
  {
    key: Constants.categorKey,
    value: Constants.category,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.categoryView,
    api_edit_route: ApiRoutes.categoryEdit,
    api_add_route: ApiRoutes.categoryAdd,
    api_delete_route: ApiRoutes.categoryDelete,
  },
  {
    key: Constants.coursesKey,
    value: Constants.course,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.courseView,
    api_edit_route: ApiRoutes.courseEdit,
    api_add_route: ApiRoutes.courseAdd,
    api_delete_route: ApiRoutes.courseDelete,
  },
  {
    key: Constants.transactionKey,
    value: Constants.transactions,
    view: false,
    add: Constants.disabled,
    edit: false,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.transactionView,
    api_edit_route: ApiRoutes.transactionEdit,
    api_add_route: [],
    api_delete_route: [],
  },
  {
    key: Constants.packageKey,
    value: Constants.package,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.packageView,
    api_edit_route: ApiRoutes.packageEdit,
    api_add_route: ApiRoutes.packageAdd,
    api_delete_route: ApiRoutes.packageDelete,
  },
  {
    key: Constants.freeVideoKey,
    value: Constants.freeVideo,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.freeVideoView,
    api_edit_route: ApiRoutes.freeVideoEdit,
    api_add_route: ApiRoutes.freeVideoAdd,
    api_delete_route: ApiRoutes.freeVideoDelete,
  },
  {
    key: Constants.promoCodeKey,
    value: Constants.promoCode,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.promoView,
    api_edit_route: ApiRoutes.promoEdit,
    api_add_route: ApiRoutes.promoAdd,
    api_delete_route: ApiRoutes.promoDelete,
  },
  {
    key: Constants.bannerKey,
    value: Constants.banner,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.bannerView,
    api_edit_route: ApiRoutes.bannerEdit,
    api_add_route: ApiRoutes.bannerAdd,
    api_delete_route: ApiRoutes.bannerDelete,
  },
  {
    key: Constants.eventKey,
    value: Constants.event,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.eventsView,
    api_edit_route: ApiRoutes.eventsView,
    api_add_route: ApiRoutes.eventsAdd,
    api_delete_route: ApiRoutes.eventsDelete,
  },
  {
    key: Constants.newsKey,
    value: Constants.news,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.newsView,
    api_edit_route: ApiRoutes.newsEdit,
    api_add_route: ApiRoutes.newsAdd,
    api_delete_route: ApiRoutes.newsDelete,
  },
  {
    key: Constants.customerKey,
    value: Constants.customer,
    view: false,
    add: Constants.disabled,
    edit: false,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.usersView,
    api_edit_route: ApiRoutes.usersEdit,
    api_add_route: [],
    api_delete_route: [],
  },
  {
    key: Constants.kycKey,
    value: Constants.kyc,
    view: false,
    add: Constants.disabled,
    edit: false,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.kycUsersView,
    api_edit_route: ApiRoutes.kycUsersEdit,
    api_add_route: [],
    api_delete_route: [],
  },
  {
    key: Constants.affiliateUsersKey,
    value: Constants.affiliateUsers,
    view: false,
    add: Constants.disabled,
    edit: Constants.disabled,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.affiliateUsersView,
    api_edit_route: [],
    api_add_route: [],
    api_delete_route: [],
  },
  {
    key: Constants.supportKey,
    value: Constants.support,
    view: false,
    add: Constants.disabled,
    edit: false,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.supportView,
    api_edit_route: ApiRoutes.supportEdit,
    api_add_route: [],
    api_delete_route: [],
  },
  {
    key: Constants.withdrawKey,
    value: Constants.withdraw,
    view: false,
    add: Constants.disabled,
    edit: false,
    delete: Constants.disabled,
    api_view_route: [],
    api_edit_route: [],
    api_add_route: [],
    api_delete_route: [],
  },
  {
    key: Constants.reportKey,
    value: Constants.report,
    view: false,
    add: Constants.disabled,
    edit: Constants.disabled,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.reportView,
    api_edit_route: [],
    api_add_route: [],
    api_delete_route: [],
  },
  {
    key: Constants.roleMgtKey,
    value: Constants.roleMgt,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.roleMgtView,
    api_edit_route: ApiRoutes.roleMgtEdit,
    api_add_route: ApiRoutes.roleMgtAdd,
    api_delete_route: ApiRoutes.roleMgtDelete,
  },
  {
    key: Constants.notificationKey,
    value: Constants.notification,
    view: false,
    add: false,
    edit: Constants.disabled,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.notifiView,
    api_edit_route: [],
    api_add_route: ApiRoutes.notifiAdd,
    api_delete_route: [],
  },
  {
    key: Constants.configurationKey,
    value: Constants.cms,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.configAdd,
    api_edit_route: ApiRoutes.configEdit,
    api_add_route: ApiRoutes.configAdd,
    api_delete_route: ApiRoutes.configDelete,
  },
];
