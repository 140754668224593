import Swal from "sweetalert2";
import { Constants } from "../Constants";

export const deleteConfirmBox = (data) => {
  return Swal.fire({
    title: Constants.areYouSure,
    text: data?.text || Constants.noRevert,
    icon: "warning",
    showCancelButton: true,
  });
};
