import * as Yup from "yup";
import { Constants } from "../Common/Constants";

export const CourseFormValidation = Yup.object().shape({
  goals: Yup.array().min(1, Constants.required),
  name: Yup.string().required(Constants.required),
  teacher_name: Yup.string()
    .required(Constants.required)
    .matches(Constants.validNameRegex, Constants.invalid),
  description: Yup.string().required(Constants.required),
  teacher_detail: Yup.string().required(Constants.required),
  image: Yup.string().required(Constants.required),
  banner_image: Yup.string().test(
    "ifVideoPresent",
    Constants.requiredBannerOrVideo,
    function (value) {
      const { video } = this.parent;
      return video || value;
    }
  ),
  video: Yup.string().test(
    "ifBannerImagePresent",
    Constants.requiredBannerOrVideo,
    function (value) {
      const { banner_image } = this.parent;
      return banner_image || value;
    }
  ),
  invoice_description: Yup.string().required(Constants.required),
  teacher_photo: Yup.string().required(Constants.required),
  category_id: Yup.string().required(Constants.required),
  strike_out_price: Yup.string()
    .required(Constants.required)
    .matches(Constants.invalidPriceRegex, Constants.invalidPrice),
  original_price: Yup.string()
    .required(Constants.required)
    .matches(Constants.invalidPriceRegex, Constants.invalidPrice)
    .test(
      "lessThanStrikeOutPrice",
      Constants.originalPriceLessThanStrikeOutPrice,
      function (value) {
        const { strike_out_price } = this.parent;
        return parseFloat(value) < parseFloat(strike_out_price);
      }
    ),
  quiz_sets_attempt: Yup.string().when(["quiz"], (quiz, schema) => {
    return quiz?.[0] === true
      ? schema
          .required(Constants.required)
          .matches(Constants.invalidQuizSetRegex, Constants.invalidValue)
      : schema;
  }),
  quiz_passing_percentage: Yup.string().when(["quiz"], (quiz, schema) => {
    return quiz?.[0] === true
      ? schema
          .required(Constants.required)
          .matches(Constants.invalidPercentRegex, Constants.invalidValue)
      : schema;
  }),
  tax_percentage: Yup.string()
    .required(Constants.required)
    .matches(Constants.invalidPercentRegex, Constants.invalidValue),
});

export const initialCourseFormValues = {
  name: "",
  teacher_name: "",
  description: "",
  teacher_detail: "",
  category_id: "",
  subcategory_id: null,
  goals: [],
  courses_link_reference: [],
  course_link: `${process.env.REACT_APP_WEB_URL}${Constants.linkCourse}`,
  set_rule: Constants.lessonSetRule[1].value,
  day_id: Constants.weekDays[0].value,
  strike_out_price: "",
  original_price: "",
  tax_percentage: "",
  image: "",
  banner_image: "",
  teacher_photo: "",
  tax_included: false,
  sent_user_certificate: false,
  sequencial_learning: false,
  expiry_duration: false,
  notify_learners: false,
  quiz: false,
  free_first_lesson: false,
  module_lessons: false,
  invoice_description: "",
  expiry_duration_month: "",
  quiz_sets_attempt: 3,
  quiz_passing_percentage: "",
  video: "",
  duration: "",
};
