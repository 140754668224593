/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import { DashboardModal } from "@uppy/react";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import { Constants } from "../Constants";

function FileAwsUpload(props) {
  const [isOpen, setOpen] = useState(false);
  const renameFile = (file) => {
    // Replace 'X' with '*' in the file name
    const newName = file.name.replace(/_/g, "*");

    // Update the file's meta property with the new name
    uppy.setFileMeta(file.id, { name: newName });
  };
  const uppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: props?.maxFiles,
      allowedFileTypes: props?.fileType,
    },
  });

  useEffect(() => {
    if (isOpen) {
      uppy
        .use(AwsS3Multipart, {
          companionUrl: process.env.REACT_APP_BASE_SOCKET_URL,
          // Add the `onBeforeUpload` option to handle file renaming before upload
          onBeforeUpload: (files) => {
            files.forEach(renameFile);
          },
        })
        .on("complete", (result) => {
          props?.handleFile(result?.successful?.map((file) => file.uploadURL));
          if (props?.handleMediaType)
            props?.handleMediaType(result?.successful?.[0]?.extension);
          setOpen(false);
        });

      return () => {
        uppy.close();
      };
    }
  }, [isOpen]);

  return (
    <div className="body d-flex py-3 design-popup">
      <i className="fa fa-upload upload-ic"></i>
      <DashboardModal
        uppy={uppy}
        open={isOpen}
        onRequestClose={() => {
          setOpen(false);
        }}
      />
      <div className="flex-p" onClick={() => setOpen(true)}>
        <span className="img-upload-label">{`${props.label}`}</span>
        <span className="font-12">{` (${Constants.width}: ${props?.width}px, ${Constants.height}: ${props?.height}px)`}</span>
        <span className="font-12">{`${
          props?.fileType
            ? ` (${Constants.accepts} ${(props?.fileType).join(", ")}`
            : ""
        })`}</span>
      </div>
      {(props.errors || props.touched) && (
        <p className="error-msg mt-4">{props.errors}</p>
      )}
    </div>
  );
}
export default FileAwsUpload;
