import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const categoryAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.CATEGORY, data);
  return response;
};

export const categoryEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.CATEGORY + "?id=" + id,
    data
  );
  return response;
};

export const categoryRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.CATEGORY + "?id=" + id
  );
  return response;
};

export const categoryOrderUpdate = async (data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.CATEGORY_ORDER_UPDATE,
    data
  );
  return response;
};

export const categoryStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.CATEGORY_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const category = async (data) => {
  let url = `${ApiUrl.CATEGORY}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};
