import { types } from "../Actions/types";

const initialState = {
  withdrawRequestsList: [],
  withdrawSingle: "",
};

export default function Withdraw(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.WITHDRAW_LIST:
      return {
        ...state,
        withdrawRequestsList: payload,
      };

    case types.WITHDRAW_SINGLE:
      return {
        ...state,
        withdrawSingle: payload,
      };

    default:
      return state;
  }
}
