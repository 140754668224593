import { ApiUrl } from "../../components/Common/ApiUrl";
import httpServiceInstance from "../../helper/HttpService";

export const goalsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.GOALS, data);
  return response;
};

export const goalsEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.GOALS + "?id=" + id,
    data
  );
  return response;
};

export const goalsRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.GOALS + `?id=${id}`);
  return response;
};

export const goalsOrderUpdate = async (data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.GOALS_ORDER_UPDATE,
    data
  );
  return response;
};

export const goalStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.GOALS_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const goals = async (data) => {
  const response = await httpServiceInstance.get(ApiUrl.GOALS);
  return response;
};
