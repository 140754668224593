import { types } from "../../Actions/types";

const initialState = {
  packageCourseslist: [],
  packageCoursesSingle: "",
  popupmodel: "",
};

export default function PackageCourses(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.PACKAGE_COURSES_LIST:
      return {
        ...state,
        packageCourseslist: payload,
      };
    case types.PACKAGE_COURSES_SINGLE:
      return {
        ...state,
        packageCoursesSingle: payload,
      };
    case types.PACKAGE_COURSES_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}
