/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import RoleList from "./RoleList";
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import { permissionList } from "./PermissionsList";
import { useDispatch } from "react-redux";
import { addRolePermission, fetchRolePermission } from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import { useSelector } from "react-redux";
import { ApiRoutes } from "./ApiRoutes";
import usePermissions from "../../Common/Functions/usePermissions";

const ManageRoles = () => {
  const [sequence, setSequence] = useState(permissionList);
  const [selectedRole, setSelectedRole] = useState(false);
  const dispatch = useDispatch();
  const { permissionlist } = useSelector((state) => state.Roles);
  const addPermission = usePermissions(Constants.permission.ROLE_MGT_ADD);
  const editPermission = usePermissions(Constants.permission.ROLE_MGT_EDIT);

  // get role permission
  useEffect(() => {
    if (selectedRole?._id) {
      dispatch(fetchRolePermission(selectedRole?._id));
    }
  }, [selectedRole]);

  useEffect(() => {
    if (permissionlist?.data?.length > 0) {
      let obj = {};
      permissionlist?.data.forEach(
        (field) => (obj[field?.key] = field.is_enabled)
      );
      // Set permission object
      setSequence([
        {
          key: Constants.dashboardKey,
          value: Constants.dashboard,
          view: obj?.dashboard_view,
          add: Constants.disabled,
          edit: Constants.disabled,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.dashboardView,
        },
        {
          key: Constants.categorKey,
          value: Constants.category,
          view: obj.category_view,
          add: obj.category_add,
          edit: obj.category_edit,
          delete: obj.category_delete,
          api_view_route: ApiRoutes.categoryView,
          api_edit_route: ApiRoutes.categoryEdit,
          api_add_route: ApiRoutes.categoryAdd,
          api_delete_route: ApiRoutes.categoryDelete,
        },
        {
          key: Constants.coursesKey,
          value: Constants.course,
          view: obj.courses_view,
          add: obj.courses_add,
          edit: obj.courses_edit,
          delete: obj.courses_delete,
          api_view_route: ApiRoutes.courseView,
          api_edit_route: ApiRoutes.courseEdit,
          api_add_route: ApiRoutes.courseAdd,
          api_delete_route: ApiRoutes.courseDelete,
        },
        {
          key: Constants.transactionKey,
          value: Constants.transactions,
          view: obj.transaction_view,
          add: Constants.disabled,
          edit: obj.transaction_edit,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.transactionView,
          api_edit_route: ApiRoutes.transactionEdit,
          api_add_route: [],
          api_delete_route: [],
        },
        {
          key: Constants.packageKey,
          value: Constants.package,
          view: obj.package_view,
          add: obj.package_add,
          edit: obj.package_edit,
          delete: obj.package_delete,
          api_view_route: ApiRoutes.packageView,
          api_edit_route: ApiRoutes.packageEdit,
          api_add_route: ApiRoutes.packageAdd,
          api_delete_route: ApiRoutes.packageDelete,
        },
        {
          key: Constants.freeVideoKey,
          value: Constants.freeVideo,
          view: obj.freeVideo_view,
          add: obj.freeVideo_add,
          edit: obj.freeVideo_edit,
          delete: obj.freeVideo_delete,
          api_view_route: ApiRoutes.freeVideoView,
          api_edit_route: ApiRoutes.freeVideoEdit,
          api_add_route: ApiRoutes.freeVideoAdd,
          api_delete_route: ApiRoutes.freeVideoDelete,
        },
        {
          key: Constants.promoCodeKey,
          value: Constants.promoCode,
          view: obj.promocode_view,
          add: obj.promocode_add,
          edit: obj.promocode_edit,
          delete: obj.promocode_delete,
          api_view_route: ApiRoutes.promoView,
          api_edit_route: ApiRoutes.promoEdit,
          api_add_route: ApiRoutes.promoAdd,
          api_delete_route: ApiRoutes.promoDelete,
        },
        {
          key: Constants.bannerKey,
          value: Constants.banner,
          view: obj.banner_view,
          add: obj.banner_add,
          edit: obj.banner_edit,
          delete: obj.banner_delete,
          api_view_route: ApiRoutes.bannerView,
          api_edit_route: ApiRoutes.bannerEdit,
          api_add_route: ApiRoutes.bannerAdd,
          api_delete_route: ApiRoutes.bannerDelete,
        },
        {
          key: Constants.eventKey,
          value: Constants.event,
          view: obj.event_view,
          add: obj.event_add,
          edit: obj.event_edit,
          delete: obj.event_delete,
          api_view_route: ApiRoutes.eventsView,
          api_edit_route: ApiRoutes.eventsView,
          api_add_route: ApiRoutes.eventsAdd,
          api_delete_route: ApiRoutes.eventsDelete,
        },
        {
          key: Constants.newsKey,
          value: Constants.news,
          view: obj.news_view,
          add: obj.news_add,
          edit: obj.news_edit,
          delete: obj.news_delete,
          api_view_route: ApiRoutes.newsView,
          api_edit_route: ApiRoutes.newsEdit,
          api_add_route: ApiRoutes.newsAdd,
          api_delete_route: ApiRoutes.newsDelete,
        },
        {
          key: Constants.customerKey,
          value: Constants.customer,
          view: obj.customers_view,
          add: Constants.disabled,
          edit: obj.customers_edit,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.usersView,
          api_edit_route: ApiRoutes.usersEdit,
          api_add_route: [],
          api_delete_route: [],
        },
        {
          key: Constants.kycKey,
          value: Constants.kyc,
          view: obj.kyc_view,
          add: Constants.disabled,
          edit: obj.kyc_edit,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.kycUsersView,
          api_edit_route: ApiRoutes.kycUsersEdit,
          api_add_route: [],
          api_delete_route: [],
        },
        {
          key: Constants.affiliateUsersKey,
          value: Constants.affiliateUsers,
          view: obj.affiliate_view,
          add: Constants.disabled,
          edit: Constants.disabled,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.affiliateUsersView,
          api_edit_route: [],
          api_add_route: [],
          api_delete_route: [],
        },
        {
          key: Constants.supportKey,
          value: Constants.support,
          view: obj.support_view,
          add: Constants.disabled,
          edit: obj.support_edit,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.supportView,
          api_edit_route: ApiRoutes.supportEdit,
          api_add_route: [],
          api_delete_route: [],
        },
        {
          key: Constants.withdrawKey,
          value: Constants.withdraw,
          view: obj.withdraw_view,
          add: Constants.disabled,
          edit: obj.withdraw_edit,
          delete: Constants.disabled,
          api_view_route: [],
          api_edit_route: [],
          api_add_route: [],
          api_delete_route: [],
        },
        {
          key: Constants.reportKey,
          value: Constants.report,
          view: obj.report_view,
          add: Constants.disabled,
          edit: Constants.disabled,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.reportView,
          api_edit_route: [],
          api_add_route: [],
          api_delete_route: [],
        },
        {
          key: Constants.roleMgtKey,
          value: Constants.roleMgt,
          view: obj.role_mgt_view,
          add: obj.role_mgt_add,
          edit: obj.role_mgt_edit,
          delete: obj.role_mgt_delete,
          api_view_route: ApiRoutes.roleMgtView,
          api_edit_route: ApiRoutes.roleMgtEdit,
          api_add_route: ApiRoutes.roleMgtAdd,
          api_delete_route: ApiRoutes.roleMgtDelete,
        },
        {
          key: Constants.notificationKey,
          value: Constants.notification,
          view: obj.notification_view,
          add: obj.notification_add,
          edit: Constants.disabled,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.notifiView,
          api_edit_route: [],
          api_add_route: ApiRoutes.notifiAdd,
          api_delete_route: [],
        },
        {
          key: Constants.configurationKey,
          value: Constants.cms,
          view: obj.configuration_view,
          add: obj.configuration_add,
          edit: obj.configuration_edit,
          delete: obj.configuration_delete,
          api_view_route: ApiRoutes.configAdd,
          api_edit_route: ApiRoutes.configEdit,
          api_add_route: ApiRoutes.configAdd,
          api_delete_route: ApiRoutes.configDelete,
        },
      ]);
    } else {
      setSequence(permissionList);
    }
  }, [permissionlist]);

  // Checkbox change
  const handleModuleTypeChange = (e, index, type) => {
    const copiedObject = JSON.parse(JSON.stringify(sequence));
    copiedObject[index][type] = e.target.checked;
    setSequence(copiedObject);
  };

  const handleGrantAccess = () => {
    // convert to API required data format
    const resultArray = [];
    sequence.forEach((elt) => {
      resultArray.push({
        key: `${elt.key}_${Constants.viewKey}`,
        enabled: elt.view === true ? true : false,
        api_route: elt.api_view_route || ["a"],
        api_method: "GET",
      });
      resultArray.push({
        key: `${elt.key}_${Constants.editKey}`,
        enabled: elt.edit === true ? true : false,
        api_route: elt.api_edit_route || ["a"],
        api_method: "PUT",
      });
      resultArray.push({
        key: `${elt.key}_${Constants.addKey}`,
        enabled: elt.add === true ? true : false,
        api_route: elt.api_add_route || ["a"],
        api_method: "POST",
      });
      resultArray.push({
        enabled: elt.delete === true ? true : false,
        key: `${elt.key}_${Constants.deleteKey}`,
        api_route: elt.api_delete_route || ["a"],
        api_method: "DELETE",
      });
    });
    let data = {
      role: selectedRole?._id,
      permissions: resultArray,
    };
    // Add permission API
    dispatch(addRolePermission(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        dispatch(fetchRolePermission(selectedRole?._id));
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response.data.message,
        });
      }
    });
  };

  return (
    <div className="container-xxl">
      <div className="row">
        <div className="col-lg-3">
          <RoleList onChange={(role) => setSelectedRole(role)} />
        </div>
        <div className="col-lg-9">
          <div className="d-flex flex-bw my-2">
            <PageHeader pagetitle={Constants.roleMgt} />
            {
              <div className="btn-hover">
                {((addPermission && !permissionlist?.data?.length > 0) ||
                  (editPermission && permissionlist?.data?.length > 0)) &&
                  selectedRole && (
                    <button
                      type="button"
                      className=" btn btn-primary"
                      onClick={() => {
                        handleGrantAccess();
                      }}
                    >
                      {Constants.grantAccess}
                    </button>
                  )}
              </div>
            }
          </div>
          <form>
            <div className="table-responsive table-h">
              <table className="table custom-table table-striped module-role ">
                <thead></thead>
                <tbody>
                  {sequence?.map((modules, index) => (
                    <tr>
                      <td>
                        <i
                          className="fa fa-folder-o me-1"
                          aria-hidden="true"
                        ></i>
                        {modules?.value}
                      </td>
                      {[
                        Constants.viewKey,
                        Constants.editKey,
                        Constants.addKey,
                        Constants.deleteKey,
                      ].map((elt) => (
                        <td>
                          {modules[elt] !== Constants.disabled && (
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={modules[elt] === true ? true : false}
                                  disabled={modules[elt] === Constants.disabled}
                                  onChange={(e) =>
                                    handleModuleTypeChange(e, index, elt)
                                  }
                                />
                                {elt.charAt(0).toUpperCase() + elt.slice(1)}
                              </label>
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageRoles;
