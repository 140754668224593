import { Field } from "formik";
import React from "react";

const RadioButton = ({ label, options, name, checked, onChange }) => {
  return (
    <>
      <label className="form-label">{label}</label>
      <div className="row">
        {options.map((option) => (
          <div className="col-md-4 col-4 mb-2" key={option.key}>
            <label className="form-label">
              <Field
                className="radio-button"
                type="radio"
                name={name}
                checked={checked?.key === option?.key}
                onChange={() => onChange(option)}
                value={option.value}
              />
              {option.value}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default RadioButton;
