/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogs } from "../../../Redux/Actions";
import { Constants } from "../../Common/Constants";
import dayjs from "dayjs";
import PageHeader from "../../Common/PageHeader";
import TableList from "../../Common/UiModel/TableList";
import ReactPagination from "../../Common/Fields/ReactPagination";

const Logs = () => {
  const dispatch = useDispatch();
  const { reportlogslist } = useSelector((state) => state.Reports);
  const [logsData, setLogsData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    dispatch(fetchLogs());
  }, []);

  useEffect(() => {
    setLogsData(reportlogslist.data);
  }, [reportlogslist]);

  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => row?.user_id?.name || Constants.admin,
    },
    {
      name: `${Constants.report} ${Constants.name}`,
      cell: (row) => row?.file_name,
    },
    {
      name: Constants.role,
      cell: (row) => row?.user_id?.role?.name || Constants.admin,
    },
    {
      name: Constants.date,
      cell: (row) => dayjs(row?.updatedAt).format(Constants.dateFormat),
    },
    {
      name: Constants.time,
      cell: (row) => dayjs(row?.updatedAt).format(Constants.timeFormat),
    },
  ];

  const handleOffset = (newOff) => {
    let data = {
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(fetchLogs(data));
  };

  return (
    <div className="container-xxl mt-3">
      <PageHeader pagetitle={Constants.logsList} />
      <TableList
        columns={columns}
        data={logsData}
        defaultSortFieldId
        highlightOnHover
      />
      {reportlogslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={reportlogslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </div>
  );
};

export default Logs;
