/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../../Common/Constants";
import { Formik, Form } from "formik";
import {
  userCommentsInsert,
  userCommentsList,
  userCommentsUpdate,
} from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import { useParams } from "react-router-dom";
import TextField from "../../Common/Fields/TextField";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import TextArea from "../../Common/Fields/TextArea";
import usePermissions from "../../Common/Functions/usePermissions";

const CommentForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { usercommentsSingle } = useSelector((state) => state.Users);
  const editPermission = usePermissions(Constants.permission.USER_EDIT);
  // Initial values for user comment form
  const [initialValues, setInitialValues] = useState({
    user_id: id,
    problem: "",
    solution: "",
  });

  // Validations for user comment form
  const validationSchema = Yup.object().shape({
    problem: Yup.string().required(Constants.required),
    solution: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial valuea to pre fill the user comment form
    if (!!usercommentsSingle) {
      let editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (usercommentsSingle[key]) {
          editValue[key] = usercommentsSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [usercommentsSingle]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const userId = JSON.parse(localStorage.getItem(Constants.user))?._id;
        values.comment_updated_by = userId;
        // Add or update user comment
        let submitFunc = userCommentsInsert(values);
        if (usercommentsSingle?._id) {
          let id = usercommentsSingle?._id;
          submitFunc = userCommentsUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(userCommentsList(id));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.USER_COMMENTS_SINGLE, payload: "" });
            dispatch({ type: types.USER_COMMENTS_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="problem"
              className="form-control"
              errors={touched?.problem ? errors?.problem : undefined}
              touched={touched?.problem}
              label={Constants.problem}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="solution"
              className="form-control"
              errors={touched?.solution ? errors?.solution : undefined}
              touched={touched?.solution}
              label={Constants.solution}
            />
          </div>
          {editPermission && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CommentForm;
