import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const notificationAdd = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.NOTIFICATION_SEND,
    data
  );
  return response;
};

export const notification = async (data) => {
  let url = `${ApiUrl.NOTIFICATION_LIST}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const EmailAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.EMAIL_SEND, data);
  return response;
};
