/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { kycRequestResponse, usersDetail } from "../../Redux/Actions";
import CardBlock from "../Users/CardBlock";
import PageHeader from "../Common/PageHeader";
import { Constants } from "../Common/Constants";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import { types } from "../../Redux/Actions/types";
import KycDetailCard from "./KycDetailCard";
import ModalPopup from "../Common/UiModel/ModalPopup";
import KycRejectionReason from "./KycRejectionReason";
import { RoutesUrl } from "../Common/RoutesUrl";
import usePermissions from "../Common/Functions/usePermissions";

const Detail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [rejectionReason, setRejectionReason] = useState(false);
  const { usersSingle } = useSelector((state) => state.Users);
  const editPermission = usePermissions(Constants.permission.KYC_EDIT);

  useEffect(() => {
    // User detail API
    dispatch(usersDetail(id));
  }, []);

  const handleKycAction = (action) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        if (action === Constants.reject) {
          setRejectionReason(true);
          return;
        }
        const userId = JSON.parse(localStorage.getItem(Constants.user))?._id;
        const data = {
          kyc_status_updated_by: userId,
          kyc_status: Constants.kycApproveKey,
        };
        dispatch(kycRequestResponse(id, data)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(usersDetail(id));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
          }
        });
      }
    });
  };

  const checkStatus = (status) => {
    const type = Constants.kycRequests.find((elt) => elt.value === status);
    return type?.label || Constants.na;
  };

  return (
    <div className="container-xxl">
      <div className="d-flex align-items-center justify-content-between">
        <PageHeader
          list={RoutesUrl.kyc}
          pagetitle={`${Constants.kycDetail} (${checkStatus(
            usersSingle?.user?.kyc_status
          )})`}
        />
        {editPermission && (
          <div className="d-flex mb-4">
            <button
              type="button"
              className="btn reject btn-c me-2"
              disabled={
                usersSingle?.user?.kyc_status === Constants.kycRejectKey
              }
              onClick={() => handleKycAction(Constants.reject)}
            >
              {Constants.reject}
            </button>
            <button
              type="button"
              className="btn approved btn-c"
              disabled={
                usersSingle?.user?.kyc_status === Constants.kycApproveKey
              }
              onClick={() => handleKycAction(Constants.approve)}
            >
              {Constants.approve}
            </button>
          </div>
        )}
      </div>
      <CardBlock user={usersSingle?.user} />
      <KycDetailCard user={usersSingle?.user} />
      <ModalPopup
        show={rejectionReason}
        onHide={() => {
          setRejectionReason(false);
        }}
        dialogClassName={"modal-md"}
        title={Constants.rejectionReason}
        component={
          <KycRejectionReason onClose={() => setRejectionReason(false)} />
        }
      />
    </div>
  );
};

export default Detail;
