import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const logsList = async (data) => {
  let url = `${ApiUrl.REPORT_GET_EXPORTS}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const abandonedUsersList = async (data) => {
  let url = `${ApiUrl.ABANDONED_USERS}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const reportList = async (days) => {
  let url = `${ApiUrl.REPORT}?days=${days}`;
  const response = await httpServiceInstance.get(url);
  return response;
};
