/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Constants } from "../../../Common/Constants";
import TabsBootstrap from "../../../Common/UiModel/TabsBootstrap";
import { setsList } from "../../../../Redux/Actions";
import { useParams } from "react-router-dom";
import SetList from "./SetList";

const QuizDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [activetab, setActiveTab] = useState("1");
  const location = useLocation();
  const navigate = useNavigate();
  const { setslist } = useSelector((state) => state.Quiz);
  const [tabsList, setTabsList] = useState([]);

  useEffect(() => {
    dispatch(setsList(id));
  }, []);

  useEffect(() => {
    if (setslist?.data?.length > 0) {
      let tabs = [];
      setslist?.data.forEach((elt, index) => {
        let idx = setslist?.data?.findIndex((elt) => elt.set_id === index + 1);
        tabs.push({
          eventKey: (index + 1).toString(),
          title: elt?.name,
          component: <SetList data={setslist?.data?.[idx]} />,
        });
      });
      setTabsList(tabs);
    }
  }, [setslist]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div className="common-space mt-4">
      <div className="row catalog_box al_catalog_box">
        <div className="col-md-12 mb-4 order-list-view">
          {tabsList.length > 0 && (
            <TabsBootstrap
              defaultActiveKey={activetab}
              onSelect={(index) => {
                setActiveTab(index);
              }}
              activeKey={activetab}
              handleTabSelect={handleTabSelect}
              options={tabsList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizDetail;
