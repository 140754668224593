/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { usersList } from "../../Redux/Actions";
import { Constants } from "../Common/Constants";
import TableList from "../Common/UiModel/TableList";
import PageHeader from "../Common/PageHeader";
import ReactPagination from "../Common/Fields/ReactPagination";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import { types } from "../../Redux/Actions/types";
import saveExportedFile from "../Common/Functions/saveExportedFile";
import usePermissions from "../Common/Functions/usePermissions";

const DeletedUsers = () => {
  const dispatch = useDispatch();
  const { userslist } = useSelector((state) => state.Users);
  const [usersData, setUsersData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const userViewPermission = usePermissions(Constants.permission.USER_VIEW);

  useEffect(() => {
    dispatch(usersList({ account_status: Constants.deleted }));
  }, []);

  useEffect(() => {
    setUsersData(userslist.data);
  }, [userslist]);

  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link
          to=""
          onClick={() => {
            if (userViewPermission)
              window.open(`${RoutesUrl.users}/${row?._id}`, "_blank");
          }}
        >
          {row?.name || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.email,
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.email}
        >
          {row?.email?.slice(0, 10) + "..."}
        </span>
      ),
    },
    {
      name: Constants.number,
      cell: (row) => `${row?.dial_code} ${row?.phone_number}`,
    },
    {
      name: Constants.date,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.time,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    {
      name: Constants.state,
      cell: (row) => row?.address?.state || Constants.na,
    },
  ];

  const handleOffset = (newOff) => {
    let data = {
      skip: newOff,
      limit: Constants.limitCount,
      account_status: Constants.deleted,
    };
    dispatch(usersList(data));
  };

  const exportDeletedUserList = () => {
    let data = {
      skipPagination: true,
      account_status: Constants.deleted,
    };
    dispatch(usersList(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        handleDownloadClick(response?.data?.data);
      }
    });
  };

  // Function to handle the download click for exported CSV data
  const handleDownloadClick = (deletedUsersList) => {
    const data = deletedUsersList?.map((element) => {
      return {
        [Constants.name]: element?.name || Constants.na,
        [Constants.email]: element?.email || Constants.na,
        [Constants.dialCode]: element?.dial_code || Constants.na,
        [Constants.number]: element?.phone_number || Constants.na,
        [Constants.date]: dayjs(element?.createdAt).format(
          Constants.dateFormat
        ),
        [Constants.time]: dayjs(element?.createdAt).format(
          Constants.timeFormat
        ),
        [Constants.state]: element?.address?.state,
      };
    });
    const csvData = saveExportedFile(Constants.deletedUsers, dispatch, data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", Constants.csvDeletedUsers);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="container-xxl mt-3">
      <PageHeader
        pagetitle={Constants.usersList}
        buttonLink={usersData?.length > 0 ? true : false}
        buttonName={Constants.export}
        onClick={() => exportDeletedUserList()}
      />
      <TableList
        columns={columns}
        data={usersData}
        defaultSortFieldId
        highlightOnHover
      />
      {userslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={userslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </div>
  );
};

export default DeletedUsers;
