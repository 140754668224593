import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { Form, Formik } from "formik";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { kycRequestResponse, usersDetail } from "../../Redux/Actions";
import { useParams } from "react-router-dom";
import { types } from "../../Redux/Actions/types";

const KycRejectionReason = ({ onClose }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Initial values for rejection form
  const [initialValues] = useState({
    reason: "",
  });

  // Validations for rejection form
  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      .required(Constants.required)
      .matches(/^.{1,50}$/, Constants.invalidReason),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        values.kyc_status = Constants.kycRejectKey;
        // Rejection API
        dispatch(kycRequestResponse(id, values)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(usersDetail(id));
            onClose();
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
          }
        });
      }}
    >
      {({ errors, touched }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="reason"
              className="form-control"
              errors={errors?.reason}
              touched={touched?.reason}
              label={Constants.reason}
            />
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default KycRejectionReason;
