import { mdiEyeOutline } from "@mdi/js";
import { Constants } from "../Constants";

const ViewListButton = (props) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        props?.onClick();
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d={mdiEyeOutline} />
      </svg>
      <span className="ps-2 name-f">{Constants.view}</span>
    </div>
  );
};

export default ViewListButton;
