/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import PageHeader from "../../Common/PageHeader";
import { Constants } from "../../Common/Constants";
import { types } from "../../../Redux/Actions/types";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import FaqForm from "./FaqForm";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import TableList from "../../Common/UiModel/TableList";
import { useState } from "react";
import {
  configFaqsDelete,
  configFaqsList,
  configFaqsUpdateStatus,
} from "../../../Redux/Actions/Configuration/Faq";
import responseMethod from "../../Common/Functions/responseMethod";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";
import dayjs from "dayjs";
import EditListButton from "../../Common/Fields/EditListButton";
import DeleteListButton from "../../Common/Fields/DeleteListButton";
import ToggleListField from "../../Common/Fields/ToggleListField";
import { Dropdown } from "react-bootstrap";
import ReactPagination from "../../Common/Fields/ReactPagination";
import usePermissions from "../../Common/Functions/usePermissions";

const Faq = () => {
  const dispatch = useDispatch();
  const { configFaqslist, popupmodel } = useSelector(
    (state) => state.ConfigFaqs
  );
  const [configFaqsData, setConfigFaqsData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);
  const deletePermission = usePermissions(Constants.permission.CONFG_DELETE);

  useEffect(() => {
    dispatch(configFaqsList());
  }, []);

  useEffect(() => {
    setConfigFaqsData(configFaqslist?.data);
  }, [configFaqslist]);

  const triggerToggle = (rowData) => {
    let itemId = rowData._id;
    let data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(configFaqsUpdateStatus(itemId, data)).then((response) => {
      let tempList = configFaqsData;
      let index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].status = data.status;
      setConfigFaqsData(tempList);
      responseMethod(response, dispatch, configFaqsList);
    });
  };

  const onDelete = (delete_id) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(configFaqsDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(configFaqsList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.ques,
      width: "30%",
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.question}
          onClick={() => navigator.clipboard.writeText(row?.question)}
        >
          {row?.question?.slice(0, 40) + "..."}
        </span>
      ),
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  {editPermission && (
                    <>
                      <div className="list-group">
                        <EditListButton
                          onClick={() => {
                            dispatch({
                              type: types.CONFIG_FAQ_MODEL,
                              payload: {
                                msg: Constants.editFaq,
                                show: true,
                                add: 0,
                              },
                            });
                            dispatch({
                              type: types.CONFIG_FAQ_SINGLE,
                              payload: row,
                            });
                          }}
                        />
                      </div>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.statusKey}
                          onChange={() => {
                            triggerToggle(row);
                          }}
                          checked={row.status === Constants.status.ACTIVE}
                        />
                      </div>
                    </>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  const handleOffset = (newOff) => {
    let data = {
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(configFaqsList(data));
  };

  return (
    <div className="container-xxl">
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.faqs}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.CONFIG_FAQ_MODEL,
              payload: { msg: Constants.addFaq, show: true },
            });
          }}
        />
      </div>
      <TableList
        columns={columns}
        data={configFaqsData}
        defaultSortFieldId
        highlightOnHover
      />
      {configFaqslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={configFaqslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.CONFIG_FAQ_SINGLE, payload: "" });
          dispatch({ type: types.CONFIG_FAQ_MODEL, payload: "" });
        }}
        dialogClassName={"modal-md"}
        title={popupmodel.msg}
        component={<FaqForm />}
      />
    </div>
  );
};

export default Faq;
