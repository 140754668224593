import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const bannersAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.BANNERS, data);
  return response;
};

export const bannersFormAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.BANNERS_FORM, data);
  return response;
};

export const bannersFormEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    `${ApiUrl.BANNERS_FORM}?id=${id}`,
    data
  );
  return response;
};

export const bannersEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.BANNERS + "?id=" + id,
    data
  );
  return response;
};

export const bannersRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.BANNERS + "?id=" + id
  );
  return response;
};

export const bannersStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.BANNERS_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const banners = async (data) => {
  let url = `${ApiUrl.BANNERS}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }${data && data?.search ? `&search=${data?.search}` : ""}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const detailBanner = async (id) => {
  const response = await httpServiceInstance.get(ApiUrl.BANNERS + `?id=${id}`);
  return response;
};

export const usersBanner = async (data) => {
  const response = await httpServiceInstance.get(
    ApiUrl.BANNERS_USERS +
      `?skip=${data?.skip || 0}&limit=${data?.limit}&banner_id=${
        data?.id
      }&redirection_type=${data?.type}`
  );
  return response;
};

export const userBannerForm = async (id, userId) => {
  const response = await httpServiceInstance.get(
    ApiUrl.BANNERS_USERS + `?banner_id=${id}&user_id=${userId}`
  );
  return response;
};
