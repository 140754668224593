/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import { Constants } from "../Common/Constants";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import TextEditor from "../Common/Fields/TextEditor";
import PageHeader from "../Common/PageHeader";
import * as Yup from "yup";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import usePermissions from "../Common/Functions/usePermissions";

const section = { type: Constants.configPrivacyPolicyKey };

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);
  const [initialValues, setInitialValues] = useState({
    policy: "",
  });

  const validationSchema = Yup.object().shape({
    policy: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response.data.data);
    });
  }, []);

  useEffect(() => {
    let listdata = settings;
    if (listdata && listdata.length > 0) {
      let editVal = {};
      for (const value of Object.values(listdata)) {
        editVal[value.name] = value.value;
        setInitialValues(editVal);
      }
    }
  }, [settings]);

  const onEditorChange = (editor, value, setFieldValue) => {
    const data = editor.getData();
    setFieldValue(value, data);
  };

  return (
    <>
      <div className=" container-xxl">
        <PageHeader pagetitle={Constants.configPrivacyPolicy} />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            let dataForm = {};
            dataForm.key_value = values;
            dataForm.type = section.type;
            let submitFunc = addAdminConfigurations(dataForm);
            dispatch(submitFunc).then((response) => {
              if (response.error) {
                dispatch({
                  type: types.ERROR_ALERT_VISIBLE,
                  payload: response.error,
                });
              } else {
                dispatch({
                  type: types.SUCCESS_ALERT_VISIBLE,
                  payload: response.data.message,
                });
                dispatch(getAdminConfigurations(section));
              }
            });
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form className="list-view card">
              <div className="mx-4">
                <TextEditor
                  hideRequiredIcon="true"
                  onChange={(_event, editor) =>
                    onEditorChange(editor, "policy", setFieldValue)
                  }
                  errors={errors?.policy ? errors?.policy : undefined}
                  touched={touched?.policy}
                  data={`${values?.policy || ""}`}
                />
              </div>
              {(editPermission || addPermission) && (
                <ButtonCustom label={Constants.submit} type="submit" />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PrivacyPolicy;
