/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../../Common/Constants";
import TextField from "../../Common/Fields/TextField";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import RequiredIcon from "../../Common/Fields/RequiredIcon";
import { mdiClose } from "@mdi/js";
import {
  categoryInsert,
  categoryList,
  categoryUpdate,
} from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import ViewImageFile from "../../Common/Fields/ViewImageFile";
import FileAwsUpload from "../../Common/Fields/FileAwsUpload";
import usePermissions from "../../Common/Functions/usePermissions";

const CategoryForm = () => {
  const dispatch = useDispatch();
  const { categorySingle } = useSelector((state) => state.Category);
  const [imagesArr, setImagesArr] = useState([]);
  // Initial values for category form
  const [initialValues, setInitialValues] = useState({
    name: "",
    banner_image: "",
    thumbnails: [],
  });
  const addPermission = usePermissions(Constants.permission.CATEGORY_ADD);
  const editPermission = usePermissions(Constants.permission.CATEGORY_EDIT);

  // Validations for category form
  const validationSchema = Yup.object().shape({
    thumbnails: Yup.array().min(1, Constants.required),
    name: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidTextRegex, Constants.invalid),
    banner_image: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial valuea to pre fill the Category form
    if (!!categorySingle) {
      let editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (categorySingle[key]) {
          editValue[key] = categorySingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [categorySingle]);

  const handleFileChange = (file, setFieldValue, field) => {
    // Add banner image for category to AWS
    setFieldValue(field, file?.[0]);
  };

  const removeImage = (setFieldValue, image, field) => {
    // Delete Category
    let arr = [...imagesArr];
    let idx = arr.findIndex((elt) => elt === image);
    arr.splice(idx, 1);
    setImagesArr(arr);
    setFieldValue(field, arr);
  };

  const handleUploadedFiles = (files, setFieldValue, key) => {
    let arr = [...imagesArr];
    arr.push(...files);
    setImagesArr(arr);
    setFieldValue(key, arr);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update category
        let submitFunc = categoryInsert(values);
        if (categorySingle?._id) {
          let id = categorySingle?._id;
          submitFunc = categoryUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(categoryList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.CATEGORY_SINGLE, payload: "" });
            dispatch({ type: types.CATEGORY_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.bannerImage}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.banner_image && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="376"
                    height="376"
                    errors={
                      touched?.banner_image ? errors?.banner_image : undefined
                    }
                    touched={touched?.banner_image}
                    label={`${Constants.upload} ${Constants.bannerImage}`}
                    handleFile={(files) =>
                      handleFileChange(files, setFieldValue, "banner_image")
                    }
                    maxFiles={1}
                  />
                )}
              </div>
              <div className="after-up">
                {values && values?.banner_image && (
                  <ViewImageFile
                    file={values?.banner_image}
                    onClick={() => setFieldValue(`banner_image`, "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.thumbnails}`}</label>
            <RequiredIcon />
            <div className="width-outer-up mb-3">
              <FileAwsUpload
                fileType={[".png", ".jpg", ".jpeg"]}
                width="163"
                height="97"
                errors={touched?.thumbnails ? errors?.thumbnails : undefined}
                touched={touched?.thumbnails}
                label={Constants.clickToUpload}
                handleFile={(files) =>
                  handleUploadedFiles(files, setFieldValue, "thumbnails")
                }
                maxFiles={25}
              />
            </div>
            <div className="image-grid">
              {values &&
                values?.thumbnails?.length > 0 &&
                values?.thumbnails?.map((elt) => {
                  return (
                    <div className="image-item" key={elt}>
                      <a href={elt} target="_blank" rel="noopener noreferrer">
                        <img alt={elt} src={elt} />
                      </a>
                      <button
                        type="button"
                        className="ms-4 delete-btn"
                        onClick={() =>
                          removeImage(setFieldValue, elt, "thumbnails")
                        }
                      >
                        <div className="delete-icon">
                          <svg width="16" height="16" viewBox="0 0 24 24">
                            <path d={mdiClose} fill="white" />
                          </svg>
                        </div>
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
          {((addPermission && !categorySingle?._id) ||
            (editPermission && categorySingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CategoryForm;
