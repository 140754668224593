import { types } from "../Actions/types";

const initialState = {
  promocodeslist: [],
  promocodesSingle: "",
  promouserslist: [],
  popupmodel: "",
};

export default function Promocodes(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.PROMOCODES_LIST:
      return {
        ...state,
        promocodeslist: payload,
      };
    case types.PROMOCODES_SINGLE:
      return {
        ...state,
        promocodesSingle: payload,
      };
    case types.PROMOCODES_USERS_LIST:
      return {
        ...state,
        promouserslist: payload,
      };
    case types.PROMOCODES_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}
