import { Constants } from "../../components/Common/Constants";
import {
  dashboardData,
  loginUser,
  logoutUser,
  userPermission,
} from "../../services/auth.service";
import { types } from "./types";
import Cookies from "js-cookie";

export const login = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await loginUser(data);
  if (response.status === true) {
    const userData = response.data.data;
    const obj = {};
    userData?.permissions?.forEach(
      (field) => (obj[field?.key] = field.is_enabled)
    );
    dispatch({ type: types.PERMISSION_OBJECT, payload: obj });
    localStorage.setItem(Constants.roleId, userData.user.role);
    Cookies.set(Constants.accessToken, userData.jwt.accessToken, {
      secure: true,
    });
    Cookies.set(Constants.refreshToken, userData.jwt.refreshToken, {
      secure: true,
    });
    localStorage.setItem(Constants.roleType, userData.role_type);
    localStorage.setItem(Constants.user, JSON.stringify(userData.user));
    dispatch({ type: types.LOGIN_SUCCESS, payload: userData });
    dispatch({ type: types.LOADER_HIDE });
    return { data: userData };
  } else {
    dispatch({ type: types.LOGIN_FAIL, payload: { error: response.error } });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const userPermissions = () => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await userPermission();
  if (response?.status === true) {
    const permissionData = response.data.data;
    const obj = {};
    permissionData?.forEach((field) => (obj[field?.key] = field.is_enabled));
    dispatch({ type: types.PERMISSION_OBJECT, payload: obj });
    dispatch({ type: types.LOADER_HIDE });
  } else {
    dispatch({ type: types.LOADER_HIDE });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await logoutUser();
  if (response.status === true) {
    dispatch({ type: types.LOADER_HIDE });
    dispatch({ type: types.LOGOUT });
    return { data: response?.data?.statusCode };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const fetchDashboardData = () => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await dashboardData();
  if (response.status === true) {
    dispatch({ type: types.LOADER_HIDE });
    return { data: response?.data?.data };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
