import { ApiUrl } from "../components/Common/ApiUrl";
import httpServiceInstance from "../helper/HttpService";
import { Constants } from "../components/Common/Constants";

export const users = async (data) => {
  let url = `${ApiUrl.USERS}${
    data?.skipPagination
      ? `?${Constants.paginationFalse}`
      : `?skip=${data?.skip || 0}`
  }&limit=${data?.limit || Constants.limitCount}${
    data && data?.search ? `&search=${data?.search}` : ""
  }${data?.state?.length > 0 ? `&state=${JSON.stringify(data?.state)}` : ""}${
    data?.city?.length > 0 ? `&city=${JSON.stringify(data?.city)}` : ""
  }${data?.goals?.length > 0 ? `&goals=${JSON.stringify(data?.goals)}` : ""}${
    data?.kyc_request > 0
      ? `&kyc_request=${JSON.stringify(data?.kyc_request)}`
      : ""
  }${data?.status ? `&status=${data?.status}` : ""}${
    data?.account_status ? `&account_status=${data?.account_status}` : ""
  }${
    data?.start_date
      ? `&start_date=${data?.start_date}&end_date=${data?.end_date}`
      : ""
  }${data?.courses ? `&courses=["${data?.courses}"]` : ""}${
    data?.userType?.length > 0
      ? `&userType=${JSON.stringify(data?.userType)}`
      : ""
  }${
    data?.completion_end
      ? `&completion_start=${data.completion_start}&completion_end=${data?.completion_end}`
      : ""
  }${data?.package ? `&package=["${data?.package}"]` : ""}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const detailUser = async (id) => {
  const response = await httpServiceInstance.get(ApiUrl.USERS + `?id=${id}`);
  return response;
};

export const kycResponse = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.KYC_APPROVAL + `?id=${id}`,
    data
  );
  return response;
};

export const enrollUser = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.ENROLL_USER, data);
  return response;
};

export const resendInvoice = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.RESEND_INVOICE, data);
  return response;
};

export const editCourseCompletionPercent = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.COURSE_COMPLETION,
    data
  );
  return response;
};

export const userCommentsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.USER_COMMENTS, data);
  return response;
};

export const userCommentsEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.USER_COMMENTS + "?id=" + id,
    data
  );
  return response;
};

export const userComments = async (id) => {
  let url = `${ApiUrl.USER_COMMENTS}?${Constants.paginationFalse}&user_id=${id}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const leadStatusEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.UPDATE_LEAD_STATUS + "?id=" + id,
    data
  );
  return response;
};

export const contactStatusEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.UPDATE_CONTACT_STATUS + "?id=" + id,
    data
  );
  return response;
};
