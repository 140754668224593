import { types } from "../../Actions/types";

const initialState = {
    configFaqslist: [],
    error: null,
    configFaqsSingle: "",
    popupmodel: "",
};

export default function ConfigFaqs(state = initialState, action) {
    let { type, payload, error } = action;
    if (payload && payload.error !== null) {
        error = payload.error;
    }

    switch (type) {
        case types.CONFIG_FAQ_LIST:
            return {
                ...state,
                configFaqslist: payload,
                error: error,
            };
        case types.CONFIG_FAQ_SINGLE:
            return {
                ...state,
                configFaqsSingle: payload,
                error: error,
            };
        case types.CONFIG_FAQ_MODEL:
            return {
                ...state,
                popupmodel: payload,
            };

        default:
            return state;
    }
}
