import {
  ques,
  quesAdd,
  quesEdit,
  quesRemove,
  quesStatusUpdate,
  sets,
} from "../../../services/Courses/quiz.service";
import { types } from "../types";

export const setsList = (courseId) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await sets(courseId);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.SETS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.SETS_LIST, error: response.error });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const quesList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await ques(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.QUES_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.QUES_LIST, error: response.error });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const quesInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await quesAdd(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const quesUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await quesEdit(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const quesDelete = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await quesRemove(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const quesUpdateStatus = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await quesStatusUpdate(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
