import { types } from "../../../Redux/Actions/types";

const responseMethod = (response, dispatch, list, id, moduleId) => {
  if (response.error) {
    dispatch({
      type: types.ERROR_ALERT_VISIBLE,
      payload: response.error,
    });
  } else {
    dispatch({
      type: types.SUCCESS_ALERT_VISIBLE,
      payload: response?.data?.message,
    });
    dispatch(list({ id: id || "", moduleId: moduleId || "" }));
  }
};

export default responseMethod;
