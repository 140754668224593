import { combineReducers } from "redux";
import Affiliate from "./Affiliate";
import AlertMessage from "./AlertMessage";
import Auth from "./Auth";
import Banners from "./Banner/Banners";
import Category from "./Catalog/Category";
import Configuration from "./Configuration/Configurations";
import ConfigFaqs from "./Configuration/Faq";
import Courses from "./Courses/Courses";
import CoursesFaqs from "./Courses/Faqs";
import EnrolledUsers from "./Courses/EnrolledUsers";
import Events from "./Events";
import FreeVideos from "./FreeVideos";
import Goals from "./Configuration/Goals";
import Lessons from "./Courses/Lessons";
import Loader from "./Loader";
import Modules from "./Courses/Modules";
import News from "./News";
import PackageCourses from "./Package/PackageCourses";
import Packages from "./Package/Packages";
import Promocodes from "./Promocode";
import Quiz from "./Courses/Quiz";
import Reports from "./Report";
import Resources from "./Courses/Resources";
import Reviews from "./Courses/Reviews";
import Roles from "./Roles";
import SubCategory from "./Catalog/SubCategory";
import Support from "./Support";
import Users from "./Users";
import Walkthrough from "./Configuration/Walkthrough";
import WelcomeEmail from "./Courses/WelcomeEmail";
import Transactions from "./Transactions";
import Withdraw from "./Withdraw";

export default combineReducers({
  Affiliate,
  AlertMessage,
  Auth,
  Banners,
  Category,
  Configuration,
  ConfigFaqs,
  Courses,
  CoursesFaqs,
  EnrolledUsers,
  Events,
  FreeVideos,
  Goals,
  Lessons,
  Loader,
  Modules,
  News,
  PackageCourses,
  Packages,
  Promocodes,
  Quiz,
  Reports,
  Resources,
  Reviews,
  Roles,
  SubCategory,
  Support,
  Transactions,
  Users,
  Walkthrough,
  WelcomeEmail,
  Withdraw,
});
