import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const sets = async (courseId) => {
  let url = ApiUrl.SETS + `?course_id=${courseId}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const ques = async (data) => {
  let url =
    ApiUrl.QUES +
    `?set_id=${data?.id}&skip=${data?.skip || 0}&limit=${
      data?.limit || Constants.limitCount
    }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const quesAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.QUES, data);
  return response;
};

export const quesRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.QUES + `?id=${id}`);
  return response;
};

export const quesEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.QUES + `?id=${id}`,
    data
  );
  return response;
};

export const quesStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.QUES_STATUS_UPDATE + `?id=${id}`,
    data
  );
  return response;
};
