import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userPermissions } from "../Redux/Actions";

/* eslint-disable react-hooks/exhaustive-deps */
const LoaderSpinner = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Role Permission API
    dispatch(userPermissions());
  }, []);

  return (
    <div id="preloader">
      <div id="loader"></div>
    </div>
  );
};

export default LoaderSpinner;
