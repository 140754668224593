import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const enrolledUsers = async (data) => {
  let url = `${ApiUrl.ENROLLED_USERS}?skip=${data?.skip || 0}&limit=${
    data?.limit
  }&${data?.type === Constants.package ? "package_id" : "course_id"}=${
    data?.id
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const editUser = async (data, id) => {
  let url = `${ApiUrl.USERS}?id=${id}`;
  const response = await httpServiceInstance.put(url, data);
  return response;
};

export const detailEnrolledUser = async (courseId, userId) => {
  const response = await httpServiceInstance.get(
    ApiUrl.ENROLLED_USERS + `?id=${userId}&course_id=${courseId}`
  );
  return response;
};

export const dateChangeEnrolledUser = async (data, courseId, userId) => {
  const response = await httpServiceInstance.put(
    ApiUrl.ENROLLED_USER_RESET_COURSE + `?id=${userId}&course_id=${courseId}`,
    data
  );
  return response;
};

export const importUsers = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.IMPORT_COURSE_USER,
    data
  );
  return response;
};
