/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exportUsersList, usersList } from "../../Redux/Actions/Users";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import StatesFilter from "./StatesFilter";
import StateCityFilter from "./StateCityFilter";
import { coursesList, goalsList, packagesList } from "../../Redux/Actions";
import getOptionsFromData from "../Common/Functions/getOptionsFromData";
import MultiSelect from "../Common/Fields/MultiSelect";
import { Link, useNavigate } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import ReactPagination from "../Common/Fields/ReactPagination";
import { types } from "../../Redux/Actions/types";
import { Button, Dropdown } from "react-bootstrap";
import ViewListButton from "../Common/Fields/ViewListButton";
import DateRangePicker from "../Common/Fields/DateRangePicker";
import dayjs from "dayjs";
import ReactSelect from "../Common/Fields/ReactSelect";
import saveExportedFile from "../Common/Functions/saveExportedFile";

const initDate = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

const UsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseslist } = useSelector((state) => state.Courses);
  const { packageslist } = useSelector((state) => state.Packages);
  const { userslist } = useSelector((state) => state.Users);
  const { goalslist } = useSelector((state) => state.Goals);
  const [usersData, setUsersData] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [showCalender, setShowCalender] = useState(false);
  const [initialDate, setInitialDate] = useState(initDate);
  const [range, setRange] = useState({});
  const [coursesSelected, setCoursesSelected] = useState({});
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [packageSelected, setPackageSelected] = useState("");
  const [packageOptions, setPackageOption] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [courseCompleted, setCourseCompleted] = useState({});

  useEffect(() => {
    // Users & Goals list API
    dispatch(usersList());
    dispatch(goalsList());
    dispatch(coursesList({ skipPagination: true }));
    dispatch(packagesList({ skipPagination: true }));
  }, []);

  useEffect(() => {
    setCoursesOptions(getOptionsFromData(courseslist?.data));
  }, [courseslist?.data]);

  useEffect(() => {
    setPackageOption(getOptionsFromData(packageslist?.data));
  }, [packageslist?.data]);

  useEffect(() => {
    // Set user list data
    setUsersData(userslist.data);
  }, [userslist]);

  useEffect(() => {
    // Convert goals list API data to array of objects (value & label)
    setGoalOptions(getOptionsFromData(goalslist?.data));
  }, [goalslist?.data]);

  useEffect(() => {
    // De-bounce function for search with delay of 500 seconds
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        getUsersData(search, selectedGoals, selectedState, selectedCity);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [search]);

  // Columns for User list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to={`${RoutesUrl.users}/${row?._id}`}>
          {row?.name || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.email,
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.email}
        >
          {row?.email?.slice(0, 10) + "..."}
        </span>
      ),
    },
    {
      name: Constants.number,
      cell: (row) => `${row?.dial_code} ${row?.phone_number}`,
    },
    {
      name: Constants.state,
      cell: (row) => row?.address?.state || Constants.na,
    },
    {
      name: Constants.city,
      cell: (row) => row?.address?.city || Constants.na,
    },
    {
      name: Constants.goals,
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.goals?.map((elt) => elt.name).join(", ")}
        >
          {row?.goals?.length > 0
            ? row?.goals
                ?.map((elt) => elt.name)
                .join(", ")
                ?.slice(0, 15) + "..."
            : Constants.na}
        </span>
      ),
    },
    {
      name: Constants.course,
      cell: (row) => {
        const val =
          row?.courses_name?.length > 0
            ? row?.courses_name.map((course) =>
                course?.name
                  ? `${course.name} (${course.course_completion_percentage}%)`
                  : Constants.na
              )
            : Constants.na;
        return (
          <span data-bs-toggle="tooltip" data-bs-placement="top" title={val}>
            {row?.courses_name?.length > 0
              ? val !== Constants.na
                ? val?.join(", ")?.slice(0, 15) + "..."
                : Constants.na
              : Constants.na}
          </span>
        );
      },
    },
    {
      name: Constants.package,
      cell: (row) => {
        const val =
          row?.packages_name?.length > 0
            ? row?.packages_name.map((elt) =>
                elt?.name
                  ? `${elt.name} (${elt.course_completion_percentage}%)`
                  : Constants.na
              )
            : Constants.na;
        return (
          <span data-bs-toggle="tooltip" data-bs-placement="top" title={val}>
            {row?.packages_name?.length > 0
              ? val !== Constants.na
                ? val?.join(", ")?.slice(0, 15) + "..."
                : Constants.na
              : Constants.na}
          </span>
        );
      },
    },
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        navigate(`${RoutesUrl.users}/${row?._id}`);
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  const getUsersData = (searchKey, goalsKey, stateKey, cityKey) => {
    const [completion_start = 0, completion_end = 0] =
      courseCompleted?.value || [];
    // User list API based on all filters
    let data = {
      search: searchKey,
      goals: goalsKey?.map((elt) => elt.value) || [],
      state: stateKey?.map((elt) => elt.label.toLowerCase()) || [],
      city: cityKey?.map((elt) => elt.label.toLowerCase()) || [],
      start_date: range?.start_date,
      end_date: range?.end_date,
      completion_start,
      completion_end,
      userType: usersSelected?.map((elt) => elt?.value),
      courses: coursesSelected?.value,
      package: packageSelected?.value,
    };
    dispatch(usersList(data));
  };

  const handleStateChange = (states) => {
    // State selection
    setSelectedState(states);
    setSelectedCity([]);
    getUsersData(search, selectedGoals, states, []);
  };

  const handleCityChange = (cities) => {
    // City selection
    setSelectedCity(cities);
    getUsersData(search, selectedGoals, selectedState, cities);
  };

  const handleGoalsChange = (goals) => {
    // Goal selection
    setSelectedGoals(goals);
    getUsersData(search, goals, selectedState, selectedCity);
  };

  const onResetFilter = () => {
    // Reset filter
    setSearch("");
    setRange({});
    setSelectedGoals([]);
    setSelectedState([]);
    setSelectedCity([]);
    setCourseCompleted([]);
    setUsersSelected([]);
    setCoursesSelected([]);
    setPackageSelected("");
    setInitialDate(initDate);
    dispatch(usersList());
  };

  const handleOffset = (newOff) => {
    const [completion_start = 0, completion_end = 0] =
      courseCompleted?.value || [];
    let data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
      goals: selectedGoals?.map((elt) => elt.value) || [],
      state: selectedState?.map((elt) => elt.label.toLowerCase()) || [],
      city: selectedCity?.map((elt) => elt.label.toLowerCase()) || [],
      start_date: range?.start_date,
      end_date: range?.end_date,
      completion_start,
      completion_end,
      userType: usersSelected?.map((elt) => elt?.value),
      courses: coursesSelected?.value,
      package: packageSelected?.value,
    };
    dispatch(usersList(data));
  };

  // Function to export users list to CSV format
  const exportUserList = () => {
    const [completion_start = 0, completion_end = 0] =
      courseCompleted?.value || [];
    let data = {
      skipPagination: true,
      search,
      goals: selectedGoals?.map((elt) => elt.value) || [],
      state: selectedState?.map((elt) => elt.label.toLowerCase()) || [],
      city: selectedCity?.map((elt) => elt.label.toLowerCase()) || [],
      start_date: range?.start_date,
      end_date: range?.end_date,
      completion_start,
      completion_end,
      userType: usersSelected?.map((elt) => elt?.value),
      courses: coursesSelected?.value,
    };
    dispatch(exportUsersList(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        handleDownloadClick(response?.data?.data);
      }
    });
  };

  // Function to handle the download click for exported CSV data
  const handleDownloadClick = (transactionList) => {
    const data = transactionList?.map((element) => {
      return {
        [Constants.name]: element.name,
        [Constants.email]: element.email || Constants.na,
        [Constants.dialCode]: element?.dial_code || Constants.na,
        [Constants.number]: element?.phone_number || Constants.na,
        [Constants.state]: element?.address?.state || Constants.na,
        [Constants.city]: element?.address?.city || Constants.na,
        [Constants.goals]:
          element?.goals?.map((elt) => elt.name).join(", ") || Constants.na,
        [Constants.course]: `${
          element?.courses_name?.length > 0
            ? element?.courses_name.map((course) =>
                course?.name
                  ? `${course.name} (${course.course_completion_percentage}%)`
                  : Constants.na
              )
            : Constants.na
        }`,
        [Constants.package]: `${
          element?.packages_name?.length > 0
            ? element?.packages_name.map((elt) =>
                elt?.name
                  ? `${elt.name} (${elt.course_completion_percentage}%)`
                  : Constants.na
              )
            : Constants.na
        }`,
      };
    });
    const csvData = saveExportedFile(Constants.customer, dispatch, data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", Constants.csvUser);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const onRangeChange = (range) => {
    setInitialDate({
      startDate: range?.[0]?.startDate,
      endDate: range?.[0]?.endDate,
      key: "selection",
    });
    setRange({
      start_date: dayjs(range?.[0]?.startDate).format("YYYY-MM-DD"),
      end_date: dayjs(range?.[0]?.endDate).format("YYYY-MM-DD"),
    });
  };

  const onDateFilter = () => {
    const [completion_start = 0, completion_end = 0] =
      courseCompleted?.value || [];
    setShowCalender(false);
    let data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      goals: selectedGoals?.map((elt) => elt.value) || [],
      state: selectedState?.map((elt) => elt.label.toLowerCase()) || [],
      city: selectedCity?.map((elt) => elt.label.toLowerCase()) || [],
      start_date: range?.start_date,
      end_date: range?.end_date,
      completion_start,
      completion_end,
      userType: usersSelected?.map((elt) => elt?.value),
      courses: coursesSelected?.value,
      package: packageSelected?.value,
    };
    dispatch(usersList(data));
  };

  const handleCourseSelection = (selected) => {
    const [completion_start = 0, completion_end = 0] =
      courseCompleted?.value || [];
    setCoursesSelected(selected);
    let data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      courses: selected?.value,
      completion_start,
      completion_end,
      userType: usersSelected?.map((elt) => elt?.value),
      goals: selectedGoals?.map((elt) => elt.value) || [],
      state: selectedState?.map((elt) => elt.label.toLowerCase()) || [],
      city: selectedCity?.map((elt) => elt.label.toLowerCase()) || [],
      package: packageSelected?.value,
    };
    dispatch(usersList(data));
  };

  const handlePackageSelection = (selected) => {
    const [completion_start = 0, completion_end = 0] =
      courseCompleted?.value || [];
    setPackageSelected(selected);
    let data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      courses: coursesSelected?.value,
      package: selected?.value,
      completion_start,
      completion_end,
      userType: usersSelected?.map((elt) => elt?.value),
      goals: selectedGoals?.map((elt) => elt.value) || [],
      state: selectedState?.map((elt) => elt.label.toLowerCase()) || [],
      city: selectedCity?.map((elt) => elt.label.toLowerCase()) || [],
    };
    dispatch(usersList(data));
  };

  const handleUserSelection = (selected) => {
    const [completion_start = 0, completion_end = 0] =
      courseCompleted?.value || [];
    setUsersSelected(selected);
    let data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      userType: selected?.map((elt) => elt?.value),
      completion_start,
      completion_end,
      courses: coursesSelected?.value,
      goals: selectedGoals?.map((elt) => elt.value) || [],
      state: selectedState?.map((elt) => elt.label.toLowerCase()) || [],
      city: selectedCity?.map((elt) => elt.label.toLowerCase()) || [],
      package: packageSelected?.value,
    };
    dispatch(usersList(data));
  };

  const handleCompletionSelection = (selected) => {
    setCourseCompleted(selected);
    const [completion_start, completion_end] = selected?.value;
    let data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      completion_start,
      completion_end,
      userType: usersSelected?.map((elt) => elt?.value),
      courses: coursesSelected?.value,
      goals: selectedGoals?.map((elt) => elt.value) || [],
      state: selectedState?.map((elt) => elt.label.toLowerCase()) || [],
      city: selectedCity?.map((elt) => elt.label.toLowerCase()) || [],
      package: packageSelected?.value,
    };
    dispatch(usersList(data));
  };

  return (
    <>
      <div className="container-xxl">
        <div className="mt-2">
          <PageHeader
            pagetitle={Constants.usersList}
            buttonLink={usersData?.length > 0 ? true : false}
            buttonName={Constants.export}
            onClick={() => exportUserList()}
          />
        </div>
        <div className="flex-header-search">
          <div className="search-w">
            <div className="row mb-4">
              {/* Goals filter */}
              <div className="col-md-3">
                <label className="form-label">{`${Constants.select} ${Constants.goals}`}</label>
                <MultiSelect
                  key="multi_select"
                  options={goalOptions}
                  onChange={(selected) => {
                    handleGoalsChange(selected);
                  }}
                  isSelectAll={true}
                  value={selectedGoals}
                  menuPlacement={"bottom"}
                />
              </div>
              {/* State filter */}
              <div className="col-md-3">
                <StatesFilter
                  selectedStates={(states) => handleStateChange(states)}
                  reset={selectedState}
                />
              </div>
              {/* City filter based on state(s) */}
              <div className="col-md-3">
                <StateCityFilter
                  states={selectedState}
                  selectedCities={(cities) => handleCityChange(cities)}
                />
              </div>
              {/* Search */}
              <div className="col-md-3">
                <label className="form-label">{`${Constants.search}`}</label>
                <div>
                  <input
                    className="px-2 filter-sec-trans"
                    type="search"
                    value={search}
                    placeholder={Constants.usersSearch}
                    onChange={(event) => {
                      setSearch(event.target.value);
                      if (event.target.value === "") {
                        setSearch("");
                        getUsersData(
                          "",
                          selectedGoals,
                          selectedState,
                          selectedCity
                        );
                      }
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          {/* Reset filters */}
          <div className="bnt-filters mt-1">
            <button
              className="btn btn-primary text-uppercase px-4 p-right fw-bold reset-btn"
              onClick={() => onResetFilter()}
            >
              {Constants.reset}
            </button>
          </div>
        </div>
        <div className="search-w space-calender position-relative">
          <div
            className={`calender-outer ${
              range?.start_date ? "calender-active" : ""
            }`}
          >
            <i
              style={{
                cursor: "pointer",
                fontSize: "25px",
              }}
              className="fa fa-calendar"
              aria-hidden="true"
              onClick={() => {
                setShowCalender((prevState) => !prevState);
              }}
            ></i>
          </div>
          <div className="row mb-4">
            {showCalender && (
              <div className="date-pos">
                <div>
                  <DateRangePicker
                    initialDate={initialDate}
                    rangeSelected={(range) => {
                      onRangeChange(range);
                    }}
                  />
                </div>
                <div className="appy-btn">
                  <Button
                    className="text-uppercase fw-bold text-center mx-auto d-block"
                    onClick={() => {
                      onDateFilter();
                    }}
                  >
                    {Constants.apply}
                  </Button>
                </div>
              </div>
            )}
            <div className="col-md-3">
              <ReactSelect
                label={`${Constants.select} ${Constants.course}`}
                required={false}
                options={coursesOptions}
                onChange={(selected) => handleCourseSelection(selected)}
                value={coursesSelected}
              />
            </div>
            <div className="col-md-3">
              <ReactSelect
                label={`${Constants.select} ${Constants.package}`}
                required={false}
                options={packageOptions}
                onChange={(selected) => handlePackageSelection(selected)}
                value={packageSelected}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">{`${Constants.select} ${Constants.usersType}`}</label>
              <MultiSelect
                key="multi_select"
                options={Constants.usersTypeOptions}
                onChange={(selected) => handleUserSelection(selected)}
                value={usersSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="col-md-3">
              <ReactSelect
                label={`${Constants.select} ${Constants.courseCompletionPer}`}
                required={false}
                options={Constants.completionPercentOptions}
                onChange={(e) => handleCompletionSelection(e)}
                value={courseCompleted}
              />
            </div>
          </div>
        </div>
        <TableList
          columns={columns}
          data={usersData}
          defaultSortFieldId
          highlightOnHover
        />
      </div>
      {userslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={userslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </>
  );
};

export default UsersList;
