import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const coursesFaqsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.COURSES_FAQS, data);
  return response;
};

export const coursesFaqsEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.COURSES_FAQS + "?id=" + id,
    data
  );
  return response;
};

export const coursesFaqsRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.COURSES_FAQS + "?id=" + id
  );
  return response;
};

export const coursesFaqsStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.COURSES_FAQS_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const coursesFaqs = async (data) => {
  let url = `${ApiUrl.COURSES_FAQS}?course_id=${data?.id}&skip=${
    data?.skip || 0
  }&limit=${data?.limit || Constants.limitCount}`;
  const response = await httpServiceInstance.get(url);
  return response;
};
