import { mdiFile } from "@mdi/js";

const ViewFile = ({ file, onClick }) => (
  <>
    <a href={file} target="_blank" rel="noopener noreferrer">
      <svg width="48" height="48" viewBox="0 0 24 24" className="mt-4">
        <path d={mdiFile} />
      </svg>
    </a>
    <div className="files-list-item-remove mt-3" onClick={() => onClick()} />
  </>
);

export default ViewFile;
