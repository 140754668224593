/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TabsBootstrap from "../../Common/UiModel/TabsBootstrap";
import { Constants } from "../../Common/Constants";
import BannerDetailPage from "./Detail/BannerDetailPage";
import { useDispatch, useSelector } from "react-redux";
import { bannersDetail } from "../../../Redux/Actions";
import FormBanner from "./Form.js";
import Users from "./Response/Users.js";
import { RoutesUrl } from "../../Common/RoutesUrl.js";
import PageHeader from "../../Common/PageHeader.js";

// Tabs initially
const initialTabsList = [
  {
    eventKey: "detail",
    title: Constants.detail,
    component: <BannerDetailPage />,
  },
];

const BannerDetail = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const activeTab = params.get("activeTab");
  const [tabsList, setTabsList] = useState(initialTabsList);
  const [activetab, setActiveTab] = useState(
    activeTab || tabsList?.[0]?.eventKey
  );
  const { bannersSingle } = useSelector((state) => state.Banners);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(bannersDetail(id));
  }, []);

  useEffect(() => {
    if (bannersSingle?.redirection_type) {
      let list = [...tabsList];
      if (
        tabsList.findIndex((elt) => elt.eventKey === Constants.usersKey) ===
          -1 &&
        bannersSingle?.redirection_type !== Constants.imageKey
      ) {
        // Add response users tab
        list.push({
          eventKey: Constants.usersKey,
          title: Constants.users,
          component: <Users />,
        });
      }
      setTabsList(list);
      if (
        bannersSingle?.redirection_type === Constants.formKey &&
        tabsList.findIndex((elt) => elt.eventKey === Constants.formKey) === -1
      ) {
        // Add form tab if redirection type of banner is form
        list.splice(1, 0, {
          eventKey: Constants.formKey,
          title: Constants.form,
          component: <FormBanner />,
        });
      }
    }
  }, [bannersSingle]);

  useEffect(() => {
    // Set active tab as per URL
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    // Set URL in correspondance with active tab
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div className="container-xxl common-space">
      <PageHeader list={RoutesUrl.banners} />
      <div className="row catalog_box al_catalog_box">
        <div className="col-md-12 mb-4 order-list-view">
          {tabsList.length > 0 && (
            <TabsBootstrap
              defaultActiveKey={activetab}
              onSelect={(index) => {
                setActiveTab(index);
              }}
              activeKey={activetab}
              handleTabSelect={handleTabSelect}
              options={tabsList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerDetail;
