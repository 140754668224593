import React from "react";
import SignIn from "../../assets/images/SignIn.png";

function AuthLeft() {
  // static image on signup page
  return (
    <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
      <div style={{ maxWidth: "25rem" }}>
        <div>
          <img src={SignIn} alt="login-img" />
        </div>
      </div>
    </div>
  );
}
export default AuthLeft;
