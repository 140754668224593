import { types } from "../Actions/types";

const initialState = {
  transactionsList: [],
  transactionsSingle: "",
};

export default function Transactions(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.TRANSACTIONS_LIST:
      return {
        ...state,
        transactionsList: payload,
      };
    case types.TRANSACTIONS_SINGLE:
      return {
        ...state,
        transactionsSingle: payload,
      };
    default:
      return state;
  }
}
