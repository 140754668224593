import React from "react";
import { Link } from "react-router-dom";
import { Constants } from "./Constants";
import BackButton from "./Fields/BackButton";
import ExcelIcon from "../../assets/images/excel.png";

function PageHeader(props) {
  const { pagetitle, buttonLink, onClick } = props;

  return (
    <div className="">
      {props?.list && <BackButton list={props?.list} />}
      <div
        className={`${
          props.className || ""
        } order-list-view align-items-center`}
      >
        <div className="  border-0 p-30  ">
          <div
            className={`card-header m-pt-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between mb-4 flex-wrap`}
          >
            <h3 className="fw-bold mb-0 page-title title-space mt-0  ">
              {pagetitle}
            </h3>
            {buttonLink && (
              <div className="d-flex justify-content-between align-items-center btn-hover">
                <Link
                  to={buttonLink}
                  onClick={onClick}
                  title=""
                  className="btn btn-primary text-uppercase"
                >
                  {props?.buttonName === Constants.export ? (
                    <img
                      src={ExcelIcon}
                      alt="excel-icon"
                      className="img-excel"
                    />
                  ) : (
                    <i className="icofont-plus-circle me-2 fs-6"></i>
                  )}
                  {props?.buttonName || Constants.add}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
