import { mdiPencilOutline } from "@mdi/js";
import { Constants } from "../Constants";

const EditListButton = ({ onClick }) => (
  <div
    className="action-icon"
    onClick={() => {
      onClick();
    }}
  >
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d={mdiPencilOutline} />
    </svg>
    <span className="ps-2 name-f">{Constants.edit}</span>
  </div>
);

export default EditListButton;
