import { State } from "country-state-city";
import MultiSelect from "../Common/Fields/MultiSelect";
import { Constants } from "../Common/Constants";
import { useEffect, useState } from "react";
const states = State.getStatesOfCountry("IN").map((elt) => {
  return { value: elt.isoCode, label: elt.name };
});

const StatesFilter = ({ selectedStates, reset }) => {
  const [allStates] = useState(states);
  const [selectedState, setSelectedState] = useState([]);

  useEffect(() => {
    if (reset?.length === 0) setSelectedState([]);
  }, [reset]);

  return (
    <>
      <label className="form-label">{`${Constants.select} ${Constants.state}`}</label>
      <MultiSelect
        key="multi_select"
        options={allStates}
        onChange={(selected) => {
          setSelectedState(selected);
          selectedStates(selected);
        }}
        value={selectedState}
        isSelectAll={true}
        menuPlacement={"bottom"}
      />
    </>
  );
};

export default StatesFilter;
