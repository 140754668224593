import {
  banners,
  bannersAdd,
  bannersEdit,
  bannersFormAdd,
  bannersFormEdit,
  bannersRemove,
  bannersStatusUpdate,
  detailBanner,
  userBannerForm,
  usersBanner,
} from "../../../services/Banner/banner.service";
import { types } from "../types";

export const bannersInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await bannersAdd(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const bannersFormInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await bannersFormAdd(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const bannersFormUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await bannersFormEdit(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const bannersList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await banners(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.BANNERS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.BANNERS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const bannersDetail = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await detailBanner(id);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.BANNERS_SINGLE, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.BANNERS_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const bannersUsers = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await usersBanner(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.BANNERS_USERS_LIST, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.BANNERS_USERS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const bannerUserForm = (id, type) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await userBannerForm(id, type);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.BANNERS_USER_FORM, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.BANNERS_USER_FORM,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const bannersUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await bannersEdit(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const bannersDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await bannersRemove(id);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const bannersUpdateStatus = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await bannersStatusUpdate(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
