/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import { Constants } from "../Common/Constants";
import TextField from "../Common/Fields/TextField";
import PageHeader from "../Common/PageHeader";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import * as Yup from "yup";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import usePermissions from "../Common/Functions/usePermissions";

const section = { type: Constants.configLoyaltyKey };

const Loyalty = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);
  const [initialValues, setInitialValues] = useState({
    loyaltyPercentage: "",
  });

  const validationSchema = Yup.object().shape({
    loyaltyPercentage: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidPercentRegex, Constants.invalidValue),
  });

  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response.data.data);
    });
  }, []);

  useEffect(() => {
    let listdata = settings;
    if (listdata && listdata.length > 0) {
      let editVal = {};
      for (const value of Object.values(listdata)) {
        editVal[value.name] = value.value;
        setInitialValues(editVal);
      }
    }
  }, [settings]);

  return (
    <div className="container-xxl">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let dataForm = {};
          dataForm.key_value = values;
          dataForm.type = section.type;
          let submitFunc = addAdminConfigurations(dataForm);
          dispatch(submitFunc).then((response) => {
            if (response.error) {
              dispatch({
                type: types.ERROR_ALERT_VISIBLE,
                payload: response.error,
              });
            } else {
              dispatch({
                type: types.SUCCESS_ALERT_VISIBLE,
                payload: response.data.message,
              });
              dispatch(getAdminConfigurations(section));
            }
          });
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-control  mt-3 list-view card">
            <PageHeader pagetitle={Constants.configLoyalty} />
            <div className="row">
              <div className="col-lg-4">
                <TextField
                  type="text"
                  label={Constants.loyaltyPercentage}
                  name="loyaltyPercentage"
                  errors={
                    touched?.loyaltyPercentage
                      ? errors?.loyaltyPercentage
                      : undefined
                  }
                  touched={touched?.loyaltyPercentage}
                />
              </div>
              {(addPermission || editPermission) && (
                <div className="col-lg-4 btn-left">
                  <ButtonCustom
                    className="m-2 btn btn-primary text-uppercase px-4 fw-bold"
                    type="submit"
                    label={Constants.submit}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Loyalty;
