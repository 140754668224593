import Select from "react-select";
import RequiredIcon from "./RequiredIcon";
import { Constants } from "../Constants";

const ReactSelect = ({
  options,
  value,
  touched,
  errors,
  onChange,
  label,
  required,
  isDisabled,
  placeholder,
}) => {
  return (
    <>
      {label && <label className="form-label">{label}</label>}
      {required && <RequiredIcon />}
      <Select
        isDisabled={isDisabled || false}
        value={value}
        onChange={(e) => onChange(e)}
        options={options}
        placeholder={placeholder || Constants.select}
      />
      {(touched ? errors : undefined || touched) && (
        <p className="error-msg">{touched ? errors : undefined}</p>
      )}
    </>
  );
};

export default ReactSelect;
