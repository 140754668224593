import { types } from "../../Actions/types";

const initialState = {
  enrolledUserslist: [],
  enrolledUsersSingle: "",
};

export default function EnrolledUsers(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.ENROLLED_USERS_LIST:
      return {
        ...state,
        enrolledUserslist: payload,
      };
    case types.ENROLLED_USERS_SINGLE:
      return {
        ...state,
        enrolledUsersSingle: payload,
      };

    default:
      return state;
  }
}
