import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { Form, Formik } from "formik";
import {
  eventsDetail,
  eventsInsert,
  eventsList,
  eventsUpdate,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import TextArea from "../Common/Fields/TextArea";
import dayjs from "dayjs";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ToggleField from "../Common/Fields/ToggleField";
import usePermissions from "../Common/Functions/usePermissions";

const EventForm = () => {
  const today = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const { eventsSingle } = useSelector((state) => state.Events);
  // Initial values for event form
  const [initialValues, setInitialValues] = useState({
    name: "",
    image: "",
    banner_image: "",
    description: "",
    address: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    total_seats: "",
    price: "",
    tax_percentage: "",
    tax_included: false,
    recommended: false,
    recently_added: false,
    top_three: false,
  });
  const addPermission = usePermissions(Constants.permission.EVENT_ADD);
  const editPermission = usePermissions(Constants.permission.EVENT_EDIT);

  // Validation for event form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    image: Yup.string().required(Constants.required),
    banner_image: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    address: Yup.string().required(Constants.required),
    total_seats: Yup.string().required(Constants.required),
    price: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidPriceRegex, Constants.invalidPrice),
    start_date: Yup.string().required(Constants.required),
    start_time: Yup.string().required(Constants.required),
    end_date: Yup.string().required(Constants.required),
    tax_percentage: Yup.string().test(
      "taxValidation",
      Constants.invalid,
      function (value) {
        const taxIncluded = this.parent.tax_included;
        if (taxIncluded) {
          return value && Constants.invalidPercentRegex.test(value);
        }
        return true;
      }
    ),
    // .test(
    //   "is-greater",
    //   Constants.endDateGreaterThanStartDate,
    //   function (end_date) {
    //     const start_date = this.parent.start_date;
    //     if (!start_date || !end_date) {
    //       return true;
    //     }
    //     return dayjs(end_date).isAfter(start_date);
    //   }
    // ),
    end_time: Yup.string()
      .test(
        "is-greater",
        "End time should be greater than start time",
        function (end_time) {
          const { start_date, end_date, start_time } = this.parent;
          // Check if start date and end date are the same
          const isSameDate =
            start_date && end_date && dayjs(start_date).isSame(end_date, "day");
          // If start date and end date are the same, compare the times
          if (isSameDate) {
            // Manually ensure "HH:mm" format before parsing
            const formattedStartTime = `${start_date} ${start_time}`;
            const formattedEndTime = `${end_date} ${end_time}`;
            // Parse the time strings
            const start_time_dayjs = dayjs(formattedStartTime);
            const end_time_dayjs = dayjs(formattedEndTime);
            return (
              start_time_dayjs &&
              end_time_dayjs &&
              (end_time_dayjs.isAfter(start_time_dayjs) ||
                end_time_dayjs.isSame(start_time_dayjs))
            );
          }
          // If start date and end date are different, no additional validation needed for end time
          return true;
        }
      )
      .required(Constants.required),
  });

  useEffect(() => {
    // Set initial values for pre-filled form
    if (eventsSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        editValue[key] = eventsSingle[key];
        setInitialValues(editValue);
      }
    }
  }, [eventsSingle]);

  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update event API
        let submitFunc = eventsInsert(values);
        if (eventsSingle?._id) {
          const id = eventsSingle?._id;
          submitFunc = eventsUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            if (eventsSingle?._id) dispatch(eventsDetail(eventsSingle?._id));
            else dispatch(eventsList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.EVENTS_SINGLE, payload: "" });
            dispatch({ type: types.EVENTS_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-6 mb-2">
            <label className="form-label">{`${Constants.image}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.image && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="150.6"
                    height="189"
                    errors={touched?.image ? errors?.image : undefined}
                    touched={touched?.image}
                    label={`${Constants.upload} ${Constants.image}`}
                    handleFile={(image) =>
                      handleFileChange(image, setFieldValue, "image")
                    }
                    maxFiles={1}
                  />
                )}
                {values && values?.image && (
                  <ViewImageFile
                    file={values?.image}
                    onClick={() => setFieldValue("image", "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <label className="form-label">{`${Constants.bannerImage}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.banner_image && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="335"
                    height="189"
                    errors={
                      touched?.banner_image ? errors?.banner_image : undefined
                    }
                    touched={touched?.banner_image}
                    label={`${Constants.upload} ${Constants.bannerImage}`}
                    handleFile={(banner_image) =>
                      handleFileChange(
                        banner_image,
                        setFieldValue,
                        "banner_image"
                      )
                    }
                    maxFiles={1}
                  />
                )}
                {values && values?.banner_image && (
                  <ViewImageFile
                    file={values?.banner_image}
                    onClick={() => setFieldValue("banner_image", "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextArea
              type="text"
              name="address"
              className="form-control"
              errors={touched?.address ? errors?.address : undefined}
              touched={touched?.address}
              label={Constants.address}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="total_seats"
              className="form-control"
              errors={touched?.total_seats ? errors?.total_seats : undefined}
              touched={touched?.total_seats}
              label={Constants.totalSeats}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="price"
              className="form-control"
              errors={touched?.price ? errors?.price : undefined}
              touched={touched?.price}
              label={Constants.price}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="date"
              name="start_date"
              min={eventsSingle?._id ? false : today}
              className="form-control"
              errors={touched?.start_date ? errors?.start_date : undefined}
              touched={touched?.start_date}
              label={Constants.startDate}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="time"
              name="start_time"
              className="form-control"
              errors={touched?.start_time ? errors?.start_time : undefined}
              touched={touched?.start_time}
              label={Constants.startTime}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="date"
              name="end_date"
              className="form-control"
              min={values?.start_date}
              errors={touched?.end_date ? errors?.end_date : undefined}
              touched={touched?.end_date}
              label={Constants.endDate}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="time"
              name="end_time"
              className="form-control"
              errors={touched?.end_time ? errors?.end_time : undefined}
              touched={touched?.end_time}
              label={Constants.endTime}
            />
          </div>
          <div className="col-md-3 mb-2">
            <label className="form-label">{Constants.topThree}</label>
            <div className="flex-toggle ">
              <ToggleField
                onChange={(e) => {
                  setFieldValue("top_three", e.target.checked);
                }}
                checked={values.top_three}
              />
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <label className="form-label">{Constants.recommended}</label>
            <div className="flex-toggle ">
              <ToggleField
                onChange={(e) => {
                  setFieldValue("recommended", e.target.checked);
                }}
                checked={values.recommended}
              />
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <label className="form-label">{Constants.recentlyAdded}</label>
            <div className="flex-toggle ">
              <ToggleField
                onChange={(e) => {
                  setFieldValue("recently_added", e.target.checked);
                }}
                checked={values.recently_added}
              />
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <label className="form-label">{Constants.taxKey}</label>
            <div className="flex-toggle ">
              <ToggleField
                onChange={(e) => {
                  setFieldValue("tax_included", e.target.checked);
                }}
                checked={values.tax_included}
              />
            </div>
          </div>
          {values?.tax_included && (
            <div className="col-md-4 mb-2">
              <TextField
                type="text"
                name="tax_percentage"
                className="form-control"
                errors={
                  touched?.tax_percentage ? errors?.tax_percentage : undefined
                }
                touched={touched?.tax_percentage}
                label={Constants.taxPercent}
              />
            </div>
          )}
          {((addPermission && !eventsSingle?._id) ||
            (editPermission && eventsSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default EventForm;
