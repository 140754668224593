import { types } from "../Actions/types";

const initialState = {
  affiliateuserlist: [],
};

export default function Affiliate(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.AFFILIATE_USER_LIST:
      return {
        ...state,
        affiliateuserlist: payload,
      };

    default:
      return state;
  }
}
