/* eslint-disable react-hooks/exhaustive-deps */
import CoursesForm from "../../CoursesForm";
import PageHeader from "../../../Common/PageHeader";
import { Constants } from "../../../Common/Constants";

const CourseDetailPage = () => {
  return (
    <div className="mt-4 course-form card">
      <PageHeader pagetitle={Constants.detail} />
      <CoursesForm />
    </div>
  );
};

export default CourseDetailPage;
