const ViewImageFile = ({ file, onClick, remove = false }) => (
  <>
    <a href={file} target="_blank" rel="noreferrer">
      <img
        className="avatar lg img-thumbnail "
        alt={file}
        src={file}
      />
    </a>
    {!remove && (
      <div className="after-up">
      <div className="files-list-item-remove" onClick={() => onClick()} />
      </div>
    )}
  </>
);

export default ViewImageFile;
