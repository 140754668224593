import {
  detailwithdraw,
  withdrawRequests,
} from "../../services/withdraw.service";
import { types } from "./types";

export const getWithdrawRequests = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await withdrawRequests(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.WITHDRAW_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.WITHDRAW_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const withdrawListExport = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await withdrawRequests(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const withdrawDetail = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await detailwithdraw(id);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.WITHDRAW_SINGLE, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.WITHDRAW_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
