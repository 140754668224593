import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const packageCoursesAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.PACKAGE_COURSES, data);
  return response;
};

export const packageCoursesEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.PACKAGE_COURSES + "?id=" + id,
    data
  );
  return response;
};

export const packageCoursesRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.PACKAGE_COURSES + "?id=" + id
  );
  return response;
};

export const packageCourses = async (id) => {
  let url = `${ApiUrl.PACKAGE_COURSES}?${Constants.paginationFalse}&package_id=${id}`;
  const response = await httpServiceInstance.get(url);
  return response;
};
