import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const lessonsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.LESSONS, data);
  return response;
};

export const lessonsEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.LESSONS + "?id=" + id,
    data
  );
  return response;
};

export const lessonsRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.LESSONS + "?id=" + id
  );
  return response;
};

export const lessonsStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.LESSONS_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const lessons = async (data) => {
  let url = `${ApiUrl.LESSONS}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }&course_id=${data?.id}${
    data?.moduleId ? `&module_id=${data?.moduleId}` : ""
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};
