import { useSelector } from "react-redux";
import { Constants } from "../Constants";

const usePermissions = (permissionType) => {
  const { permissionsArr } = useSelector((state) => state.Auth);
  const roleType = localStorage.getItem(Constants.roleType);
  const permission =
    roleType === Constants.defaultAdmin || permissionsArr?.[permissionType];

  return permission;
};

export default usePermissions;
