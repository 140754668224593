import React from "react";
import PageHeader from "../Common/PageHeader";
import { Constants } from "../Common/Constants";
import TableList from "../Common/UiModel/TableList";
import secondsToHour from "../Common/Functions/secondsToHour";
import ReactSelect from "../Common/Fields/ReactSelect";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { contactStatusUpdate, usersDetail } from "../../Redux/Actions";
import dayjs from "dayjs";
import usePermissions from "../Common/Functions/usePermissions";

const AbandonedCourses = ({ courses }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const userEditPermission = usePermissions(Constants.permission.USER_EDIT);

  const columns = [
    {
      name: Constants.id,
      cell: (_row, i) => i + 1,
    },
    {
      name: Constants.name,
      selector: (row) => row?.name,
    },
    {
      name: Constants.duration,
      selector: (row) =>
        row?.courseDuration ? secondsToHour(row?.courseDuration) : 0,
    },
    {
      name: Constants.lessons,
      selector: (row) => row?.totalLessons || 0,
    },
    {
      name: Constants.updatedBy,
      selector: (row) => row?.updated_by?.name || Constants.admin,
    },
    {
      name: Constants.date,
      cell: (row) => dayjs(row?.updatedAt).format(Constants.dateFormat),
    },
    {
      name: Constants.time,
      cell: (row) => dayjs(row?.updatedAt).format(Constants.timeFormat),
    },
    {
      name: Constants.statusKey,
      cell: (row) => (
        <ReactSelect
          required={false}
          options={Constants.contactStatusOptions}
          onChange={(e) => handleContactStatusChange(e, row)}
          value={
            Constants.contactStatusOptions.find(
              (elt) => elt?.value === row.contact_status
            ) || Constants.contactStatusOptions?.[0]
          }
        />
      ),
    },
  ];

  if (!userEditPermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.statusKey);
    if (index !== -1) columns.splice(index, 1);
  }

  const handleContactStatusChange = (selected, data) => {
    const userId = JSON.parse(localStorage.getItem(Constants.user))?._id;
    const values = { contact_status: selected.value, updated_by: userId };
    dispatch(contactStatusUpdate(data?._id, values)).then((response) => {
      if (response?.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response?.error,
        });
      } else {
        dispatch(usersDetail(id));
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response?.data?.message,
        });
      }
    });
  };

  return (
    <div className="mt-3">
      <div className="mt-3">
        <PageHeader pagetitle={Constants.abandonedCourses} />
      </div>
      <TableList
        columns={columns}
        data={courses}
        defaultSortFieldId
        highlightOnHover
      />
    </div>
  );
};

export default AbandonedCourses;
