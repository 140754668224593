/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReports } from "../../../Redux/Actions";
import { Constants } from "../../Common/Constants";
import AreaGraphMonthly from "./AreaGraphMonthyl";

const Monthly = () => {
  const dispatch = useDispatch();
  const { reportslist } = useSelector((state) => state.Reports);

  useEffect(() => {
    dispatch(fetchReports(Constants.reportMonth));
  }, []);

  return (
    <div>
      <div className="row mt-4">
        <div id="chart" className="col-md-4">
          <AreaGraphMonthly
            data={reportslist?.data?.data?.revenue_data}
            title={Constants.totalRevenue}
            format={Constants.currency}
          />
        </div>
        <div id="chart" className="col-md-4">
          <AreaGraphMonthly
            data={reportslist?.data?.data?.new_signup_data}
            title={Constants.newSignups}
            format=""
          />
        </div>
        <div id="chart" className="col-md-4">
          <AreaGraphMonthly
            data={reportslist?.data?.data?.paid_users_data}
            title={Constants.newPaidUsers}
            format=""
          />
        </div>
        <div id="chart" className="col-md-4">
          <AreaGraphMonthly
            data={reportslist?.data?.data?.active_users_data}
            title={Constants.activeUsers}
            format=""
          />
        </div>
        <div id="chart" className="col-md-4">
          <AreaGraphMonthly
            data={reportslist?.data?.data?.total_transactions_data}
            title={Constants.transactions}
            format=""
          />
        </div>
        <div id="chart" className="col-md-4">
          <AreaGraphMonthly
            data={reportslist?.data?.data?.course_wise}
            title={Constants.course}
            format=""
          />
        </div>
        <div id="chart" className="col-md-4">
          <AreaGraphMonthly
            data={reportslist?.data?.data?.referred_users_data}
            title={Constants.referredUsers}
            format=""
          />
        </div>
        <div id="chart" className="col-md-4">
          <AreaGraphMonthly
            data={reportslist?.data?.data?.referred_amount_data}
            title={Constants.referAmount}
            format={Constants.currency}
          />
        </div>
        <div id="chart" className="col-md-4">
          <AreaGraphMonthly
            data={reportslist?.data?.data?.total_uninstalls}
            title={Constants.uninstalls}
            format=""
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </div>
  );
};

export default Monthly;
