import { mdiDeleteOutline } from "@mdi/js";
import { Constants } from "../Constants";

const DeleteListButton = ({ onClick }) => (
  <div className="action-icon ms-4" onClick={() => onClick()}>
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d={mdiDeleteOutline} />
    </svg>
    <span className="ps-2 name-f">{Constants.delete}</span>
  </div>
);

export default DeleteListButton;
