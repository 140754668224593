import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Constants } from "../Common/Constants";
import { Formik, Form } from "formik";
import {
  updateCourseCompletionPercent,
  usersDetail,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import ReactSelect from "../Common/Fields/ReactSelect";

const CompletionPercentForm = ({ data, onHide }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [completionPer, setCompletionPer] = useState(
    Constants?.completionPerOptions?.[1]
  );
  const [initialValues] = useState({
    user: id,
    course_completion: Constants?.completionPerOptions?.[1]?.value,
    course: data?._id,
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        // Enroll API
        let submitFunc = updateCourseCompletionPercent(values);
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            //user detail api
            dispatch(usersDetail(id));
            onHide();
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
          }
        });
      }}
    >
      {({ setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <ReactSelect
              label={Constants.setRule}
              required={true}
              options={Constants.completionPerOptions}
              onChange={(selected) => {
                setCompletionPer(selected);
                setFieldValue("course_completion", selected?.value);
              }}
              value={completionPer}
            />
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default CompletionPercentForm;
