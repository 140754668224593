import { types } from "../Actions/types";

const initialState = {
  eventslist: [],
  eventsuserslist: [],
  eventsSingle: "",
  popupmodel: "",
};

export default function Events(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.EVENTS_LIST:
      return {
        ...state,
        eventslist: payload,
      };
    case types.EVENTS_USERS_LIST:
      return {
        ...state,
        eventsuserslist: payload,
      };
    case types.EVENTS_SINGLE:
      return {
        ...state,
        eventsSingle: payload,
      };
    case types.EVENTS_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}
