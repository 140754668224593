import {
  detailEvent,
  events,
  eventsAdd,
  eventsEdit,
  eventsRemove,
  eventsStatusUpdate,
  usersEvent,
} from "../../services/event.service";
import { types } from "./types";

export const eventsInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await eventsAdd(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const eventsList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await events(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.EVENTS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.EVENTS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const eventsDetail = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await detailEvent(id);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.EVENTS_SINGLE, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.EVENTS_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const eventsUsers = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await usersEvent(id);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.EVENTS_USERS_LIST, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.EVENTS_USERS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const eventsUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await eventsEdit(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const eventsDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await eventsRemove(id);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const eventsUpdateStatus = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await eventsStatusUpdate(id, data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
