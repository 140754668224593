/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import welc from "../../assets/images/wel.png";
import { Constants } from "../Common/Constants";
import { getAdminConfigurations } from "../../Redux/Actions";
import { useDispatch } from "react-redux";

const section = { type: Constants.configBasicKey };

const StaticDashboard = () => {
  const [settings, setSettings] = useState([]);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem(Constants.user));

  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response?.data?.data);
    });
  }, []);

  return (
    <div>
      <div className="container-xxl mt-3">
        <div className="card-h mt-4">
          <h3 class="welcome-text">
            <span>{`Hello ${user?.name || Constants.admin}!`}</span>
          </h3>
          <img
            src={settings?.[0]?.value || welc}
            alt="welcome"
            className="img-fluid w-50  mx-auto my-4"
          />
        </div>
      </div>
    </div>
  );
};

export default StaticDashboard;
