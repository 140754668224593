import React from "react";
import Loyalty from "./Loyalty";
import CurrencyUsage from "./CurrencyUsage";
import ReferEarn from "./ReferEarn";
import Refund from "./Refund";

const PricingRule = () => {
  return (
    <>
      <Loyalty />
      <CurrencyUsage />
      <ReferEarn />
      <Refund />
    </>
  );
};

export default PricingRule;
