/* eslint-disable react-hooks/exhaustive-deps */
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import PromoCodeForm from "../PromoCodeForm";

const PromoDetailPage = () => {
  return (
    <div className="mt-4 course-form card">
      <PageHeader pagetitle={Constants.detail} />
      <PromoCodeForm />
    </div>
  );
};

export default PromoDetailPage;
