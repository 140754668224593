import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const eventsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.EVENTS, data);
  return response;
};

export const eventsEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.EVENTS + "?id=" + id,
    data
  );
  return response;
};

export const eventsRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.EVENTS + "?id=" + id
  );
  return response;
};

export const eventsStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.EVENTS_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const events = async (data) => {
  let url = `${ApiUrl.EVENTS}?${
    data?.skipPagination
      ? `${Constants.paginationFalse}`
      : `skip=${data?.skip || 0}`
  }&limit=${data?.limit || Constants.limitCount}${
    data && data?.search ? `&search=${data?.search}` : ""
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const detailEvent = async (id) => {
  const response = await httpServiceInstance.get(ApiUrl.EVENTS + `?id=${id}`);
  return response;
};

export const usersEvent = async (data) => {
  const response = await httpServiceInstance.get(
    ApiUrl.EVENTS_USERS +
      `?event_id=${data?.id}&skip=${data?.skip || 0}${
        data?.limit ? `&limit=${data?.limit}` : ""
      }`
  );
  return response;
};
