import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RequiredIcon from "./RequiredIcon";

const TextEditor = (props) => {
  const editorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
    ],
  };

  return (
    <div>
      <label className="form-label">{props?.label}</label>
      {props.hideRequiredIcon ? null : <RequiredIcon />}
      <CKEditor
        editor={ClassicEditor}
        config={props?.default ? {} : editorConfiguration}
        onChange={props?.onChange}
        data={props.data}
      />
      {(props.errors || props.touched) && (
        <p className="error-msg">{props.errors}</p>
      )}
    </div>
  );
};

export default TextEditor;
