import { saveExport } from "../../../Redux/Actions/Transactions";
import { types } from "../../../Redux/Actions/types";
import { Constants } from "../Constants";
import Papa from "papaparse";

const saveExportedFile = (file, dispatch, data) => {
  const csv = Papa.unparse(data);
  const userId = JSON.parse(localStorage.getItem(Constants.user))?._id;
  const values = {
    file_name: file,
    user_id: userId,
  };
  dispatch(saveExport(values)).then((response) => {
    if (response.error) {
      dispatch({
        type: types.ERROR_ALERT_VISIBLE,
        payload: response.error,
      });
    }
  });
  return csv;
};

export default saveExportedFile;
