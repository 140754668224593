import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const BarGraph = ({ data, title, format }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: title,
        data: [],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return format + val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: [],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return format + val;
          },
        },
      },
      title: {
        text: title,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  });

  useEffect(() => {
    if (data?.data && data?.dates) {
      setChartData((prevGraphData) => ({
        ...prevGraphData,
        series: [
          {
            ...prevGraphData.series[0],
            data: data?.data,
          },
        ],
        options: {
          ...prevGraphData.options,
          xaxis: {
            ...prevGraphData.options.xaxis,
            categories: data?.dates?.map((month) => month.slice(0, 3)),
          },
        },
      }));
    }
  }, [data]);

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={250}
    />
  );
};

export default BarGraph;
