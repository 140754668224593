import React from "react";
import forbidden from "../../../assets/images/404.png";
const Forbidden = () => {
  return <div>
<div className="container-xxl mt-3">
{/* <h3 class="fw-bold mb-0 page-title title-space mt-0  "> Forbidden</h3> */}
<div className="card">
<img src={forbidden} alt="403-image" className="img-fluid w-75  mx-auto my-4" />
</div>
</div>
  </div>;
};

export default Forbidden;
