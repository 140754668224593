import PageHeader from "../../../Common/PageHeader";
import { Constants } from "../../../Common/Constants";
import SetRuleForm from "./SetRuleForm";

const SetRule = () => {
  return (
    <div className="mt-4 course-form card">
      <PageHeader pagetitle={Constants.setRule} />
      <SetRuleForm />
    </div>
  );
};

export default SetRule;
