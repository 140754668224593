/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { Form, Formik } from "formik";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import ReactSelect from "../Common/Fields/ReactSelect";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import PageHeader from "../Common/PageHeader";
import {
  coursesList,
  packagesList,
  userEnrollment,
  usersDetail,
} from "../../Redux/Actions";
import getOptionsFromData from "../Common/Functions/getOptionsFromData";
import { useParams } from "react-router-dom";
import usePermissions from "../Common/Functions/usePermissions";

const EnrollUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { courseslist } = useSelector((state) => state.Courses);
  const { packageslist } = useSelector((state) => state.Packages);
  const [typeSelected, setTypeSelected] = useState(
    Constants.typeEnrollCoursePckgOptions?.[0]
  );
  const [optionSelected, setOptionSelected] = useState("");
  const [options, setOptions] = useState([]);
  const userEditPermission = usePermissions(Constants.permission.USER_EDIT);
  // Initial values for enroll form
  const [initialValues] = useState({
    razorpay_payment_id: "",
    package: "",
    course: "",
    user: id,
  });

  useEffect(() => {
    if (typeSelected?.value === Constants.course)
      dispatch(coursesList({ skipPagination: true }));
    else dispatch(packagesList({ skipPagination: true }));
  }, [typeSelected]);

  // Validation for enroll form
  const validationSchema = Yup.object().shape({
    razorpay_payment_id: Yup.string().required(Constants.required),
    course:
      typeSelected?.value === Constants.course
        ? Yup.string().required(Constants.required)
        : "",
    package:
      typeSelected?.value === Constants.package
        ? Yup.string().required(Constants.required)
        : "",
  });

  const handleSelectedChange = (e, setSelected, setFieldValue) => {
    setSelected(e);
    if (typeSelected?.value === Constants.course) {
      setFieldValue("course", e.value);
    } else {
      setFieldValue("package", e.value);
    }
  };

  useEffect(() => {
    setOptions(getOptionsFromData(courseslist?.data));
  }, [courseslist?.data]);

  useEffect(() => {
    setOptions(getOptionsFromData(packageslist?.data));
  }, [packageslist?.data]);

  return (
    <div className="mt-4 card">
      <PageHeader pagetitle={Constants.enrollUser} />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          if (typeSelected?.value === Constants.course) {
            values.enrollment_type = 0;
            delete values?.package;
          } else {
            values.enrollment_type = 1;
            delete values?.course;
          }
          // Enroll API
          let submitFunc = userEnrollment(values);
          dispatch(submitFunc).then((response) => {
            if (response?.error) {
              dispatch({
                type: types.ERROR_ALERT_VISIBLE,
                payload: response?.error,
              });
            } else {
              //user detail api
              resetForm();
              setOptionSelected("");
              dispatch(usersDetail(id));
              dispatch({
                type: types.SUCCESS_ALERT_VISIBLE,
                payload: response?.data?.message,
              });
            }
          });
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className="row">
            <div className="col-md-4 mb-2">
              <TextField
                type="text"
                name="razorpay_payment_id"
                className="form-control"
                errors={
                  touched?.razorpay_payment_id
                    ? errors?.razorpay_payment_id
                    : undefined
                }
                touched={touched?.razorpay_payment_id}
                label={Constants.transactionId}
              />
            </div>
            <div className={`col-md-4 mb-4`}>
              <ReactSelect
                label={`${Constants.select} ${Constants.type}`}
                options={Constants.typeEnrollCoursePckgOptions}
                value={typeSelected}
                onChange={(selected) => {
                  setTypeSelected(selected);
                  setOptionSelected("");
                }}
              />
            </div>
            <div className={`col-md-4 mb-4`}>
              <ReactSelect
                label={`${Constants.select} ${
                  typeSelected?.value === Constants.course
                    ? Constants.course
                    : Constants.package
                }`}
                required={true}
                options={options}
                value={optionSelected}
                errors={touched?.course ? errors?.course : undefined}
                touched={touched?.course}
                onChange={(selected) => {
                  handleSelectedChange(
                    selected,
                    setOptionSelected,
                    setFieldValue
                  );
                }}
              />
            </div>
            {userEditPermission && (
              <ButtonCustom label={Constants.submit} type="submit" />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EnrollUser;
