import {
  calculateAmountRefund,
  detailtransactions,
  exportSaved,
  refund,
  transactions,
} from "../../services/transactions.service";
import { types } from "./types";

export const getTransactionsList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await transactions(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.TRANSACTIONS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.TRANSACTIONS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const transactionsListExport = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await transactions(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const transactionDetail = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await detailtransactions(id);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.TRANSACTIONS_SINGLE, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.TRANSACTIONS_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const refundTransaction = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await refund(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const saveExport = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await exportSaved(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const getRefundAmount = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  let response = await calculateAmountRefund(data);
  if (response.status === true) {
    let result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
