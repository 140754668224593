import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const supportStatusEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.UPDATE_SUPPORT + "?id=" + id,
    data
  );
  return response;
};

export const supports = async (data) => {
  let url = `${ApiUrl.SUPPORT}${
    data?.skipPagination
      ? `?${Constants.paginationFalse}`
      : `?skip=${data?.skip || 0}`
  }&limit=${data?.limit || Constants.limitCount}${
    data && data?.search ? `&search=${data?.search}` : ""
  }${
    data && data?.support_status
      ? `&support_status=${data?.support_status}`
      : ""
  }${
    data?.start_date
      ? `&start_date=${data?.start_date}&end_date=${data?.end_date}`
      : ""
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};
