/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/react";
import GoldenRetriever from "@uppy/golden-retriever";
import Tus from "@uppy/tus";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";

const VideoTusUpload = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [uppy, setUppy] = useState(null);

  useEffect(() => {
    const newUppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["video/mp4"],
      },
    });

    newUppy.use(Tus, {
      endpoint: process.env.REACT_APP_TUS_URL,
    });

    newUppy.use(GoldenRetriever, {
      indexedDB: { maxFileSize: Infinity, maxTotalSize: Infinity },
    });

    new DashboardModal({
      uppy: newUppy,
      closeModalOnClickOutside: true,
      closeModalOnEscape: true,
      onRequestClose: () => setOpen(false),
    });

    newUppy.on("upload", () => {
      setOpen(true);
    });

    newUppy.on("complete", (result) => {
      const videoFile = result?.successful?.[0];
      props?.handleFile(videoFile?.uploadURL);
      setOpen(false);
    });

    setUppy(newUppy);

    return () => {
      if (newUppy) {
        newUppy.close();
      }
    };
  }, []);

  return (
    <div className="width-outer-up">
      <div className="body d-flex flex-col py-1">
        {uppy && (
          <DashboardModal
            uppy={uppy}
            open={isOpen}
            closeModalOnClickOutside
            closeModalOnEscape
            onRequestClose={() => {
              setOpen(false);
            }}
          />
        )}
        <div className="flex-p" onClick={() => setOpen(true)}>
          <i className="fa fa-upload upload-ic"></i>
          <span className="img-upload-label">{`${props.label}`}</span>
        </div>
        {(props.errors || props.touched) && (
          <p className="error-msg mt-4">{props.errors}</p>
        )}
      </div>
    </div>
  );
};

export default VideoTusUpload;
