const getOptionsFromData = (data) => {
  if (data?.length > 0) {
    return data.map((elt) => ({
      value: elt?._id,
      label: elt?.name,
    }));
  }
  return [];
};

export default getOptionsFromData;
