import { types } from "../../Actions/types";

const initialState = {
  configList: [],
  error: null,
  config: "",
  popupmodel: "",
  settingList: "",
  settingUpdate: "",
};

export default function Configuration(state = initialState, action) {
  let { type, payload, error } = action;
  if (payload && payload.error != null) {
    error = payload.error;
  }

  switch (type) {
    case types.ADMIN_CONFIGURATION_LIST:
      return {
        ...state,
        configList: payload,
        error: error,
      };

    case types.ADMIN_CONFIGURATION_ADD:
      return {
        ...state,
        config: payload,
        error: error,
      };

    default:
      return state;
  }
}
