/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  goalUpdateStatus,
  goalsDelete,
  goalsList,
  goalsUpdateOrder,
} from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import GoalsForm from "./GoalsForm";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";
import responseMethod from "../../Common/Functions/responseMethod";
import { Link } from "react-router-dom";
import GoalsSelectionLimit from "./GoalsSelectionLimit";
import DeleteListButton from "../../Common/Fields/DeleteListButton";
import ToggleListField from "../../Common/Fields/ToggleListField";
import EditListButton from "../../Common/Fields/EditListButton";
import { Dropdown } from "react-bootstrap";
import usePermissions from "../../Common/Functions/usePermissions";

const Goals = (props) => {
  const dispatch = useDispatch();
  const { goalslist, popupmodel } = useSelector((state) => state.Goals);
  const [goalsData, setGoalsData] = useState([]);
  const [selectionLimit, showSelectionLimit] = useState(false);
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);
  const deletePermission = usePermissions(Constants.permission.CONFG_DELETE);

  useEffect(() => {
    // Goals list API
    dispatch(goalsList());
    dispatch({
      type: types.GOALS_SINGLE,
      payload: "",
    });
  }, [props.type]);

  useEffect(() => {
    // Set goals list data from store
    setGoalsData(goalslist?.data);
  }, [goalslist]);

  const triggerToggle = (rowData) => {
    // Update goal status API
    let itemId = rowData._id;
    let data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(goalUpdateStatus(itemId, data)).then((response) => {
      let tempList = goalsData;
      let index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].status = data.status;
      setGoalsData(tempList);
      responseMethod(response, dispatch, goalsList);
    });
  };

  const handleOnDragEnd = (result) => {
    // Re-arrange the Goals
    if (!result.destination) return;
    const items = Array.from(goalslist?.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let data = items.map((elt, index) => {
      elt.sort_order = index + 1;
      return elt;
    });
    setGoalsData(data);
    dispatch(goalsUpdateOrder(items)).then((response) => {
      responseMethod(response, dispatch, goalsList);
    });
  };

  const onDelete = (delete_id) => {
    // Delete goal
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(goalsDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(goalsList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  return (
    <div className="container-xxl pt-m-0 pt-16-web">
      <div className="row">
        <div className="col-md-10">
          <PageHeader
            pagetitle={Constants.configGoals}
            buttonLink={addPermission}
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: types.GOALS_FORM_MODEL,
                payload: { msg: Constants.addGoals, show: true },
              });
            }}
          />
        </div>
        <div className="col-md-2">
          <div className={`align-items-center`}>
            <div className="border-0 p-30">
              {/* Goals selection limit */}
              <div
                className={`card-header m-pt-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between mb-4 flex-wrap`}
              >
                <div className="d-flex justify-content-between align-items-center btn-hover">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      showSelectionLimit(true);
                    }}
                    className="btn btn-primary text-uppercase"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>
                    {Constants.selectionLimit}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card list-view">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="sequence">
            {(provided) => (
              <ul
                className="sequence"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {goalsData?.length > 0 &&
                  goalsData.map((option, index) => {
                    return (
                      <Draggable
                        key={option._id}
                        draggableId={option._id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            className="space-btwn"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div>
                              <p>{option?.name}</p>
                            </div>
                            {/* Actions for Goal */}
                            <div className="switch-tn2 position-relative btn-h">
                              <div className="remove-drop-ic">
                                <Dropdown className="dropdown user-profilem">
                                  <Dropdown.Toggle
                                    as="a"
                                    className="nav-link dropdown-toggle pulse p-0 margin-b"
                                    href="#!"
                                    role="button"
                                  >
                                    <i
                                      className="fa fa-ellipsis-h ellips-t cursor-pointer"
                                      aria-hidden="true"
                                    ></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                                    <div className="outer-dot2 btn-list2">
                                      {editPermission && (
                                        <>
                                          <div className="list-group">
                                            <EditListButton
                                              onClick={() => {
                                                dispatch({
                                                  type: types.GOALS_FORM_MODEL,
                                                  payload: {
                                                    msg: Constants.editGoals,
                                                    show: true,
                                                    add: 0,
                                                  },
                                                });
                                                dispatch({
                                                  type: types.GOALS_SINGLE,
                                                  payload: option,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="list-group">
                                            <ToggleListField
                                              label={Constants.statusKey}
                                              onChange={() => {
                                                triggerToggle(option);
                                              }}
                                              checked={
                                                option.status ===
                                                Constants.status.ACTIVE
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                      {deletePermission && (
                                        <div className="list-group">
                                          <DeleteListButton
                                            onClick={() => {
                                              onDelete(option._id);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        {/* Goal Form */}
        <ModalPopup
          show={popupmodel}
          onHide={() => {
            dispatch({ type: types.GOALS_FORM_MODEL, payload: "" });
            dispatch({ type: types.GOALS_SINGLE, payload: "" });
          }}
          dialogClassName={"modal"}
          title={popupmodel.msg}
          component={<GoalsForm />}
        />
        {/* Goal Selection Form */}
        <ModalPopup
          show={selectionLimit}
          onHide={() => showSelectionLimit(false)}
          dialogClassName={"modal"}
          title={Constants.configGoalsSelectionLimit}
          component={
            <GoalsSelectionLimit onHide={() => showSelectionLimit(false)} />
          }
        />
      </div>
    </div>
  );
};

export default Goals;
