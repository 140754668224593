const secondsToHour = (total_seconds) => {
  const seconds = parseInt(total_seconds, 10);
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedRemainingSeconds =
    remainingSeconds % 60 < 10
      ? `0${remainingSeconds % 60}`
      : remainingSeconds % 60;
  return `${formattedHours}:${formattedMinutes}:${formattedRemainingSeconds}`;
};

export default secondsToHour;
