/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const VideoDuration = ({ url, fetchDuration }) => {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(1);

  function myFunction(duration) {
    setProgress(100);
    fetchDuration(duration);
    setLoading(false);
  }

  function addVideoLoadedDataListener(videoElement, videoSrc, callback) {
    videoElement.src = videoSrc;

    // Add an event listener for the 'loadeddata' event
    videoElement.addEventListener(
      "loadeddata",
      function () {
        // Callback with the video duration
        callback(videoElement.duration);
      },
      false
    );
  }

  useEffect(() => {
    const videoUploaded = videoRef.current;
    let intervalId;

    if (url && videoUploaded) {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 1;
          if (newProgress >= 100) {
            clearInterval(intervalId);
          }
          return newProgress;
        });
      }, 7000);

      addVideoLoadedDataListener(videoUploaded, url, myFunction);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [url]);

  return (
    <div className="width-outer-up ">
      {loading && (
        <CircularProgressbar value={progress} text={`${progress}%`} />
      )}
      <video ref={videoRef} id="videoId" style={{ display: "none" }}>
        <source type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoDuration;
