/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../../Common/PageHeader";
import { Constants } from "../../../Common/Constants";
import { types } from "../../../../Redux/Actions/types";
import ModalPopup from "../../../Common/UiModel/ModalPopup";
import LessonForm from "./LessonForm";
import { useState } from "react";
import { useEffect } from "react";
import {
  lessonsDelete,
  lessonsList,
  lessonsUpdateStatus,
} from "../../../../Redux/Actions";
import { deleteConfirmBox } from "../../../Common/Functions/deleteConfirmBox";
import { Link, useParams } from "react-router-dom";
import TableList from "../../../Common/UiModel/TableList";
import ToggleListField from "../../../Common/Fields/ToggleListField";
import DeleteListButton from "../../../Common/Fields/DeleteListButton";
import EditListButton from "../../../Common/Fields/EditListButton";
import dayjs from "dayjs";
import ReactPagination from "../../../Common/Fields/ReactPagination";
import { Dropdown } from "react-bootstrap";
import { RoutesUrl } from "../../../Common/RoutesUrl";
import usePermissions from "../../../Common/Functions/usePermissions";

const Lesson = () => {
  const dispatch = useDispatch();
  const { id, moduleId } = useParams();
  const { lessonslist, popupmodel } = useSelector((state) => state.Lessons);
  const [lessonsData, setLessonsData] = useState([]);
  let lessonData;
  if (moduleId) {
    lessonData = { id, moduleId };
  } else {
    lessonData = { id };
  }
  const [pageIndex, setPageIndex] = useState(1);
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);
  const deletePermission = usePermissions(Constants.permission.COURSES_DELETE);

  useEffect(() => {
    dispatch(lessonsList(lessonData));
    dispatch({
      type: types.LESSONS_SINGLE,
      payload: "",
    });
  }, []);

  useEffect(() => {
    setLessonsData(lessonslist.data);
  }, [lessonslist]);

  const triggerToggle = (rowData) => {
    let itemId = rowData._id;
    let data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(lessonsUpdateStatus(itemId, data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response?.data?.message,
        });
      }
      dispatch(lessonsList(lessonData));
    });
  };

  const onDelete = (delete_id) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(lessonsDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(lessonsList(lessonData));
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  const handleOffset = (newOff) => {
    let data = {
      ...lessonData,
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(lessonsList(data));
  };

  const handleEdit = (row) => {
    dispatch({
      type: types.LESSONS_FORM_MODEL,
      payload: {
        msg: Constants.editLesson,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.LESSONS_SINGLE,
      payload: row,
    });
  };

  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (row) => row?.episodeNumber,
    },
    {
      name: Constants.image,
      width: "10%",
      selector: (row) => (
        <div
          className={`img-out ${
            row.status === Constants.status.ACTIVE
              ? "active-status"
              : "inactive-status"
          }`}
        >
          <img
            src={row?.thumbnail}
            alt={row?.name}
            style={{
              maxHeight: "100px",
              maxWidth: "100px",
            }}
          />
        </div>
      ),
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          {row?.name}
        </Link>
      ),
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    {
      name: Constants.updatedBy,
      selector: (row) => row?.updated_by?.name || Constants.admin,
    },
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <EditListButton
                      onClick={() => {
                        handleEdit(row);
                      }}
                    />
                  </div>
                  {editPermission && (
                    <div className="list-group">
                      <ToggleListField
                        label={Constants.statusKey}
                        onChange={() => {
                          triggerToggle(row);
                        }}
                        checked={row.status === Constants.status.ACTIVE}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  return (
    <div className={moduleId ? "container-xxl" : ""}>
      <div className="mt-3">
        <PageHeader
          list={
            moduleId
              ? `${RoutesUrl?.courses}/${id}?activeTab=${Constants.moduleKey}`
              : ""
          }
          pagetitle={Constants.lessonList}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.LESSONS_FORM_MODEL,
              payload: { msg: Constants.addLesson, show: true },
            });
          }}
        />
      </div>
      <TableList
        columns={columns}
        data={lessonsData}
        defaultSortFieldId
        highlightOnHover
      />
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.LESSONS_SINGLE, payload: "" });
          dispatch({ type: types.LESSONS_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<LessonForm />}
      />
      {lessonslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={lessonslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </div>
  );
};

export default Lesson;
